import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';

import { CheckoutStore } from 'store/CheckoutStore';
import { HeaderStore } from 'store/HeaderStore';
import { PaymentStore } from 'store/PaymentStore';
import { LoginStore } from 'store/LoginStore';

import CouponIcon from 'assets/icons/coupon.svg';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { PrimaryButton } from 'styled/PrimaryButton';
import { Flex } from 'styled/Flex';
import { Loader } from 'components/Loader/Loader';

const PromotionCodeContainer = styled.div`
  padding: 27px 41px 11px 51px;

  @media ${devices.mobile} {
    padding: 27px 21px 11px 11px;
  }
`;

const PromotionCodeInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputBlock = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 72px;
  padding: 19px 17px 16px;
  color: ${({ theme }) => theme.colors.darkBlack};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  @media ${devices.mobile} {
    min-height: 57px;
    padding: 10px 17px;
    font-size: 17px;
  }
`;

const Input = styled.input`
  width: 100%;
  margin: ${(props: { margin?: string }) => (props.margin ? props.margin : '')};
  border: 0;
  outline: none;
  ::placeholder {
    color: #a6a6a6;
  }
`;

const PromotionCode = styled.div`
  width: 100%;
  user-select: none;
`;

const ErrorText = styled.div`
  margin-left: 50px;
  margin-top: 13px;
  color: #fc8575;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  text-align: left;
`;

const PromotionCodeButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 250px;
  height: 56px;

  @media ${devices.mobile} {
    max-height: 38px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;

export const PromotionCodeCard = observer((props: { checkoutId: number }) => {
  const history = useHistory();
  const { checkoutId } = props;
  const {
    addPromotionCode,
    checkout,
    removePromotionCode,
    setPromotionError,
    promotionError,
  } = CheckoutStore;
  const transactionLinkHash = new URLSearchParams(useLocation().search).get(
    'transactionLinkHash'
  ) as string;
  const { user } = LoginStore;
  const { setBackButton, setTitle } = HeaderStore;
  const { setActivePromotion, removeActivePromotion, activePromotion } = PaymentStore;
  const userData = toJS(user);
  const [promotionCode, setPromotionCode] = useState('');
  const [promotionCodeValue, setPromotionCodeValue] = useState('');
  const [isPromotionCodeLoading, setIsPromotionCodeLoading] = useState(false);
  const checkoutData = toJS(checkout);
  const { t } = useTranslation(Namespaces.UI);

  useEffect(() => {
    if (checkoutData && checkoutData.promotionCode && !promotionCode) {
      const { code } = checkoutData.promotionCode;
      setPromotionCode(code);
      setPromotionCodeValue(code);
    }
  }, []);

  useEffect(() => {
    if (checkoutData && checkoutData.promotionCode && promotionCode) {
      const { code } = checkoutData.promotionCode;
      setPromotionCode(code);
      setPromotionCodeValue(code);
    }
    if (activePromotion) {
      setIsPromotionCodeLoading(true);
      setPromotionCodeValue(activePromotion);
      addPromotionCode(
        checkoutId,
        activePromotion,
        transactionLinkHash,
        (updatedCheckout) => {
          setIsPromotionCodeLoading(false);
          if (updatedCheckout?.promotionCode) {
            setPromotionCode(updatedCheckout.promotionCode.code);
          }
        }
      );
      removeActivePromotion();
    }
  }, [
    promotionCode,
    activePromotion,
    checkoutData,
    checkoutId,
    transactionLinkHash,
    removeActivePromotion,
    addPromotionCode,
  ]);

  return (
    <>
      <PromotionCodeContainer data-testid="promotion-code-card">
        <PromotionCodeInputContainer>
          <CustomIcon
            width="21px"
            height="15px"
            margin="0 7px 0 0 "
            icon={CouponIcon}
            cursor
          />

          <InputBlock>
            {!checkoutData?.promotionCodeDiscount ? (
              <Input
                value={promotionCodeValue}
                onChange={(e) => {
                  setPromotionCode('');
                  setPromotionCodeValue(e.target.value);
                  setPromotionError('');
                }}
              />
            ) : (
              <PromotionCode>{checkoutData?.promotionCode?.code}</PromotionCode>
            )}
          </InputBlock>
        </PromotionCodeInputContainer>

        {promotionError && <ErrorText>{t('promotionCodeError')}</ErrorText>}
      </PromotionCodeContainer>
      {(promotionCode || promotionCodeValue || isPromotionCodeLoading) && (
        <Flex justifyContent="center" margin="15px 0 0 0">
          <PromotionCodeButton
            size="large"
            onClick={() => {
              if (!promotionCode) {
                if (!userData.id && !transactionLinkHash) {
                  setActivePromotion(promotionCodeValue);
                  setBackButton(true);
                  setTitle(t('loginTitle'));
                  localStorage.prevPath =
                    history.location.pathname + history.location.search;
                  history.push('/auth');
                  return;
                }
                if (promotionCodeValue) {
                  setIsPromotionCodeLoading(true);
                  addPromotionCode(
                    checkoutId,
                    promotionCodeValue,
                    transactionLinkHash,
                    (updatedCheckout) => {
                      setIsPromotionCodeLoading(false);
                      if (updatedCheckout?.promotionCode) {
                        setPromotionCode(updatedCheckout.promotionCode.code);
                      }
                    }
                  );
                }
              } else {
                if (checkoutData?.promotionCode) {
                  setIsPromotionCodeLoading(true);
                  removePromotionCode(
                    checkoutId,
                    checkoutData?.promotionCode.id,
                    transactionLinkHash,
                    (updatedCheckout) => {
                      setIsPromotionCodeLoading(false);
                      if (updatedCheckout) {
                        setPromotionCode('');
                        setPromotionCodeValue('');
                      }
                    }
                  );
                } else {
                  setPromotionError('');
                }
              }
            }}
          >
            {isPromotionCodeLoading ? (
              <Loader small isWhite />
            ) : !promotionCode ? (
              t('checkoutPromotionCodeCardAddButton')
            ) : (
              t('checkoutPromotionCodeCardRemoveButton')
            )}
          </PromotionCodeButton>
        </Flex>
      )}
    </>
  );
});
