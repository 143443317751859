import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

export const Header = styled.div`
  margin: 0px 0px 20px 0px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
`;

export const HeaderElements = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.darkBlack};
`;

export const CardTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;

  @media ${devices.mobile} {
    font-size: 20px;
  }
`;

export const CardHeaderErrorLabel = styled.div`
  color: ${({ theme }) => theme.colors.peach};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: right;
  white-space: nowrap;
  @media ${devices.mobile} {
    font-size: 18px;
  }
`;

export const Price = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: right;

  @media ${devices.mobile} {
    font-size: 18px;
  }
`;
export const PriceDiscount = styled.div`
  width: 150px;
  color: #fc8575;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: right;
  white-space: nowrap;
  @media ${devices.mobile} {
    width: 120px;
    font-size: 18px;
  }
`;

export const AddBookingButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 250px;
  height: 56px;

  @media ${devices.mobile} {
    max-height: 38px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

export const ModalBody = styled.div`
  position: relative;
  width: 545px;
  padding: 65px 67px 40px 65px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;

  @media ${devices.mobile} {
    width: 90%;
    padding: 25px;
  }
`;

export const CancelModalText = styled.div`
  margin: 25px 0 7px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
`;
export const CancelModalSubText = styled.div`
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`;
export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

export const ModalCancelButton = styled(PrimaryButton)`
  box-sizing: border-box;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: #6f6f6f;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #a6a6a6;
  border-radius: 25px;

  @media ${devices.mobile} {
    margin-bottom: 10px;
  }
`;
export const ModalRejectButton = styled(PrimaryButton)`
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.darkBlack};
  border-radius: 28px;

  @media ${devices.mobile} {
    margin: 5px auto 10px;
  }
`;
