import { useState, useEffect, useContext, FC } from 'react';

import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { ThemeContext } from 'styled-components';
import { getPrice } from 'helpers';
import { getYMD, getTime, isLocalTimeZoneSameAs } from 'helpers/DateTimeUtils';
import moment from 'moment';
import { Namespaces } from 'languages';
import { getAddBookingCTAEvent, postCTAEventMessage } from 'helpers/EventUtils';

import { Loader } from 'components/Loader/Loader';
import { CollapseText } from 'components/CollapseText/CollapseText';

import { CheckoutStore, AddCheckoutItemData } from 'store/CheckoutStore';
import { Course } from 'store/ServiceStore';
import { CourseStore } from 'store/CourseStore';
import { LoginStore } from 'store/LoginStore';

import { Flex } from 'styled/Flex';
import { CourseOccasions } from './CourseOccasions';
import { Avatar } from 'components/Avatar';
import {
  Card,
  CardHeader,
  CardDescription,
  CardPriceIcon,
  CardDateTitle,
  CardDateValues,
  Hour,
  GroupLeadersBlock,
  GroupLeadersBlockName,
  GroupLeaderBlock,
  OccasionsBlock,
  MessageBox,
  WaitListButton,
  CardButton,
  InfoIcon,
  Modal,
  ModalBody,
  IconClose,
  ModalCardName,
  ModalCardDescription,
  TimeZoneText,
  SpotsLeftLabel,
  BookingDurationBlock,
  BookingStartEndBlock,
  BookingStartBlock,
  BookingEndBlock,
  CardValidBlock,
  Price,
  GroupLeaderName,
} from './detailsStyles';
import { WaitingListModal } from './WaitingListModal';
import { DONT_ASK } from 'constants/checkoutConstants';

export const CourseDetails: FC<{ course: Course }> = observer(({ course }) => {
  const {
    currency,
    description,
    startTime,
    endTime,
    firstBookableAt,
    lastBookableAt,
    id,
    name,
    price,
    isBookPerOccasion,
    isFull,
    isWaitingListEnabled,
    isCallerAttending,
    isCallerOnWaitingList,
    resources,
    spotsLeft,
    proxyUsersMode,
    proxyUserIdsOnWaitingList,
  } = course;
  const history = useHistory();
  const { profileId } = useParams<{ profileId: string }>();
  const [loading, setLoading] = useState(false);
  const [isIdModalOpen, setIdOpenModal] = useState<number[]>([]);
  const [isCollapsed, setCollapsed] = useState(true);
  const { user } = LoginStore;
  const userData = toJS(user);
  const { addItemToCheckout } = CheckoutStore;
  const {
    course: newCourse,
    fetchCourse,
    clearCourse,
    fetchCourseOccasions,
    addToCourseWaitingList,
    addToCourseOccasionWaitingList,
  } = CourseStore;
  const [selectedButtons, setSelectedButtons] = useState<number[]>([]);
  const [itemsToSelect, setItemsToSelect] = useState<number[]>([]);
  const [allEntries, setAllEntries] = useState(false);
  const [modal, setModal] = useState<number | null>(null);
  const { t } = useTranslation(Namespaces.UI);
  const themeContext = useContext(ThemeContext);

  const handleSubmit = async (userProxyIds: number[]) => {
    setLoading(true);
    if (isBookPerOccasion)
      return addToCourseOccasionWaitingList({
        id: modal?.toString() || '',
        userProxyIds,
      }).then(() => {
        setLoading(false);
      });
    else {
      return addToCourseWaitingList({ id, userProxyIds }).then(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    fetchCourse(id);
    return () => {
      clearCourse();
      setItemsToSelect([]);
      setSelectedButtons([]);
    };
  }, [id, fetchCourse, clearCourse]);

  useEffect(() => {
    const { courseId } = localStorage;
    if (courseId && userData.id) {
      if (proxyUsersMode === DONT_ASK)
        addToCourseWaitingList({ id: courseId }, setLoading);
      else setModal(courseId);
      localStorage.removeItem('courseId');
    }
  }, [userData.id, addToCourseWaitingList, proxyUsersMode]);

  useEffect(() => {
    const currentCourse = toJS(newCourse);
    if (currentCourse) {
      const { data } = currentCourse;
      const selectableButtons = data.reduce((acc, item) => {
        const { isFull: isFullItem, isCallerAttending: isDisabled, id: itemId } = item;
        if (isBookPerOccasion && !isFullItem && !isDisabled) {
          acc.push(itemId);
        }
        return acc;
      }, [] as number[]);
      setItemsToSelect(selectableButtons);
    }
  }, [isBookPerOccasion, newCourse]);

  const currentCourse = toJS(newCourse);
  if (!currentCourse) {
    return <Loader />;
  }

  const { data, page, totalPages, totalRows } = currentCourse;

  const getRequestData = () => {
    const requestData = [] as AddCheckoutItemData[];

    if (isBookPerOccasion) {
      if (allEntries) {
        requestData.push({ serviceId: id, allEntries: true });
        return requestData;
      }
      selectedButtons.forEach((el) => {
        requestData.push({ serviceId: id, entryId: el });
      });
    }
    if (!isBookPerOccasion) {
      requestData.push({ serviceId: id });
    }
    return requestData;
  };

  const isCourseBookable = () => {
    let now = moment();
    return now.isAfter(firstBookableAt) && now.isBefore(lastBookableAt);
  };

  const renderCourseFooter = () => {
    if (!isCourseBookable()) {
      return <></>;
    }

    if (!isBookPerOccasion) {
      if (isCallerAttending) {
        return <MessageBox>{t('haveBookingThis')}</MessageBox>;
      }

      if (
        isFull &&
        (isCallerOnWaitingList || (proxyUserIdsOnWaitingList || [])?.length > 0)
      ) {
        return <MessageBox isRed>{t('waitingListText')}</MessageBox>;
      }

      if (isFull && isWaitingListEnabled) {
        return (
          <WaitListButton
            onClick={() => {
              if (!userData.id) {
                localStorage.prevPath = history.location.pathname;
                localStorage.courseId = id;
                history.push('/auth');
                return;
              }
              if (!loading) {
                if (proxyUsersMode === DONT_ASK) {
                  setLoading(true);
                  addToCourseWaitingList({ id }, setLoading);
                } else {
                  setModal(id);
                }
              }
            }}
          >
            {loading ? <Loader isWhite small /> : t('waitingList')}
          </WaitListButton>
        );
      }

      if (isFull) {
        return <MessageBox>{t('courseFull')}</MessageBox>;
      }

      return (
        <CardButton
          onClick={() => {
            if (!loading) {
              setLoading(true);
              addItemToCheckout(profileId, getRequestData(), setLoading);
              postCTAEventMessage(getAddBookingCTAEvent());
            }
          }}
        >
          {loading ? <Loader isWhite small /> : t('addToCart')}
        </CardButton>
      );
    }

    if (!selectedButtons.length) {
      return <></>;
    }

    return (
      <CardButton
        disabled={isBookPerOccasion && !selectedButtons.length}
        onClick={() => {
          if (!loading && selectedButtons.length) {
            setLoading(true);
            addItemToCheckout(profileId, getRequestData(), setLoading);
            postCTAEventMessage(getAddBookingCTAEvent());
          }
        }}
      >
        {loading ? <Loader isWhite small /> : t('addToCart')}
      </CardButton>
    );
  };

  const servicePrice = getPrice(price, currency);

  let localTimeZoneDiffers =
    !isLocalTimeZoneSameAs(firstBookableAt) || !isLocalTimeZoneSameAs(lastBookableAt);

  return (
    <>
      <Card
        collapsed={isCollapsed}
        onClick={() => {
          setCollapsed(true);
        }}
      >
        <Flex flexDirection="column">
          <Flex flexDirection="column">
            <CardHeader>{name}</CardHeader>
            <CardDescription>
              <CollapseText text={description} maxLines={4} />
            </CardDescription>
            <Flex alignItems="center">
              {servicePrice && (
                <>
                  <CardPriceIcon />
                  <Price>{servicePrice}</Price>
                </>
              )}
              {!isFull && spotsLeft !== undefined && spotsLeft >= 0 && (
                <SpotsLeftLabel
                  color={
                    spotsLeft > 0 ? themeContext.colors.yellow : themeContext.colors.red
                  }
                >
                  {spotsLeft === 0
                    ? t('spotsLeftFull')
                    : spotsLeft === 1
                    ? t('spotsLeftSingle')
                    : t('spotsLeft', {
                        count: spotsLeft,
                      })}
                </SpotsLeftLabel>
              )}
            </Flex>

            {localTimeZoneDiffers && (
              <TimeZoneText>{t('timeZoneDifferText')}</TimeZoneText>
            )}

            <CardValidBlock>
              <BookingDurationBlock>
                <CardDateTitle>{t('courseDuration').toUpperCase()}</CardDateTitle>
                <CardDateValues>{`${getYMD(startTime, true)} ~ ${getYMD(
                  endTime,
                  true
                )}`}</CardDateValues>
              </BookingDurationBlock>
              <BookingStartEndBlock>
                <BookingStartBlock>
                  <CardDateTitle>{t('bookingStart').toUpperCase()}</CardDateTitle>
                  <CardDateValues
                    color={
                      moment().isAfter(firstBookableAt)
                        ? themeContext.colors.darkBlack
                        : themeContext.colors.whiteRed
                    }
                  >
                    {getYMD(firstBookableAt, true)}
                    <Hour>{getTime(firstBookableAt, true)}</Hour>
                  </CardDateValues>
                </BookingStartBlock>
                <BookingEndBlock>
                  <CardDateTitle>{t('bookingEnd').toUpperCase()}</CardDateTitle>
                  <CardDateValues
                    color={
                      moment().isBefore(lastBookableAt)
                        ? themeContext.colors.darkBlack
                        : themeContext.colors.whiteRed
                    }
                  >
                    {getYMD(lastBookableAt, true)}
                    <Hour>{getTime(lastBookableAt, true)}</Hour>
                  </CardDateValues>
                </BookingEndBlock>
              </BookingStartEndBlock>
            </CardValidBlock>
            {resources?.length && (
              <GroupLeadersBlock>
                <GroupLeadersBlockName>{t('groupLeaders')}</GroupLeadersBlockName>
                {resources.map((element) => {
                  return (
                    <GroupLeaderBlock key={element.id}>
                      <Avatar
                        size={64}
                        url={element.avatarUrl}
                        style={{ marginRight: '22px' }}
                      />
                      <GroupLeaderName>{element.name}</GroupLeaderName>
                      <InfoIcon
                        onClick={() => {
                          setIdOpenModal([element.id]);
                        }}
                      />
                    </GroupLeaderBlock>
                  );
                })}
              </GroupLeadersBlock>
            )}
            <OccasionsBlock>
              <CourseOccasions
                data={data}
                isBookPerOccasion={isBookPerOccasion}
                selectedButtons={selectedButtons}
                setSelectedButtons={setSelectedButtons}
                page={page}
                totalPages={totalPages}
                totalRows={totalRows}
                id={id}
                itemsToSelect={itemsToSelect}
                allEntries={allEntries}
                isCourseBookable={isCourseBookable()}
                setAllEntries={setAllEntries}
                fetchCourseOccasions={fetchCourseOccasions}
                addToCourseOccasionWaitingList={addToCourseOccasionWaitingList}
                setModal={setModal}
                proxyUsersMode={proxyUsersMode}
              />
            </OccasionsBlock>
          </Flex>
        </Flex>
        {renderCourseFooter()}
      </Card>
      {isIdModalOpen.length ? (
        <Modal>
          <ModalBody>
            <IconClose
              onClick={() => {
                setIdOpenModal([]);
              }}
            />
            {resources.map((element) => {
              if (isIdModalOpen.includes(element.id)) {
                return (
                  <div key={element.id}>
                    <Avatar
                      size={115}
                      url={element.avatarUrl}
                      style={{ marginRight: '22px' }}
                    />
                    <ModalCardName>{element.name}</ModalCardName>
                    <ModalCardDescription>{element.description}</ModalCardDescription>
                  </div>
                );
              }
              return null;
            })}
          </ModalBody>
        </Modal>
      ) : null}
      <WaitingListModal
        isOpen={!!modal}
        onClose={() => setModal(null)}
        course={course}
        onSubmit={handleSubmit}
        isLoading={loading}
      />
    </>
  );
});
