import { devices } from 'constants/mediaConstants';
import styled, { DefaultTheme } from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

type ScheduleContentProps = {
  disabled?: boolean;
  isRed?: boolean;
  theme: DefaultTheme;
  isSchedule?: boolean;
};

type IconProps = {
  icon: string;
  disabled?: boolean;
};

export const ScheduleBlock = styled.div`
  display: flex;
  min-height: 69px;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.middleGray};
`;

export const ScheduleContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props: ScheduleContentProps) =>
    props.isSchedule ? 'space-between' : 'flex-start'};
  align-items: ${(props: ScheduleContentProps) =>
    props.isSchedule ? 'center' : 'flex-start'};
  color: ${(props: ScheduleContentProps) =>
    props.isRed ? props.theme.colors.red : props.theme.colors.darkBlack};
  opacity: ${(props: ScheduleContentProps) => (props.disabled ? 0.3 : 1)};
`;

export const ScheduleHeadBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.middleGray};
`;

export const ScheduleName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

export const ScheduleLength = styled.div`
  margin-left: 5px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.darkGray};
  @media ${devices.mobile} {
    font-size: 12px;
  } ;
`;

export const CustomIcon = styled.div`
  height: 20px;
  width: 20px;
  margin: 9px 45px 9px auto;
  opacity: ${(props: IconProps) => (props.disabled ? '0.3' : '1')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props: IconProps) => props.icon});
  @media ${devices.mobile} {
    margin: 9px 35px 9px auto;
  } ;
`;

export const ScheduleWaitListButton = styled.div`
  width: 85px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid ${({ theme }) => theme.colors.whiteRed};
  color: ${({ theme }) => theme.colors.whiteRed};
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  @media ${devices.mobile} {
    width: 65px;
    height: 38px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const SelectButton = styled.div`
  width: 85px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  @media ${devices.mobile} {
    width: 65px;
    height: 38px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const CustomDoneIcon = styled.div`
  height: 20px;
  width: 20px;
  margin: 9px 45px 9px auto;
  opacity: ${(props: IconProps) => (props.disabled ? '0.3' : '1')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props: IconProps) => props.icon});
  cursor: pointer;
  @media ${devices.mobile} {
    margin: 9px 35px 9px auto;
  } ;
`;

export const SelectAllButton = styled.div`
  width: 110px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  @media ${devices.mobile} {
    width: 95px;
    height: 38px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const ScheduleDate = styled.div<{ isSchedule: boolean }>`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 18px;
  margin-left: 10px;
  margin-right: ${({ isSchedule }) => (isSchedule ? '0' : '25px')};
  @media ${devices.mobile} {
    font-size: 12px;
    line-height: 13px;
  }
`;

export const WeekdayName = styled.div`
  margin-left: 10px;
  margin-top: 2px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.middleLightGray};
  @media ${devices.mobile} {
    font-size: 10px;
  } ;
`;
export const ScheduleDurationTime = styled.div<{ isSchedule: boolean }>`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  ${({ isSchedule }) =>
    isSchedule &&
    `
    white-space: nowrap;
    width: min-content;
    text-align: center;
  `}
  @media ${devices.mobile} {
    font-size: 12px;
  } ;
`;
export const LoadMoreButton = styled(PrimaryButton)`
  max-height: 38px;
  width: 130px;
  margin: 25px auto 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  box-sizing: border-box;
  border-radius: 19px;
  color: ${({ theme }) => theme.colors.darkBlack};

  @media ${devices.mobile} {
    max-height: 38px;
    width: 130px;
    padding: 12px 30px;
    font-size: 12px;
  }
  &:hover {
    opacity: 0.7;
  }
`;
