import styled from 'styled-components';
import { getTime, getDayMonthTime } from 'helpers/DateTimeUtils';
import { PrimaryButton } from 'styled/PrimaryButton';
import moment from 'moment';
import { devices } from 'constants/mediaConstants';

type TimeButtonProps = {
  disabled?: boolean;
  isParsedTime?: boolean;
  startTime: string;
  endTime?: string;
  active: boolean;
  margin?: string | undefined;
  action: () => void;
  availableCount?: number;
};

type ButtonWrapperProps = {
  disabled: boolean | undefined;
};

const ButtonWrapper = styled.div`
  opacity: ${(props: ButtonWrapperProps) => (props.disabled ? '0.4' : '1')};
`;

const Button = styled(PrimaryButton)`
  height: 35px;
  padding: 12px;
  border-radius: 17.5px;

  @media ${devices.mobile} {
    font-size: 14px;
    padding: 8px;
  }
`;

export const getTimeText = (
  startTime: string,
  endTime?: string,
  isParsedTime?: boolean
) => {
  let timeText = '';
  if (isParsedTime) {
    timeText = startTime;
  } else if (!endTime) {
    timeText = getTime(startTime, true);
  } else {
    if (moment.parseZone(startTime).isSame(moment.parseZone(endTime), 'date')) {
      timeText = `${getTime(startTime, true)} ➜ ${getTime(endTime, true)}`;
    } else {
      timeText = `${getDayMonthTime(startTime, true)} ➜ ${getDayMonthTime(
        endTime,
        true
      )}`;
    }
  }
  return timeText;
};

export const TimeButton = (props: TimeButtonProps) => {
  const {
    startTime,
    endTime,
    action,
    active,
    isParsedTime,
    disabled,
    availableCount,
  } = props;

  const timeText = getTimeText(startTime, endTime, isParsedTime);
  return (
    <ButtonWrapper disabled={disabled} data-testid="timerButton">
      <Button onClick={action} secondary={!active} disabled={disabled}>
        {timeText}
        {availableCount && ` (${availableCount})`}
      </Button>
    </ButtonWrapper>
  );
};
