import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Error } from 'components/Error/Error';
import { Loader } from 'components/Loader/Loader';
import { urls } from 'helpers';
import { CheckoutStore } from 'store/CheckoutStore';
import { Namespaces } from 'languages';
import {
  ERROR_NOT_FOUND,
  TRANSACTION_LINK_EXPIRED,
  CHECKOUT_DELETED,
} from 'constants/apiErrorCodeConstants';

export const PaymentLinkPage = observer(() => {
  const [loading, setLoading] = useState(true);
  const [errorCode, setErrorCode] = useState('');
  const { t } = useTranslation(Namespaces.UI);
  const { getCheckoutFromHash } = CheckoutStore;
  const { transactionLinkHash } = useParams<{ transactionLinkHash: string }>();
  const history = useHistory();
  useEffect(() => {
    getCheckoutFromHash(transactionLinkHash, setLoading).then((data) => {
      if (data.profileId && data.checkoutId) {
        const { profileId, checkoutId } = data;
        history.push(
          urls.checkoutWithTransactionLinkHash(profileId, checkoutId, transactionLinkHash)
        );
      } else {
        setErrorCode(data.error as string);
      }
    });
  }, []);

  let message = t('errorMessageDefault');
  if (errorCode === ERROR_NOT_FOUND) {
    message = t('errorHashNotFound');
  } else if (errorCode === TRANSACTION_LINK_EXPIRED) {
    message = t('errorLinkExpired');
  } else if (errorCode === CHECKOUT_DELETED) {
    message = t('errorCheckoutDeleted');
  }

  return (
    <>
      {loading && <Loader />}
      {errorCode && (
        <Error
          errorMessage={message}
          callback={() => {
            history.push(urls.services(localStorage.profileId));
          }}
        />
      )}
    </>
  );
});
