import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import Layout from 'styled/Layout';
import { PrimaryButton } from 'styled/PrimaryButton';

export const Card = styled(Layout)`
  position: relative;
  padding: 35px 39px 23px;
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: ${(props: { collapsed: boolean }) => (props.collapsed ? 'auto' : 'pointer')};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;
  @media ${devices.mobile} {
    padding: 30px 30px 15px;
  }
`;

export const CardHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
`;

export const CardDescription = styled.div`
  margin: 10px 0;
  padding-right: 40px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.middleDark};
  white-space: pre-wrap;
  @media ${devices.mobile} {
    font-size: 15px;
    padding-right: 20px;
  } ;
`;

export const CardButton = styled(PrimaryButton)`
  max-height: 56px;
  width: 214px;
  padding: 17px 50px;
  margin: 25px auto 0;
  font-size: 14px;

  @media ${devices.mobile} {
    max-height: 38px;
    width: 170px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;

export const Price = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'normal' : 'bold'};
  font-size: 20px;
  text-decoration-line: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'line-through' : 'none'};
`;
