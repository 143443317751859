import styled from 'styled-components';
import { Modal } from 'antd';

export const $WaitingListModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 30px;
  }
`;

export const ModalContent = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  p {
    margin: 0;
  }
  .modal-title {
    font-size: 18px;
    font-weight: 700;
  }
  .modal-helper {
    font-size: 14px;
    font-weight: 400;
  }
  .modal-buttons {
    display: flex;
    gap: 17px;
    justify-content: start;
    div {
      height: 37px;
    }
  }
  .users-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    p {
      margin: 0;
    }
    .new-person-button {
      height: 37px;
      width: 173px;
      border-radius: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin-top: 10px;
    }
    > div {
      display: flex;
      justify-content: space-between;
      position: relative;
      align-items: center;
      padding: 12px;
      padding-left: 30px;
      border: 1px solid ${({ theme }) => theme.colors.middleGray};
      border-radius: 10px;
      .user-info {
        display: flex;
        align-items: center;
        overflow: hidden;
        p {
          font-size: 20px;
          overflow: hidden;
          margin: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }
      .add-button {
        height: 32px;
      }
      .remove-button {
        position: absolute;
        right: -12px;
        * {
          cursor: pointer;
        }
      }
    }
  }
  .save-button-container {
    display: flex;
    justify-content: center;
    .save-button {
      width: 70%;
      height: 56px;
      margin-top: 20px;
    }
  }
`;
