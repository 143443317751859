import { useEffect, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import CheckIcon from 'assets/icons/check.svg';
import { observer } from 'mobx-react-lite';
import { CustomIcon } from './CustomIcon/CustomIcon';
import { renderInputBorder } from 'helpers/StylesUtils';

const InputBlock = styled.label`
  position: relative;
  display: block;
  min-height: 57px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 11px;
  margin-bottom: ${(props: { error?: string }) => (props.error ? '40px' : '0')};
  padding: 7px 17px;
  background: ${(props: { error?: string }) => (props.error ? '#FFFBFB' : '#ffffff')};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border: ${(props: { error?: string; focus: boolean }) =>
    renderInputBorder(props.focus, props.error)};
  border-radius: 10px;
  input {
    background: ${(props: { error?: string }) => (props.error ? '#FFFBFB' : '#ffffff')};
  }
  user-select: none;
`;

const InputHeader = styled.div`
  color: #a6a6a6;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;

type CustomInputProps = {
  theme: DefaultTheme;
  placeholder?: string;
};

const CustomInput = styled.input`
  width: 100%;
  color: ${(props: CustomInputProps) => props.theme.colors.darkBlack};
  border: 0;
  outline: none;
  font-family: ${(props: CustomInputProps) => props.theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
`;

const Required = styled.div`
  position: absolute;
  top: calc(50% - 4px);
  right: -20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fc8575;
`;

const Verified = styled.div`
  position: absolute;
  top: calc(50% - 6px);
  right: -25px;
`;

const ErrorText = styled.div`
  position: absolute;
  bottom: -20px;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;

type Props = {
  verified?: boolean;
  value: string;
  title: string;
  error: string;
  required?: boolean;
  disabled: boolean;
  change?: (value: string) => void;
  iconElement?: JSX.Element;
  forceFocus?: boolean;
  id?: string;
  placeholder?: string;
  type?: string;
  maxLength?: number | undefined;
  onKeyDown?: (value: React.KeyboardEvent) => void;
};

const IconElement = styled.div`
  position: absolute;
  right: -50px;
  top: calc(50% - 16px);
`;

export const Input = observer((props: Props) => {
  const [focus, setFocus] = useState(false);
  const {
    verified,
    value,
    title,
    error,
    required,
    change,
    disabled,
    iconElement,
    forceFocus,
    id,
    placeholder,
    type,
    maxLength,
    onKeyDown,
  } = props;

  useEffect(() => {
    if (forceFocus) {
      setFocus(true);
    } else {
      setFocus(false);
    }
  }, [forceFocus]);

  const renderStatusItem = () => {
    if (!required && !verified) {
      return null;
    }
    if (required && !verified) {
      return <Required />;
    }
    return (
      <Verified>
        <CustomIcon width="14px" height="12px" icon={CheckIcon} />
      </Verified>
    );
  };
  return (
    <InputBlock
      focus={focus}
      error={error}
      id={id}
      data-testid="input"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <InputHeader>{title}</InputHeader>
      <CustomInput
        type={type || 'text'}
        disabled={disabled}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        onChange={(e) => {
          if (change) {
            change(e.target.value);
          }
        }}
        value={value}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (onKeyDown) {
            onKeyDown(e);
          }
        }}
        maxLength={maxLength}
      />
      {renderStatusItem()}
      {iconElement && <IconElement>{iconElement}</IconElement>}
      {error && <ErrorText>{`*${error}`}</ErrorText>}
    </InputBlock>
  );
});
