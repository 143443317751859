import { ChangeEvent, FC, useEffect, useState } from 'react';
import { CustomButton, GiftCardContainer } from './styles/giftCardStyles';
import CheckIcon from 'assets/icons/check.svg';
import { useHistory, useLocation } from 'react-router';
import { CheckoutStore } from 'store/CheckoutStore';
import { Loader } from 'components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';
import { HeaderStore } from 'store/HeaderStore';
import { LoginStore } from 'store/LoginStore';
import { PaymentStore } from 'store/PaymentStore';
import { MANDATORY } from 'constants/checkoutConstants';

const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

interface IProps {
  checkoutId: number;
}

export const GiftCard: FC<IProps> = ({ checkoutId }) => {
  const history = useHistory();
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const transactionLinkHash = new URLSearchParams(useLocation().search).get(
    'transactionLinkHash'
  );
  const { t } = useTranslation(Namespaces.UI);
  const { setBackButton, setTitle } = HeaderStore;
  const { user } = LoginStore;

  const {
    addGiftEmail,
    removeGiftEmail,
    checkout,
    checkoutFieldErrors,
    setFieldError,
    giftPayload,
    setGiftPayload,
  } = CheckoutStore;
  const {
    setActiveGiftEmail,
    activeGiftEmail,
    activeGiftEmailFirstName,
    activeGiftEmailLastName,
  } = PaymentStore;

  const { email, firstName, surname } = giftPayload;

  const handleAction = async () => {
    if (!isValidEmail(email)) {
      setFieldError('giftEmail', t('errorInvalidEmail'));
      return;
    }
    if (!user.id && !transactionLinkHash) {
      setActiveGiftEmail({ email, giftFirstName: firstName, giftLastName: surname });
      setBackButton(true);
      setTitle(t('loginTitle'));
      localStorage.prevPath = history.location.pathname + history.location.search;
      history.push('/auth');
      return;
    }

    setFieldError('giftEmail', '');
    setFieldError('giftFirstName', '');
    setFieldError('giftLastName', '');
    setIsLoading(true);

    if (isSaved) {
      await removeGiftEmail(checkoutId).then((e) => {
        if (e.success) {
          setGiftPayload({ email: '', firstName: '', surname: '' });
          setIsSaved(false);
        } else {
          setFieldError('giftEmail', e.message);
        }
      });
    } else {
      await addGiftEmail(
        checkoutId,
        { email, firstName, surname },
        transactionLinkHash
      ).then((e) => {
        if (e.success) {
          setIsSaved(true);
        } else {
          setFieldError('giftEmail', e.message);
        }
      });
    }

    setIsLoading(false);
  };

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setGiftPayload({ ...giftPayload, email: e.target.value });
    setFieldError('giftEmail', '');
    setFieldError('giftError', '');
  };

  const onChangeGiftFirstName = (e: ChangeEvent<HTMLInputElement>) => {
    setGiftPayload({ ...giftPayload, firstName: e.target.value });
    setFieldError('giftError', '');
    setFieldError('giftFirstName', '');
  };

  const onChangeGiftLastName = (e: ChangeEvent<HTMLInputElement>) => {
    setGiftPayload({ ...giftPayload, surname: e.target.value });
    setFieldError('giftError', '');
    setFieldError('giftLastName', '');
  };

  useEffect(() => {
    if (checkout?.giftEmail || checkout?.giftFirstName || checkout?.giftSurname) {
      setGiftPayload({
        email: checkout?.giftEmail,
        firstName: checkout?.giftFirstName,
        surname: checkout?.giftSurname,
      });
      setIsSaved(true);
    }
  }, [checkout?.giftEmail, checkout?.giftFirstName, checkout?.giftSurname]);

  useEffect(() => {
    if (activeGiftEmail && user.id) {
      setIsLoading(true);
      addGiftEmail(
        checkoutId,
        {
          email: activeGiftEmail,
          firstName: activeGiftEmailFirstName,
          surname: activeGiftEmailLastName,
        },
        transactionLinkHash
      )
        .then((e) => {
          if (e.success) {
            setIsSaved(true);
            setGiftPayload({
              email: activeGiftEmail,
              firstName: activeGiftEmailFirstName,
              surname: activeGiftEmailLastName,
            });
            setActiveGiftEmail({ email: '', giftFirstName: '', giftLastName: '' });
          } else {
            setFieldError('giftEmail', e.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [activeGiftEmail, user.id]);

  useEffect(() => {
    return () => {
      setGiftPayload({ email: '', firstName: '', surname: '' });
    };
  }, []);

  return (
    <GiftCardContainer>
      <div
        style={{
          position: 'relative',
        }}
      >
        <div style={{ display: 'flex', gap: '10px' }}>
          <div style={{ width: '50%' }}>
            <div className="input-container">
              <input
                placeholder={t('giftFirstName')}
                value={firstName}
                onChange={onChangeGiftFirstName}
                disabled={isSaved}
              />
            </div>
            {checkoutFieldErrors?.giftFirstName && (
              <p className="error-text">{checkoutFieldErrors?.giftFirstName}</p>
            )}
          </div>
          <div style={{ width: '50%' }}>
            <div className="input-container">
              <input
                placeholder={t('giftLastName')}
                value={surname}
                onChange={onChangeGiftLastName}
                disabled={isSaved}
              />
              {isSaved ? (
                <img src={CheckIcon} alt="Check" className="check-icon" />
              ) : checkout?.giftMode === MANDATORY ? (
                <div className="mandatory-circle" />
              ) : (
                <></>
              )}
            </div>
            {checkoutFieldErrors?.giftLastName && (
              <p className="error-text">{checkoutFieldErrors?.giftLastName}</p>
            )}
          </div>
        </div>
        <div className="input-container" style={{ width: '100%', marginTop: '20px' }}>
          <input
            placeholder={t('recipientEmail')}
            value={email}
            onChange={onChangeEmail}
            disabled={isSaved}
          />
          {isSaved ? (
            <img src={CheckIcon} alt="Check" className="check-icon" />
          ) : checkout?.giftMode === MANDATORY ? (
            <div className="mandatory-circle" />
          ) : (
            <></>
          )}
        </div>
        {checkoutFieldErrors?.giftEmail && (
          <p className="error-text">{checkoutFieldErrors?.giftEmail}</p>
        )}
      </div>
      {email && firstName && surname && (
        <>
          <CustomButton onClick={handleAction}>
            {isLoading ? (
              <Loader small isWhite />
            ) : isSaved ? (
              t('giftRecipientRemove')
            ) : (
              t('giftRecipientAdd')
            )}
          </CustomButton>
          {checkoutFieldErrors?.giftError && (
            <p className="error-text" style={{ marginTop: '-10px', alignSelf: 'center' }}>
              {checkoutFieldErrors?.giftError}
            </p>
          )}
        </>
      )}
    </GiftCardContainer>
  );
};
