import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { getYMD, getTimeLineRange } from 'helpers/DateTimeUtils';
import { FlexibleSlot } from 'store/ServiceStore';
import RoundArrow from 'assets/icons/roundArrow.svg';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import {
  HourBlock,
  HourBlockTitle,
  MinutesWrapper,
  MinuteBlock,
  DataDay,
  DragCircle,
  DragCircleTouch,
  MinuteLine,
  HourLine,
  TimeBlock,
  TimeWrap,
} from './styles';
import { getRangeTime, getStartAllowedTime } from './TimeLineHelpers';

const subTimeSlot = 30;

export interface Slot {
  startRangeTime: number | null;
  endRangeTime: number | null;
}

export interface TimeLineProps {
  slots: FlexibleSlot[];
  resourceId: number;
  activeSlots: {
    startRangeTime: number | null;
    endRangeTime: number | null;
  };
  isMobileBrowser: boolean;
  rangeIncrementMinutes: number;
  maxBookableMinutes?: number;
  minBookableMinutes?: number;
  rangeStartTime: string;
  rangeEndTime: string;
  setActiveSlot: ({
    startRangeTime,
    endRangeTime,
  }: {
    startRangeTime: number | null;
    endRangeTime: number | null;
  }) => void;
  onTimeRangeSelect?: (e?: Slot) => void;
}

type ActiveRangeElementProps = {
  [key: number]: { time: number };
  width: number;
  range: number[];
};

export const TimeLine: FC<TimeLineProps> = ({
  slots,
  activeSlots,
  setActiveSlot,
  rangeStartTime,
  rangeEndTime,
  rangeIncrementMinutes,
  maxBookableMinutes,
  minBookableMinutes,
  resourceId,
  isMobileBrowser,
  onTimeRangeSelect,
}) => {
  const hoursPerPage = isMobileBrowser ? 5 : 10;
  const stepsForArrow = isMobileBrowser ? 3 : 5;
  const { startRangeTime, endRangeTime } = activeSlots;
  const allowedTime = getRangeTime(slots);
  const [dragged, setDragged] = useState(false);
  const startActiveRange = getStartAllowedTime(allowedTime[0][0], hoursPerPage);
  const [activeRange, setActiveRange] = useState([
    startActiveRange,
    startActiveRange + hoursPerPage,
  ]);
  const [activeRangeElements, setActiveRangeElements] = useState<ActiveRangeElementProps>(
    { width: 0, range: [0, 0] }
  );
  const rangeRef = useRef<Slot | null | undefined>(null);
  const [startFrom, endFrom] = getTimeLineRange(rangeStartTime, rangeEndTime, true);
  const [timeArrayData, setTimeArray] = useState<number[]>([]);
  const getElementsFromDocument = useCallback(
    (allowedDateTime: number[], timeArray: number[]) => {
      const [start, end] = allowedDateTime || activeRangeElements.range;
      const elements = Array.from(
        document.getElementsByClassName(`${start}-${end}-${resourceId}`)
      );
      const filteredTime = timeArray.filter((el) => el >= start && el <= end);
      return elements.reduce((acc, element, j) => {
        const { x, width } = element.getBoundingClientRect();
        return {
          ...acc,
          [Math.round(x)]: {
            time: filteredTime[j],
          },
          width: Math.round(width),
          range: [start, end],
        };
      }, {});
    },
    [activeRangeElements.range, resourceId]
  );

  useEffect(() => {
    if (activeRangeElements.width) {
      const elements = getElementsFromDocument(
        activeRangeElements.range,
        timeArrayData
      ) as ActiveRangeElementProps;
      if (elements.width) {
        setActiveRangeElements(elements);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRange]);

  useEffect(() => {
    const nowStartActiveRange = Math.trunc((activeSlots.startRangeTime as number) / 60);
    const activeRangePair =
      nowStartActiveRange > activeRange[0] && nowStartActiveRange < activeRange[1];
    const isRangeValue =
      (activeSlots.startRangeTime as number) !== null &&
      (activeSlots.endRangeTime as number) !== null;
    if (
      (isRangeValue && !dragged && !activeRangeElements.width) ||
      (isRangeValue && !activeRangePair && !dragged)
    ) {
      const start = Math.trunc((activeSlots.startRangeTime as number) / 60);
      if (start === 0) {
        setActiveRange([start, start + hoursPerPage]);
      } else {
        setActiveRange([start - 1, start - 1 + hoursPerPage]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlots, hoursPerPage]);

  const fixTouchMove = () => {
    return undefined;
  };

  useEffect(() => {
    document.body.removeEventListener('touchstart', fixTouchMove);
    document.body.addEventListener('touchstart', fixTouchMove);
  }, []);

  useEffect(() => {
    if (
      activeSlots.startRangeTime !== null &&
      activeSlots.endRangeTime !== null &&
      !activeRangeElements.width
    ) {
      const allowedDateTime = allowedTime.find(
        (el) =>
          el[0] <= (activeSlots.startRangeTime as number) &&
          el[1] >= (activeSlots.endRangeTime as number)
      );

      if (allowedDateTime) {
        setActiveRangeElements(
          getElementsFromDocument(
            allowedDateTime,
            timeArrayData
          ) as ActiveRangeElementProps
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeRangeElements.width,
    activeSlots.endRangeTime,
    activeSlots.startRangeTime,
    timeArrayData,
  ]);

  const moveRangeToLeft = () => {
    let [start, end] = activeRange;
    if (start === startFrom) {
      return;
    }
    if (start - stepsForArrow <= startFrom) {
      end -= start;
      start = startFrom;
    } else {
      start -= stepsForArrow;
      end -= stepsForArrow;
    }
    if (activeSlots.startRangeTime === null && activeSlots.endRangeTime === null) {
      setActiveRangeElements({ width: 0, range: [0, 0] });
    }
    setActiveRange([start, end]);
  };

  const moveRangeToRight = () => {
    let [start, end] = activeRange;
    if (end === endFrom) {
      return;
    }
    if (end + stepsForArrow >= endFrom) {
      start += endFrom - end;
      end = endFrom;
    } else {
      start += stepsForArrow;
      end += stepsForArrow;
    }
    if (activeSlots.startRangeTime === null && activeSlots.endRangeTime === null) {
      setActiveRangeElements({ width: 0, range: [0, 0] });
    }
    setActiveRange([start, end]);
  };

  const getAllowedStatus = (minutesOfTime: number) => {
    return allowedTime.find((el) => {
      const [start, end] = el;
      if (minutesOfTime >= start && minutesOfTime < end) {
        return el;
      }
      return false;
    });
  };

  const getElementWithNearCoordinate = (coordinate: number) => {
    for (let i = coordinate - 4; i < coordinate + 4; i++) {
      if (activeRangeElements[i]) {
        return activeRangeElements[i];
      }
    }

    return false;
  };

  const getElementWithNearCoordinateInMobile = (coordinate: number) => {
    for (let i = Math.trunc(coordinate) - 4; i < Math.trunc(coordinate) + 4; i++) {
      if (activeRangeElements[i]) {
        return activeRangeElements[i];
      }
    }

    return false;
  };

  const handlerStartCircle = () => {
    const cloneActiveSlots = { ...activeSlots };
    return (e: MouseEvent) => {
      const timeElement = getElementWithNearCoordinate(e.clientX);
      if (timeElement) {
        const { time } = timeElement;
        if (time < (endRangeTime as number) && cloneActiveSlots.startRangeTime !== time) {
          setActiveSlot({ startRangeTime: time, endRangeTime });
          cloneActiveSlots.startRangeTime = time;
          rangeRef.current = { startRangeTime: time, endRangeTime };
        }
      }
    };
  };

  const handlerStartCircleMobile = () => {
    const cloneActiveSlots = { ...activeSlots };
    return (e: TouchEvent) => {
      const timeElement = getElementWithNearCoordinateInMobile(e.touches[0].clientX);

      if (timeElement) {
        const { time } = timeElement;
        if (time < (endRangeTime as number) && cloneActiveSlots.startRangeTime !== time) {
          setActiveSlot({ startRangeTime: time, endRangeTime });
          cloneActiveSlots.startRangeTime = time;
          rangeRef.current = { startRangeTime: time, endRangeTime };
        }
      }
    };
  };

  const handlerEndCircleMobile = () => {
    const cloneActiveSlots = { ...activeSlots };
    return (e: TouchEvent) => {
      const width = activeRangeElements.width ? activeRangeElements.width : 0;
      const endCoordinates = e.touches[0].clientX - width;
      const timeElement = getElementWithNearCoordinateInMobile(endCoordinates);
      if (timeElement) {
        const { time } = timeElement;
        if (time >= (startRangeTime as number)) {
          setActiveSlot({
            startRangeTime,
            endRangeTime: time + rangeIncrementMinutes,
          });
          cloneActiveSlots.endRangeTime = time;
          rangeRef.current = {
            startRangeTime,
            endRangeTime: time + rangeIncrementMinutes,
          };
        }
      }
    };
  };

  const handlerEndCircle = () => {
    const cloneActiveSlots = { ...activeSlots };
    return (e: MouseEvent) => {
      const width = activeRangeElements.width ? activeRangeElements.width : 0;
      const endCoordinates = e.clientX - width;
      const timeElement = getElementWithNearCoordinate(endCoordinates);
      if (timeElement) {
        const { time } = timeElement;
        if (
          time >= (startRangeTime as number) &&
          cloneActiveSlots.endRangeTime !== time
        ) {
          setActiveSlot({
            startRangeTime,
            endRangeTime: time + rangeIncrementMinutes,
          });
          cloneActiveSlots.endRangeTime = time;
          rangeRef.current = {
            startRangeTime,
            endRangeTime: time + rangeIncrementMinutes,
          };
        }
      }
    };
  };

  const removeListener = (handlerFunc: (e: MouseEvent) => void, wrap: () => void) => {
    const elem = document.getElementById(`timeline-${resourceId}`);
    if (elem) {
      setDragged(false);
      elem.removeEventListener('mousemove', handlerFunc);
      document.removeEventListener('mouseup', wrap);
      if (onTimeRangeSelect) {
        onTimeRangeSelect(rangeRef.current || undefined);
      }
    }
  };

  const removeListenerMobile = (
    handlerFunc: (e: TouchEvent) => void,
    wrap: () => void
  ) => {
    const elem = document.getElementById(`timeline-${resourceId}`);
    if (elem) {
      setDragged(false);
      elem.removeEventListener('touchmove', handlerFunc);
      document.removeEventListener('touchend', wrap);
      if (onTimeRangeSelect) {
        onTimeRangeSelect(rangeRef.current || undefined);
      }
    }
  };

  const handleTouchMobile = (e: React.TouchEvent) => {
    const startPoint = document.getElementById(`endingpoint-start-${resourceId}`);
    const endPoint =
      document.getElementById(`endingpoint-end-${resourceId}`) ||
      document.getElementById(`startingpoint-${resourceId}`);
    const target = e?.target as HTMLElement;
    let closerPoint;

    if (target && startPoint && endPoint) {
      const targetRect = target.getBoundingClientRect();
      const startPointRect = startPoint.getBoundingClientRect();
      const endPointRect = endPoint.getBoundingClientRect();

      const distanceToStart = Math.abs(targetRect.left - startPointRect.left);
      const distanceToEnd = Math.abs(targetRect.left - endPointRect.left);

      closerPoint = distanceToStart < distanceToEnd ? 'start' : 'end';
    }

    setDragged(true);

    const handlerFunc =
      closerPoint === 'start' || (startPoint && !endPoint)
        ? handlerStartCircleMobile()
        : handlerEndCircleMobile();

    const wrap = () => {
      removeListenerMobile(handlerFunc, wrap);
    };
    const elem = document.getElementById(`timeline-${resourceId}`);
    if (elem) {
      elem.addEventListener('touchmove', handlerFunc);
      document.addEventListener('touchend', wrap);
    }
  };

  const renderRange = () => {
    const [start, end] = activeRange;
    const minutesPattern: number[] = [];
    for (let i = 0; i < 60; i += rangeIncrementMinutes) {
      minutesPattern.push(i);
    }
    const elements = Array.from(new Array(end - start));
    const timeArray: number[] = [];
    const arr = elements.map((slot, i) => {
      let time = start + i;
      const timeForArray = start + i;
      const minutesOfTime = time * 60;
      if (time > 23) {
        time = start + i - 24;
      }
      return (
        <HourBlock key={i}>
          {time === 0 && i ? <DataDay>{getYMD(rangeEndTime, true)}</DataDay> : null}
          <HourBlockTitle>{time < 10 ? `0${time}` : time}</HourBlockTitle>
          <MinutesWrapper>
            {minutesPattern.map((el, key) => {
              const allowedDateTime = getAllowedStatus(minutesOfTime + el);
              if (allowedDateTime) {
                timeArray.push(timeForArray * 60 + el);
              }

              const isActive =
                (startRangeTime as number) <= minutesOfTime + el &&
                minutesOfTime + el < (endRangeTime as number) &&
                activeSlots.startRangeTime !== null &&
                activeSlots.endRangeTime !== null;

              const isPrevActive =
                (startRangeTime as number) <=
                  minutesOfTime + el - rangeIncrementMinutes &&
                minutesOfTime + el - rangeIncrementMinutes < (endRangeTime as number);

              const isNextActive =
                (startRangeTime as number) <=
                  minutesOfTime + el + rangeIncrementMinutes &&
                minutesOfTime + el + rangeIncrementMinutes < (endRangeTime as number);

              const isStartActive = !isPrevActive && isActive;
              const isEndActive = !isNextActive && isActive;
              const isStartOrEndCircleHidden = isStartActive || isEndActive;
              const isExtraCircleRender = !isPrevActive && isActive && !isNextActive;

              const handleClick = (timeInMinutes: number) => {
                if (allowedDateTime) {
                  const startTimeRange =
                    Math.trunc(timeInMinutes / 60) * 60 < allowedDateTime[0]
                      ? allowedDateTime[0]
                      : Math.trunc(timeInMinutes / 60) * 60;
                  const endTimeRange =
                    Math.trunc(timeInMinutes / 60 + 1) * 60 > allowedDateTime[1]
                      ? allowedDateTime[1]
                      : Math.trunc(timeInMinutes / 60 + 1) * 60;
                  setActiveRangeElements(
                    getElementsFromDocument(
                      allowedDateTime,
                      timeArray
                    ) as ActiveRangeElementProps
                  );
                  setActiveSlot({
                    startRangeTime: startTimeRange,
                    endRangeTime: endTimeRange,
                  });
                  rangeRef.current = {
                    startRangeTime: startTimeRange,
                    endRangeTime: endTimeRange,
                  };
                  if (onTimeRangeSelect) {
                    onTimeRangeSelect(rangeRef.current);
                  }
                }
              };

              return (
                <MinuteBlock
                  key={key}
                  className={
                    allowedDateTime
                      ? `${allowedDateTime[0]}-${allowedDateTime[1]}-${resourceId}`
                      : ''
                  }
                  isStart={key === 0}
                  isEnd={key === minutesPattern.length - 1}
                  isPrevAllowed={
                    !!getAllowedStatus(minutesOfTime + el - rangeIncrementMinutes)
                  }
                  isNextAllowed={
                    !!getAllowedStatus(minutesOfTime + el + rangeIncrementMinutes)
                  }
                  isPrevActive={
                    isPrevActive && startRangeTime !== null && endRangeTime !== null
                  }
                  isNextActive={
                    isNextActive && startRangeTime !== null && endRangeTime !== null
                  }
                  isAllowed={!!allowedDateTime}
                  isError={
                    !!startRangeTime &&
                    !!endRangeTime &&
                    ((!!maxBookableMinutes &&
                      maxBookableMinutes < endRangeTime - startRangeTime) ||
                      (!!minBookableMinutes &&
                        minBookableMinutes > endRangeTime - startRangeTime))
                  }
                  isActive={isActive && startRangeTime !== null && endRangeTime !== null}
                  onClick={() => {
                    handleClick(minutesOfTime + el);
                  }}
                >
                  {isExtraCircleRender && (
                    <DragCircle
                      isStartActive={false}
                      isError={
                        !!startRangeTime &&
                        !!endRangeTime &&
                        ((!!maxBookableMinutes &&
                          maxBookableMinutes < endRangeTime - startRangeTime) ||
                          (!!minBookableMinutes &&
                            minBookableMinutes > endRangeTime - startRangeTime))
                      }
                      onMouseDown={(e) => {
                        setDragged(true);
                        e.preventDefault();
                        const handlerFunc = handlerEndCircle();
                        const wrap = () => {
                          removeListener(handlerFunc, wrap);
                        };

                        const elem = document.getElementById(`timeline-${resourceId}`);
                        if (elem) {
                          elem.addEventListener('mousemove', handlerFunc);
                          document.addEventListener('mouseup', wrap);
                        }
                      }}
                      id={`startingpoint-${resourceId}`}
                    />
                  )}
                  {isStartOrEndCircleHidden && (
                    <DragCircle
                      isStartActive={isStartActive}
                      id={
                        isStartActive
                          ? `endingpoint-start-${resourceId}`
                          : `endingpoint-end-${resourceId}`
                      }
                      isError={
                        !!startRangeTime &&
                        !!endRangeTime &&
                        ((!!maxBookableMinutes &&
                          maxBookableMinutes < endRangeTime - startRangeTime) ||
                          (!!minBookableMinutes &&
                            minBookableMinutes > endRangeTime - startRangeTime))
                      }
                      onMouseDown={(e) => {
                        setDragged(true);
                        e.preventDefault();
                        const handlerFunc = isStartActive
                          ? handlerStartCircle()
                          : handlerEndCircle();
                        const wrap = () => {
                          removeListener(handlerFunc, wrap);
                        };
                        const elem = document.getElementById(`timeline-${resourceId}`);

                        if (elem) {
                          elem.addEventListener('mousemove', handlerFunc);
                          document.addEventListener('mouseup', wrap);
                        }
                      }}
                    />
                  )}
                  {isMobileBrowser && !!allowedDateTime ? (
                    <>
                      <DragCircleTouch isStartActive onTouchStart={handleTouchMobile} />
                      <DragCircleTouch
                        isStartActive={false}
                        onTouchStart={handleTouchMobile}
                        isFirstSlot={
                          (minutesOfTime || 0) - allowedDateTime[0] < subTimeSlot
                        }
                        isLastSlot={
                          allowedDateTime[1] - (minutesOfTime || 0) < subTimeSlot
                        }
                      />
                    </>
                  ) : null}
                  {minutesPattern.length / 2 === key && <MinuteLine />}
                </MinuteBlock>
              );
            })}
          </MinutesWrapper>
          <HourLine
            isBlack={!!(time === 0 && i)}
            onClick={() => {
              const allowedTimeRange = getAllowedStatus(minutesOfTime);
              if (allowedTimeRange) {
                setActiveSlot({
                  startRangeTime:
                    minutesOfTime < allowedTimeRange[0]
                      ? allowedTimeRange[0]
                      : minutesOfTime,
                  endRangeTime:
                    minutesOfTime + 60 > allowedTimeRange[1]
                      ? allowedTimeRange[1]
                      : minutesOfTime + 60,
                });
                rangeRef.current = {
                  startRangeTime:
                    minutesOfTime < allowedTimeRange[0]
                      ? allowedTimeRange[0]
                      : minutesOfTime,
                  endRangeTime:
                    minutesOfTime + 60 > allowedTimeRange[1]
                      ? allowedTimeRange[1]
                      : minutesOfTime + 60,
                };
                if (onTimeRangeSelect) {
                  onTimeRangeSelect(rangeRef.current);
                }
              }
            }}
          />
        </HourBlock>
      );
    });
    if (
      timeArray[0] !== timeArrayData[0] ||
      timeArray[timeArray.length - 1] !== timeArrayData[timeArrayData.length - 1]
    )
      setTimeArray(timeArray);
    return arr;
  };

  return (
    <TimeBlock gap="0">
      <CustomIcon
        width="26px"
        height="26px"
        margin={isMobileBrowser ? '0 5px 0 0' : '0'}
        icon={RoundArrow}
        cursor
        onClick={moveRangeToLeft}
      />
      <TimeWrap id={`timeline-${resourceId}`}>{renderRange()}</TimeWrap>
      <CustomIcon
        width="26px"
        height="26px"
        margin={isMobileBrowser ? '0 0 0 5px' : '0'}
        icon={RoundArrow}
        cursor
        rotate
        onClick={moveRangeToRight}
      />
    </TimeBlock>
  );
};
