import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

export const GiftCardContainer = styled.div`
  padding: 27px 41px 11px 51px;
  display: flex;
  flex-direction: column;
  gap: 28px;

  @media ${devices.mobile} {
    padding: 27px 21px 11px 11px;
  }

  .input-container {
    display: flex;
    align-items: center;
    gap: 6px;
    position: relative;
    .check-icon {
      position: absolute;
      right: -24px;
      width: 18px;
    }
    .mandatory-circle {
      position: absolute;
      right: -24px;
      width: 12px;
      height: 12px;
      background: #fc8575;
      border-radius: 50%;
    }
    input {
      width: 100%;
      height: 72px;
      border: 1px solid ${({ theme }) => theme.colors.middleGray};
      border-radius: 10px;
      padding: 18px 21px;
      font-size: 20px;
      outline: none;
      ::placeholder {
        color: ${({ theme }) => theme.colors.darkGray};
        font-size: 20px;
      }
      @media ${devices.mobile} {
        height: 57px;
        padding: 10px 17px;
        font-size: 17px;
        ::placeholder {
          font-size: 17px;
        }
      }
    }
  }
  .error-text {
    font-size: 18px;
    text-align: center;
    margin: 0;
    color: #fc8575;
    text-align: left;
    margin-top: 10px;
    margin-left: 5px;
    @media ${devices.mobile} {
      margin-left: 5px;
      line-height: 19px;
    }
  }
`;

export const CustomButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 250px;
  height: 56px;
  align-self: center;

  @media ${devices.mobile} {
    max-height: 38px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;
