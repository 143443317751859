import styled from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

export const Container = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isExpanded }) => (isExpanded ? 'flex-start' : 'center')};
  background-color: #fff;
  border-radius: 20px;
  height: ${({ isExpanded }) => (isExpanded ? 'auto' : 'auto')};
  margin: 0 15px 20px;
  box-shadow: ${({ isExpanded }) =>
    isExpanded ? '0px 5px 15px rgba(0, 0, 0, 0.2)' : 'none'};
  position: relative;
  padding: 20px 25px 20px 30px;
  cursor: pointer;
  :hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }
  p {
    margin: 0;
  }
  .title {
    font-size: 20px;
    font-weight: 700;
  }
  .description {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CollapseButton = styled(PrimaryButton)`
  height: 38px;
  padding: 0;
  padding-left: 10px;
  border-radius: 10px;
  background-color: #f5f5f6;
  color: #a6a6a6;
  font-size: 18px;
  align-self: end;
  margin-top: 40px;
  ${Container}:hover & {
    color: #2d2d2e;
  }
`;
