import { useTranslation } from 'react-i18next';
import {
  convertMinutesToDate,
  convertMinutesToTime,
  isLocalTimeZoneSameAs,
  getYMD,
  getTime,
} from 'helpers/DateTimeUtils';
import { Namespaces } from 'languages';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import {
  APPOINTMENT_TYPE_FLEXIBLE,
  APPOINTMENT_TYPE_UNTIL_CANCEL,
} from 'constants/appointmentConstants';
import ArrowDownButton from 'assets/icons/arrowButtonDown.svg';
import Close from 'assets/icons/closeCircle.svg';
import {
  Container,
  DatesContainer,
  CardStartBlock,
  CardEndBlock,
  CardDateTitle,
  CardDateValues,
  TimeValue,
  DateValue,
  ArrowDownIcon,
  TimeZoneText,
} from './styles';
import { FC } from 'react';

interface IProps {
  type?: string;
  startAction?: () => void;
  endAction?: () => void;
  rangeStartTime?: string;
  startTime: string | number | null;
  endTime: string | number | null;
  showRemoveButton?: boolean;
  onRemoveButtonClicked?: () => void;
}

export const CardDateBlock: FC<IProps> = ({
  type,
  startAction,
  endAction,
  startTime,
  endTime,
  rangeStartTime,
  showRemoveButton,
  onRemoveButtonClicked,
}) => {
  const { t } = useTranslation(Namespaces.UI);

  let localTimeZoneDiffers = false;
  if (startTime && typeof startTime === 'string') {
    localTimeZoneDiffers = !isLocalTimeZoneSameAs(startTime as string);
  } else if (endTime && typeof endTime === 'string') {
    localTimeZoneDiffers =
      localTimeZoneDiffers || !isLocalTimeZoneSameAs(endTime as string);
  } else if (rangeStartTime) {
    localTimeZoneDiffers =
      localTimeZoneDiffers || !isLocalTimeZoneSameAs(rangeStartTime as string);
  }

  switch (type) {
    case APPOINTMENT_TYPE_FLEXIBLE: {
      return (
        <Container isBig>
          {localTimeZoneDiffers && <TimeZoneText>{t('timeZoneDifferText')}</TimeZoneText>}
          <DatesContainer>
            <CardStartBlock onClick={startAction}>
              <CardDateTitle>{t('start').toUpperCase()}</CardDateTitle>
              <CardDateValues bigFontSize>
                <DateValue>
                  {startTime !== null
                    ? convertMinutesToDate(rangeStartTime as string, startTime as number)
                    : getYMD(rangeStartTime, true)}
                </DateValue>
                <TimeValue>
                  {startTime === null
                    ? '--:--'
                    : convertMinutesToTime(rangeStartTime as string, startTime as number)}
                </TimeValue>
                <ArrowDownIcon>
                  <CustomIcon
                    width="20px"
                    height="17px"
                    icon={ArrowDownButton}
                    hover
                    cursor
                  />
                </ArrowDownIcon>
              </CardDateValues>
            </CardStartBlock>
            <CardEndBlock onClick={endAction}>
              <CardDateTitle>{t('end').toUpperCase()}</CardDateTitle>
              <CardDateValues bigFontSize>
                <DateValue>
                  {endTime !== null
                    ? convertMinutesToDate(rangeStartTime as string, endTime as number)
                    : getYMD(rangeStartTime, true)}
                </DateValue>
                <TimeValue>
                  {endTime === null
                    ? '--:--'
                    : convertMinutesToTime(rangeStartTime as string, endTime as number)}
                </TimeValue>
                <ArrowDownIcon>
                  <CustomIcon
                    width="20px"
                    height="17px"
                    icon={ArrowDownButton}
                    hover
                    cursor
                  />
                </ArrowDownIcon>
              </CardDateValues>
            </CardEndBlock>
          </DatesContainer>
        </Container>
      );
    }
    case APPOINTMENT_TYPE_UNTIL_CANCEL: {
      return (
        <Container>
          {localTimeZoneDiffers && <TimeZoneText>{t('timeZoneDifferText')}</TimeZoneText>}
          <DatesContainer>
            <CardStartBlock>
              <CardDateTitle>{t('start').toUpperCase()}</CardDateTitle>
              <CardDateValues bigFontSize>
                <TimeValue>{getYMD(startTime as string, true)}</TimeValue>
              </CardDateValues>
            </CardStartBlock>
            <CardEndBlock>
              <CardDateTitle>{t('end').toUpperCase()}</CardDateTitle>
              <CardDateValues bigFontSize>
                <TimeValue>
                  {endTime ? getYMD(endTime as string, true) : t('untilCancellation')}
                </TimeValue>
              </CardDateValues>
            </CardEndBlock>
          </DatesContainer>
        </Container>
      );
    }
    default: {
      return (
        <Container>
          {localTimeZoneDiffers && <TimeZoneText>{t('timeZoneDifferText')}</TimeZoneText>}
          <DatesContainer>
            <CardStartBlock>
              <CardDateTitle>{t('start').toUpperCase()}</CardDateTitle>
              <CardDateValues isLeft bigFontSize>
                <DateValue isBlack>{getYMD(startTime as string, true)}</DateValue>
                <TimeValue isUnderLine>{getTime(startTime as string, true)}</TimeValue>
              </CardDateValues>
            </CardStartBlock>
            <CardEndBlock>
              <CardDateTitle>{t('end').toUpperCase()}</CardDateTitle>
              <CardDateValues isLeft bigFontSize>
                {endTime ? (
                  <>
                    {getYMD(endTime as string, true) ===
                    getYMD(startTime as string, true) ? null : (
                      <DateValue isBlack>{getYMD(endTime as string, true)}</DateValue>
                    )}

                    <TimeValue isUnderLine>{getTime(endTime as string, true)}</TimeValue>
                  </>
                ) : (
                  t('untilCancellation')
                )}
              </CardDateValues>
            </CardEndBlock>

            {showRemoveButton && (
              <CustomIcon
                position="absolute"
                right="-15px"
                top="calc(50% - 15px)"
                width="30px"
                height="30px"
                icon={Close}
                onClick={() => {
                  if (onRemoveButtonClicked) {
                    onRemoveButtonClicked();
                  }
                }}
                hover
                cursor
              />
            )}
          </DatesContainer>
        </Container>
      );
    }
  }
};
