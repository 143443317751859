import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

export const TotalBlock = styled.div`
  max-width: 400px;
  margin: 30px auto 30px auto;
  padding: 23px 55px 19px 72px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 20px;

  @media ${devices.mobile} {
    margin: 30px auto 0;
    padding: 20px 10px 10px;
  }
`;

export const TotalPriceTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 29px;
  margin-right: 4px;

  @media ${devices.mobile} {
    font-size: 26px;
  }
`;

export const TotalPrice = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 29px;
  text-align: right;

  @media ${devices.mobile} {
    font-size: 26px;
  }
`;

export const VatAmount = styled.div`
  color: #a6a6a6;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

export const IconBlock = styled.div`
  width: 75px;
`;

export const PaymentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 400px;
  max-height: 58px;
  margin: 15px auto;
  padding: 17px 40px;
  color: ${({ theme }) => theme.colors.darkBlack};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  cursor: pointer;
  opacity: ${(props: { isDisabled: boolean }) => (props.isDisabled ? 0.5 : 1)};
  cursor: ${(props: { isDisabled: boolean }) =>
    props.isDisabled ? 'not-allowed' : 'pointer'};
  :hover {
    opacity: 0.7;
  }

  @media ${devices.mobile} {
    padding: 14px 10px;
  }
`;

export const PayText = styled.div`
  margin-right: 5px;
  font-weight: normal;
  font-size: 16px;
`;
export const PayBoldText = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

export const TermsError = styled.div`
  top: 100%;
  color: ${({ theme }) => theme.colors.red};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;

export const CheckoutErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 30px auto 30px auto;
  padding: 0 30px;
  background: #feecee;
  border-radius: 5px;
  text-align: center;
`;

export const CheckoutError = styled.div`
  padding: 5px 0;
  color: ${({ theme }) => theme.colors.red};
  font-size: 13px;
`;

export const CheckboxWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0 30px 0;
`;

export const ConfirmButton = styled(PrimaryButton)`
  margin: 30px auto 30px auto;
  max-width: 250px;
`;
