import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Loader } from 'components/Loader/Loader';
import { getPrice } from 'helpers';
import { Namespaces } from 'languages';
import { CardDateBlock } from 'components/CardDateBlock/CardDateBlock';
import { APPOINTMENT_TYPE_UNTIL_CANCEL } from 'constants/appointmentConstants';
import { getAddBookingCTAEvent, postCTAEventMessage } from 'helpers/EventUtils';

import { AppointmentUntilCancellation } from 'store/ServiceStore';
import { CheckoutStore } from 'store/CheckoutStore';

import PriceIcon from 'assets/icons/price.svg';
import LocationIcon from 'assets/icons/location.svg';

import AppointmentCardContainer from 'styled/AppointmentCardContainer';
import AppointmentCardCoverImage from 'styled/AppointmentCardCoverImage';
import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { Avatar } from 'components/Avatar';
import {
  CardAddress,
  CardButton,
  CardDescription,
  CardHeader,
  CardPrice,
  CardPriceData,
} from './styles';
import { ExpandCollapse } from 'components/ExpandCollapse';
import { ActiveRow } from './ActiveRow';

const renderDescription = (
  isCollapsed: boolean,
  isTextShort: boolean,
  text: string,
  maxWidthString: number
) => {
  return isCollapsed || isTextShort ? text : `${text.slice(0, maxWidthString)}...`;
};

export const AppointmentCardUntilCancellation = observer(
  (props: { appointment: AppointmentUntilCancellation; defaultActive: boolean }) => {
    const [loading, setLoading] = useState(false);
    const [quantity, setQuantity] = useState(1);

    const {
      appointment: {
        avatarUrl,
        currency,
        name,
        description,
        pricePerUnit,
        price,
        address,
        coverImageUrl,
        startTime,
        endTime,
        checkoutItemData,
        availableCount,
        groupSelectMode,
      },
      defaultActive,
    } = props;

    const [isCollapsed, setCollapsed] = useState(defaultActive);
    const { profileId } = useParams<{ profileId: string }>();
    const { clientWidth } = document.body;
    const { addItemToCheckout } = CheckoutStore;
    const maxWidthString = clientWidth / 5;
    const isTextShort = description ? description.length < maxWidthString : true;
    const { t } = useTranslation(Namespaces.UI);

    const servicePrice = getPrice(price, currency, true, t(pricePerUnit));

    return (
      <AppointmentCardContainer
        collapsed={isCollapsed}
        data-testid="appointment-card-until-cancellation"
        withPaddingTop={!coverImageUrl}
      >
        {coverImageUrl && isCollapsed ? (
          <AppointmentCardCoverImage img={coverImageUrl} />
        ) : null}
        <Flex alignItems="center" style={{ padding: '0 25px' }}>
          {avatarUrl && (
            <Avatar size={50} url={avatarUrl} style={{ marginRight: '22px' }} />
          )}
          <CardHeader>{name}</CardHeader>
        </Flex>
        <CardDescription
          style={{
            marginTop: '9px',
            padding: '0 25px',
          }}
        >
          {renderDescription(isCollapsed, isTextShort, description, maxWidthString)}
        </CardDescription>
        {servicePrice && (
          <CardPrice>
            <CustomIcon width="21px" height="21px" margin="0 10px 0 0" icon={PriceIcon} />
            <CardPriceData>{servicePrice}</CardPriceData>
          </CardPrice>
        )}
        {address && (
          <CardAddress>
            <CustomIcon
              width="21px"
              height="21px"
              margin="0 10px 0 0"
              icon={LocationIcon}
            />
            <CardAddress>{address}</CardAddress>
          </CardAddress>
        )}
        <div style={{ padding: '0 25px' }}>
          <CardDateBlock
            type={APPOINTMENT_TYPE_UNTIL_CANCEL}
            startTime={startTime}
            endTime={endTime}
          />
        </div>
        {groupSelectMode === 'selectCount' ? (
          <ActiveRow
            item={{
              index: 0,
              quantity,
              startTime,
              endTime,
              checkoutItemData,
              availableCount,
            }}
            onSubmit={async () => {
              if (!loading) {
                await addItemToCheckout(profileId, [{ ...checkoutItemData, quantity }]);
                postCTAEventMessage(getAddBookingCTAEvent());
              }
            }}
            price={price}
            borderLess
            onNext={() => setQuantity(quantity + 1)}
            onPrev={() => setQuantity(quantity - 1)}
            withTime={false}
          />
        ) : (
          <CardButton
            onClick={() => {
              if (!loading) {
                setLoading(true);
                addItemToCheckout(profileId, [checkoutItemData], setLoading);
                postCTAEventMessage(getAddBookingCTAEvent());
              }
            }}
          >
            {loading ? <Loader isWhite small /> : t('addToCart')}
          </CardButton>
        )}
        {(!isTextShort || coverImageUrl) && (
          <ExpandCollapse isCollapsed={isCollapsed} setCollapsed={setCollapsed} />
        )}
      </AppointmentCardContainer>
    );
  }
);
