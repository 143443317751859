import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { CSSProperties, Dispatch, FC, SetStateAction } from 'react';
import ArrowDown from 'assets/icons/arrow-down-white.svg';
import ArrowUp from 'assets/icons/arrow-up-white.svg';
import { ExpandCollapseContainer } from 'styled/ExpandCollapseContainer';

interface IProps {
  isCollapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
  style?: CSSProperties;
}

export const ExpandCollapse: FC<IProps> = ({ isCollapsed, setCollapsed, style }) => {
  return (
    <ExpandCollapseContainer
      onClick={(e) => {
        e.stopPropagation();
        setCollapsed(!isCollapsed);
      }}
      style={{
        bottom: '-13px',
        position: 'absolute',
        right: '0px',
        left: '0px',
        ...style,
      }}
    >
      {isCollapsed ? (
        <CustomIcon
          bottom="1px"
          position="relative"
          width="13px"
          height="13px"
          icon={ArrowUp}
          hover
          cursor
        />
      ) : (
        <CustomIcon
          top="1px"
          position="relative"
          width="13px"
          height="13px"
          icon={ArrowDown}
          hover
          cursor
        />
      )}
    </ExpandCollapseContainer>
  );
};
