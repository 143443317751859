import styled from 'styled-components';

export const CalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  .no-courses {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const OccasionsWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.middleGray};
`;
