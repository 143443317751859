import { FC, useState } from 'react';

import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { getPrice } from 'helpers';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/Loader/Loader';
import { CollapseText } from 'components/CollapseText/CollapseText';
import { VoucherValidBlock } from 'components/VoucherDetails/VoucherValidBlock';
import { devices } from 'constants/mediaConstants';
import { getAddBookingCTAEvent, postCTAEventMessage } from 'helpers/EventUtils';

import { Namespaces } from 'languages';
import { CheckoutStore } from 'store/CheckoutStore';
import { Membership } from 'store/ServiceStore';

import PriceIcon from 'assets/icons/price.svg';
import ArrowDown from 'assets/icons/arrow-down.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';

import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { Card, CardHeader, CardDescription, CardButton, Price } from './styles';

interface IProps {
  memberships: Membership;
}

export const MembershipDetails: FC<IProps> = observer(({ memberships }) => {
  const {
    name,
    price,
    currency,
    description,
    validTo,
    id,
    validForAllServices,
    validServices,
  } = memberships;
  const { profileId } = useParams<{ profileId: string }>();
  const [loading, setLoading] = useState(false);
  const [isCollapsed, setCollapsed] = useState(true);
  const { addItemToCheckout } = CheckoutStore;
  const { t } = useTranslation(Namespaces.UI);
  const getRequestData = () => {
    return [
      {
        serviceId: id,
      },
    ];
  };

  return (
    <>
      <Card
        collapsed={isCollapsed}
        onClick={() => {
          setCollapsed(true);
        }}
      >
        <Flex>
          <Flex flexDirection="column">
            <CardHeader>{name}</CardHeader>
            <CardDescription>
              <CollapseText text={description} maxLines={4} />
            </CardDescription>
            <Flex alignItems="center">
              <CustomIcon
                width="16px"
                height="16px"
                margin="0 10px 0 0"
                icon={PriceIcon}
              />
              <Price>{getPrice(price, currency)}</Price>
            </Flex>
          </Flex>
        </Flex>
        {isCollapsed && (
          <>
            <VoucherValidBlock
              validTo={validTo}
              validForAllServices={validForAllServices}
              validServices={validServices}
            />
            <CardButton
              onClick={() => {
                if (!loading) {
                  setLoading(true);
                  addItemToCheckout(profileId, getRequestData(), setLoading);
                  postCTAEventMessage(getAddBookingCTAEvent());
                }
              }}
            >
              {loading ? <Loader isWhite small /> : t('addToCart')}
            </CardButton>
          </>
        )}
        <CustomIcon
          position="absolute"
          right="24px"
          bottom={isCollapsed ? '23px' : 'calc(50% - 6px)'}
          width="20px"
          height="12px"
          icon={isCollapsed ? ArrowUp : ArrowDown}
          onClick={(e) => {
            e?.stopPropagation();
            setCollapsed(!isCollapsed);
          }}
          transition="background-image 0s, bottom 0.5s"
          hover
          cursor
          device={devices.mobile}
          deviceHeight="9px"
          deviceWidth="15px"
        />
      </Card>
    </>
  );
});
