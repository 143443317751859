import { Namespaces } from 'languages';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckoutData } from 'store/CheckoutStore';
import {
  CardTitle,
  HeaderElements,
  Price,
  AddBookingButton,
  CancelModalText,
  CancelModalSubText,
  ButtonWrap,
  ModalCancelButton,
  ModalRejectButton,
} from '../styles/checkoutStyles';
import { Flex } from 'styled/Flex';
import { getPrice, urls } from 'helpers';
import { useHistory, useLocation } from 'react-router';
import { UserProxy } from 'store/UserStore';
import { CheckoutItemsCard } from '../CheckoutItemsCard';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import AlertImage from 'assets/icons/alert.svg';

export const BookingsHeaderInfo: FC<{
  checkoutData: CheckoutData;
}> = ({ checkoutData }) => {
  const { price, currency } = checkoutData;
  const { t } = useTranslation(Namespaces.UI);
  return (
    <HeaderElements>
      <Flex alignItems="center">
        <CardTitle>{t('bookings')}</CardTitle>
      </Flex>
      <Price>{getPrice(price, currency)}</Price>
    </HeaderElements>
  );
};

export const BookingsBodyInfo: FC<{
  checkoutData: CheckoutData;
  userProxies: UserProxy[];
  setActiveDeleteItemId: Dispatch<SetStateAction<number | undefined>>;
}> = ({ checkoutData, userProxies, setActiveDeleteItemId }) => {
  const { items } = checkoutData;
  const transactionLinkHash = new URLSearchParams(useLocation().search).get(
    'transactionLinkHash'
  ) as string;
  const history = useHistory();
  const { t } = useTranslation(Namespaces.UI);

  return (
    <>
      {items &&
        items.map((item, i) => (
          <CheckoutItemsCard
            checkoutId={checkoutData.id}
            item={item}
            key={i}
            setActiveDeleteItemId={setActiveDeleteItemId}
            transactionLinkHash={transactionLinkHash}
            userProxies={userProxies}
          />
        ))}
      <Flex justifyContent="center" margin="26px 0 0 0">
        {!transactionLinkHash && (
          <AddBookingButton
            size="large"
            onClick={() => {
              history.push(urls.services(localStorage.profileId));
            }}
          >
            {t('bookingsCardAddButton')}
          </AddBookingButton>
        )}
      </Flex>
    </>
  );
};

export const ModalCancelBody: FC<{
  handleCancel: () => void;
  handleModalKeep: () => void;
}> = ({ handleCancel, handleModalKeep }) => {
  const { t } = useTranslation(Namespaces.UI);
  return (
    <>
      <CustomIcon width="72px" height="72px" margin="0 auto" icon={AlertImage} />
      <CancelModalText>{t('checkoutRemoveBooking')}</CancelModalText>
      <CancelModalSubText>{t('checkoutRemoveBookingMessage')}</CancelModalSubText>
      <ButtonWrap>
        <ModalCancelButton onClick={handleCancel}>
          {t('checkoutRemoveBookingYes')}
        </ModalCancelButton>
        <ModalRejectButton onClick={handleModalKeep}>{t('noKeepIt')}</ModalRejectButton>
      </ButtonWrap>
    </>
  );
};
