import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import Layout from 'styled/Layout';
import { PrimaryButton } from 'styled/PrimaryButton';

export const Card = styled(Layout)`
  position: relative;
  padding: 30px 45px 19px 35px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  margin: 0 15px 20px;
  user-select: none;
  cursor: pointer;
  box-shadow: 4px 4px 15px 2px rgba(0, 0, 0, 0.03);
  border: 1px solid #ededed;
  @media${devices.mobile} {
    padding: 19px 25px 19px 25px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardTitle = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
`;

export const CardDescription = styled.div`
  width: 95%;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.middleDark};
  font-style: normal;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  white-space: pre-wrap;
`;

export const Price = styled.div`
  margin-left: 5px;
  font-style: normal;
  white-space: nowrap;
  font-weight: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'normal' : 'bold'};
  font-size: 15px;
  text-decoration-line: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'line-through' : 'none'};
`;

export const Balance = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.red};
  @media${devices.mobile} {
    font-size: 13px;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 0px 0px;
  font-size: 12px;
  letter-spacing: 0.4px;
`;

export const CardPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 18px;
`;

export const CourseDurationBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  opacity: 0.6;
`;

export const CourseDurationTitle = styled.div`
  font-size: 13px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  margin-left: 10px;
`;

export const CourseDuration = styled.div`
  font-size: 13px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  margin-left: 10px;
`;

export const CardTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  margin-top: 2px;
`;

export const WrapInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BookButton = styled(PrimaryButton)`
  padding: 6px 41px;
  border-radius: 19px;
  width: 108px;
  height: 34px;
  font-size: 15px;
`;

export const SpotsLeftLabel = styled.div`
  color: ${(props) => props.color};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  float: right;
  text-align: center;
  margin-top: 4px;
  position: absolute;
  right: 0;
  bottom: -18px;
  width: 100%;
`;

export const CardBody = styled.div``;

export const ServiceDuration = styled.div`
  margin-left: 5px;
  @media${devices.mobile} {
    font-size: 15px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;
