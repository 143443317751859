import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Namespaces } from 'languages';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

import ArrowDown from 'assets/icons/arrow-down-white.svg';
import ArrowUp from 'assets/icons/arrow-up-white.svg';
import ArrowLeft from 'assets/icons/arrow-left.svg';
import ArrowRight from 'assets/icons/arrow-right.svg';

import Calendar from 'react-calendar';
import './appointment_calendar.css';

import { Flex } from 'styled/Flex';
import {
  ContentContainer,
  TopContainer,
  CalendarTitleContainer,
  CalendarTitleMonth,
  CalendarContainer,
  TodayButton,
  SelectedDatesContainer,
  SelectedDatesTitle,
  MissingDateContainer,
  SelectedPeriodLabel,
} from './calendarStyles';
import { ExpandCollapseContainer } from 'styled/ExpandCollapseContainer';

export const PERSPECTIVE_WEEK = 'week';
export const PERSPECTIVE_MONTH = 'month';

export type ValuePiece = Date | null;
export type Value = ValuePiece | [ValuePiece, ValuePiece];

export const ReactCalendar = observer(
  (props: {
    selectedDates: string[];
    activeStartDate: string;
    perspective: string;
    disabledDates: string[];
    isLoading: boolean;
    showNeighboringMonth: boolean;
    allowRangeSelection: boolean;
    minRangeDays: number | null;
    maxRangeDays: number | null;
    onSelectedDateChanged: (firstDate: string, secondDate: string | null) => void;
    onVisibleDateRangeChanged: (
      newStartDate: string,
      newEndDate: string,
      newActiveStartDate: string
    ) => void;
    onPerspectiveChanged?: (newPerspective: string) => void;
    view?: 'schedule' | 'default';
  }) => {
    const {
      selectedDates,
      activeStartDate,
      perspective,
      disabledDates: disabledDatesProp,
      isLoading,
      showNeighboringMonth,
      allowRangeSelection,
      minRangeDays,
      maxRangeDays,
      onSelectedDateChanged,
      onVisibleDateRangeChanged,
      onPerspectiveChanged,
      view = 'default',
    } = props;
    const { t } = useTranslation(Namespaces.UI);

    const selectedDateMoment1 =
      selectedDates?.length > 0 ? moment(selectedDates[0]) : null;
    const selectedDateMoment2 =
      selectedDates?.length > 1 ? moment(selectedDates[1]) : null;
    const todayMoment = moment().startOf('day');

    const activeStartDateMoment = moment(activeStartDate);

    const [minDateMoment, setMinDateMoment] = useState<moment.Moment | null>(null);
    const [maxDateMoment, setMaxDateMoment] = useState<moment.Moment | null>(null);

    useEffect(() => {
      if (allowRangeSelection) {
        // Update min and max dates so that only possible dates can be selected
        if (selectedDateMoment1 && !selectedDateMoment2) {
          // Range has been partially selected
          let firstDisabledDateBeforeStart;
          for (let i = disabledDatesProp.length - 1; i >= 0; i--) {
            const disabledDateMoment = moment(disabledDatesProp[i]);
            if (disabledDateMoment.isBefore(selectedDateMoment1)) {
              firstDisabledDateBeforeStart = disabledDateMoment;
              break;
            }
          }

          let firstDisabledDateAfterStart;
          for (let i = 0; i < disabledDatesProp.length; i++) {
            const disabledDateMoment = moment(disabledDatesProp[i]);
            if (disabledDateMoment.isAfter(selectedDateMoment1)) {
              firstDisabledDateAfterStart = disabledDateMoment;
              break;
            }
          }

          // If max range is set, we need to check further
          if (maxRangeDays) {
            // Don't go past max date forward
            var maxRangedDayForward = moment(selectedDateMoment1).add(
              maxRangeDays - 1,
              'days'
            );
            if (
              !firstDisabledDateAfterStart ||
              firstDisabledDateAfterStart.isAfter(maxRangedDayForward)
            ) {
              firstDisabledDateAfterStart = maxRangedDayForward;
            }

            // Don't go past max date backwards
            var maxRangedDayBackward = moment(selectedDateMoment1).add(
              -maxRangeDays + 1,
              'days'
            );
            if (
              !firstDisabledDateBeforeStart ||
              firstDisabledDateBeforeStart.isBefore(maxRangedDayBackward)
            ) {
              firstDisabledDateBeforeStart = maxRangedDayBackward;
            }
          }

          if (
            firstDisabledDateBeforeStart &&
            (!minDateMoment ||
              minDateMoment.format('YYYY-MM-DD') !=
                firstDisabledDateBeforeStart.format('YYYY-MM-DD'))
          ) {
            setMinDateMoment(firstDisabledDateBeforeStart);
          }

          if (firstDisabledDateAfterStart) {
            if (
              !maxDateMoment ||
              maxDateMoment.format('YYYY-MM-DD') !=
                firstDisabledDateAfterStart.format('YYYY-MM-DD')
            ) {
              setMaxDateMoment(firstDisabledDateAfterStart);
            }
          }
        } else if (selectedDateMoment1 && selectedDateMoment2) {
          setMinDateMoment(null);
          setMaxDateMoment(null);
        }
      }
    }, [
      allowRangeSelection,
      selectedDateMoment1,
      selectedDateMoment2,
      disabledDatesProp,
      maxDateMoment,
      setMaxDateMoment,
    ]);

    const onBackwardButtonClicked = () => {
      let newActiveStartDate = moment(activeStartDateMoment);
      let newActiveEndDate = moment();
      if (perspective === PERSPECTIVE_WEEK) {
        newActiveStartDate = newActiveStartDate.add(-1, 'week').startOf('isoWeek');
        newActiveEndDate = moment(newActiveStartDate).endOf('isoWeek');
      } else if (perspective === PERSPECTIVE_MONTH) {
        newActiveStartDate = newActiveStartDate.add(-1, 'month').startOf('month');
        newActiveEndDate = moment(newActiveStartDate).endOf('month');
      }

      updateDates(newActiveStartDate, newActiveEndDate);
    };

    const onForwardButtonClicked = () => {
      let newActiveStartDate = moment(activeStartDateMoment);
      let newActiveEndDate = moment();
      if (perspective === PERSPECTIVE_WEEK) {
        newActiveStartDate = newActiveStartDate.add(1, 'week').startOf('isoWeek');
        newActiveEndDate = moment(newActiveStartDate).endOf('isoWeek');
      } else if (perspective === PERSPECTIVE_MONTH) {
        newActiveStartDate = newActiveStartDate.add(1, 'month').startOf('month');
        newActiveEndDate = moment(newActiveStartDate).endOf('month');
      }

      updateDates(newActiveStartDate, newActiveEndDate);
    };

    const onTodayButtonClicked = () => {
      let newActiveStartDate = moment(todayMoment);
      let newActiveEndDate = moment(todayMoment);
      if (perspective === PERSPECTIVE_WEEK) {
        newActiveStartDate = newActiveStartDate.startOf('isoWeek');
        newActiveEndDate = newActiveEndDate.endOf('isoWeek');
      } else if (perspective === PERSPECTIVE_MONTH) {
        newActiveStartDate = newActiveStartDate.startOf('month');
        newActiveEndDate = newActiveEndDate.endOf('month');
      }

      updateDates(newActiveStartDate, newActiveEndDate);

      if (!allowRangeSelection) {
        onSelectedDateChanged(todayMoment.format('YYYY-MM-DD'), null);
      }
    };

    const updatePerspective = (newPerspective: string) => {
      let newActiveStartDate = moment(activeStartDateMoment);
      let newActiveEndDate = moment();
      if (newPerspective === PERSPECTIVE_WEEK) {
        newActiveStartDate = newActiveStartDate.startOf('isoWeek');
        newActiveEndDate = moment(newActiveStartDate).endOf('isoWeek');
      } else if (newPerspective === PERSPECTIVE_MONTH) {
        newActiveStartDate = newActiveStartDate.startOf('month');
        newActiveEndDate = moment(newActiveStartDate).endOf('month');
      }

      if (newActiveStartDate.isBefore(todayMoment)) {
        // First day is before today, re calculate
        newActiveStartDate = moment(todayMoment);

        if (newPerspective === PERSPECTIVE_WEEK) {
          newActiveStartDate = newActiveStartDate.startOf('isoWeek');
          newActiveEndDate = moment(newActiveStartDate).endOf('isoWeek');
        } else if (newPerspective === PERSPECTIVE_MONTH) {
          newActiveStartDate = newActiveStartDate.startOf('month');
          newActiveEndDate = moment(newActiveStartDate).endOf('month');
        }
      }

      onPerspectiveChanged?.(newPerspective);
      updateDates(newActiveStartDate, newActiveEndDate);
    };

    const updateDates = (
      newActiveStartDate: moment.Moment,
      newActiveEndDate: moment.Moment
    ) => {
      let newRangeStartDate = moment(newActiveStartDate);
      let newRangeEndDate = moment(newActiveEndDate);

      if (showNeighboringMonth) {
        // Make sure to include "stray" days that is not in current month
        let newDates = includeNeighboringMonthDaysInRange(
          newRangeStartDate,
          newRangeEndDate
        );
        newRangeStartDate = newDates.startDate;
        newRangeEndDate = newDates.endDate;
      }

      onVisibleDateRangeChanged(
        newRangeStartDate.format('YYYY-MM-DD'),
        newRangeEndDate.format('YYYY-MM-DD'),
        newActiveStartDate.format('YYYY-MM-DD')
      );
    };

    let disabledDates = [...disabledDatesProp];
    if (minRangeDays && selectedDateMoment1 && !selectedDateMoment2) {
      // Partial range selected and min range settings, see if we need to disable dates in the range
      let startDateBackward = moment(selectedDateMoment1);
      const minSelectableDateBackward = moment(startDateBackward).add(
        -minRangeDays + 1,
        'days'
      );
      while (startDateBackward.add(-1, 'days').diff(minSelectableDateBackward) > 0) {
        disabledDates.push(startDateBackward.format('YYYY-MM-DD'));
      }

      let startDateForward = moment(selectedDateMoment1);
      const minSelectableDateForward = moment(startDateForward).add(
        minRangeDays - 1,
        'days'
      );
      while (startDateForward.add(1, 'days').diff(minSelectableDateForward) < 0) {
        disabledDates.push(startDateForward.format('YYYY-MM-DD'));
      }
    }

    let titleMonthDateMoment = moment(activeStartDateMoment);
    const firstDayOfLastWeekOfMonth = moment(titleMonthDateMoment)
      .endOf('month')
      .startOf('isoWeek');
    if (titleMonthDateMoment.isSameOrAfter(firstDayOfLastWeekOfMonth)) {
      // Visible week is between two months, pick appropriate month
      if (
        selectedDateMoment1 &&
        selectedDateMoment1.isBetween(
          titleMonthDateMoment,
          moment(firstDayOfLastWeekOfMonth).add(1, 'week')
        )
      ) {
        // Pick month from selected date
        titleMonthDateMoment = moment(selectedDateMoment1);
      }
    }

    return (
      <ContentContainer view={view}>
        <TopContainer view={view}>
          {view === 'default' && (
            <CalendarTitleContainer>
              <CalendarTitleMonth>
                {t(titleMonthDateMoment.format('MMMM').toLowerCase())}
              </CalendarTitleMonth>
              <div>{titleMonthDateMoment.format('YYYY')}</div>
            </CalendarTitleContainer>
          )}
          <Flex alignItems="center">
            {moment(activeStartDateMoment)
              .add(-1, 'week')
              .isSameOrAfter(moment(todayMoment).startOf('isoWeek')) && (
              <CustomIcon
                width="13px"
                height="20px"
                icon={ArrowLeft}
                onClick={(e) => {
                  e?.stopPropagation();
                  onBackwardButtonClicked();
                }}
                hover
                cursor
              />
            )}
            <TodayButton
              onClick={(e) => {
                e?.stopPropagation();
                onTodayButtonClicked();
              }}
            >
              {t('today')}
            </TodayButton>
            <CustomIcon
              width="13px"
              height="20px"
              icon={ArrowRight}
              onClick={(e) => {
                e?.stopPropagation();
                onForwardButtonClicked();
              }}
              hover
              cursor
            />
          </Flex>
        </TopContainer>
        <CalendarContainer>
          <Calendar
            className={view === 'schedule' ? 'react-calendar-schedule' : ''}
            onChange={(value: Value, event) => {
              event?.stopPropagation();
              if (!allowRangeSelection) {
                let changedToDate = value ? value.toString() : '';
                if (!changedToDate) return;

                changedToDate = moment(new Date(changedToDate)).format('YYYY-MM-DD');
                if (
                  selectedDateMoment1 &&
                  changedToDate === selectedDateMoment1.format('YYYY-MM-DD')
                ) {
                  return;
                }

                onSelectedDateChanged(changedToDate, null);
              } else {
                const valueArray = value as ValuePiece[];
                let changedToDate1 = (valueArray[0]
                  ? moment(new Date(valueArray[0].toString()))
                  : '') as moment.Moment;
                let changedToDate2 = (valueArray[1]
                  ? moment(new Date(valueArray[1].toString()))
                  : '') as moment.Moment;

                if (
                  minRangeDays &&
                  minRangeDays > 1 &&
                  changedToDate1 &&
                  changedToDate2 &&
                  changedToDate1.format('YYYY-MM-DD') ===
                    changedToDate2.format('YYYY-MM-DD')
                ) {
                  // Same day selected and minRange doesn't allow it
                  return;
                }

                onSelectedDateChanged(
                  changedToDate1.format('YYYY-MM-DD'),
                  changedToDate2 ? changedToDate2.format('YYYY-MM-DD') : null
                );
              }
            }}
            value={
              !allowRangeSelection
                ? selectedDateMoment1
                  ? selectedDateMoment1.toDate()
                  : null
                : selectedDateMoment1 && selectedDateMoment2
                ? [selectedDateMoment1.toDate(), selectedDateMoment2.toDate()]
                : null
            }
            activeStartDate={activeStartDateMoment.toDate()}
            formatShortWeekday={(locale, date) => {
              return t(moment(date).format('dddd').toLowerCase())
                .substring(0, 3)
                .toUpperCase();
            }}
            showNavigation={false}
            showNeighboringMonth={showNeighboringMonth}
            selectRange={allowRangeSelection}
            allowPartialRange={allowRangeSelection}
            minDate={minDateMoment ? moment(minDateMoment).toDate() : undefined}
            maxDate={maxDateMoment ? moment(maxDateMoment).toDate() : undefined}
            tileDisabled={({ date }) => {
              const momentDate = moment(date);
              return (
                momentDate.isBefore(todayMoment) ||
                disabledDates.includes(moment(date).format('YYYY-MM-DD'))
              );
            }}
            tileClassName={({ date }) => {
              const tileDateMoment = moment(date);
              if (perspective === PERSPECTIVE_WEEK) {
                const isChosenWeek =
                  tileDateMoment.isoWeek() === activeStartDateMoment.isoWeek();
                if (!isChosenWeek) {
                  return 'hidden';
                }
              }

              let extraClassName = 'visible';

              if (isLoading) {
                extraClassName += ' loading';

                if (!allowRangeSelection) {
                  extraClassName += ' single-selection';
                }

                return extraClassName;
              }

              const tileDateString = tileDateMoment.format('YYYY-MM-DD');

              if (
                tileDateMoment.isBefore(todayMoment) ||
                disabledDates.includes(tileDateString)
              ) {
                extraClassName += ' disabled';
              }

              if (!allowRangeSelection) {
                // Single selection
                extraClassName += ' single-selection';

                if (
                  selectedDateMoment1 &&
                  tileDateString === selectedDateMoment1.format('YYYY-MM-DD')
                ) {
                  extraClassName += ' circle selected';
                }
              } else {
                // Range selection
                if (
                  selectedDateMoment1 &&
                  tileDateString === selectedDateMoment1.format('YYYY-MM-DD')
                ) {
                  extraClassName += ' circle selected';
                }

                if (
                  selectedDateMoment2 &&
                  tileDateString === selectedDateMoment2.format('YYYY-MM-DD')
                ) {
                  extraClassName += ' circle selected';
                }

                if (selectedDateMoment1 && !selectedDateMoment2) {
                  // Partial range selected, disable not possible dates
                  if (minDateMoment && tileDateMoment.isBefore(minDateMoment)) {
                    extraClassName += ' disabled';
                  } else if (maxDateMoment && tileDateMoment.isAfter(maxDateMoment)) {
                    extraClassName += ' disabled';
                  } else if (
                    maxRangeDays &&
                    (tileDateMoment.isAfter(
                      moment(selectedDateMoment1).add(maxRangeDays - 1, 'days')
                    ) ||
                      tileDateMoment.isBefore(
                        moment(selectedDateMoment1).add(-maxRangeDays + 1, 'days')
                      ))
                  ) {
                    extraClassName += ' disabled';
                  }
                }
              }

              if (tileDateString === todayMoment.format('YYYY-MM-DD')) {
                extraClassName += ' circle today';
              }

              return extraClassName;
            }}
          />
        </CalendarContainer>

        {allowRangeSelection && (
          <SelectedDatesContainer>
            <SelectedDatesTitle>{t('calendarSelectedPeriod')}</SelectedDatesTitle>
            {!selectedDateMoment1 && (
              <MissingDateContainer isStartDate={true}>
                {t('calendarSelectStartDate').toUpperCase()}
              </MissingDateContainer>
            )}

            {selectedDateMoment1 && (
              <SelectedPeriodLabel>
                {selectedDateMoment1 && !selectedDateMoment2
                  ? `${selectedDateMoment1.format('YYYY-MM-DD')} - `
                  : selectedDateMoment2
                  ? `${selectedDateMoment1.format(
                      'YYYY-MM-DD'
                    )} - ${selectedDateMoment2.format('YYYY-MM-DD')}`
                  : ''}
              </SelectedPeriodLabel>
            )}

            {selectedDateMoment1 && !selectedDateMoment2 && (
              <MissingDateContainer isStartDate={false}>
                {t('calendarSelectEndDate').toUpperCase()}
              </MissingDateContainer>
            )}
          </SelectedDatesContainer>
        )}

        {view === 'default' && (
          <ExpandCollapseContainer
            onClick={() =>
              updatePerspective(
                perspective === PERSPECTIVE_MONTH ? PERSPECTIVE_WEEK : PERSPECTIVE_MONTH
              )
            }
            style={{
              bottom: '-12.5px',
            }}
          >
            {perspective === PERSPECTIVE_MONTH ? (
              <CustomIcon
                bottom="1px"
                position="relative"
                width="13px"
                height="13px"
                icon={ArrowUp}
                hover
                cursor
              />
            ) : (
              <CustomIcon
                top="1px"
                position="relative"
                width="13px"
                height="13px"
                icon={ArrowDown}
                hover
                cursor
              />
            )}
          </ExpandCollapseContainer>
        )}
      </ContentContainer>
    );
  }
);

function includeNeighboringMonthDaysInRange(
  startDate: moment.Moment,
  endDate: moment.Moment
) {
  startDate = moment(startDate).startOf('isoWeek');
  endDate = moment(endDate).endOf('isoWeek');
  return { startDate, endDate };
}
