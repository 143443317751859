import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

export const Modal = styled.div<{ withBackgroundDimm: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ withBackgroundDimm }) =>
    withBackgroundDimm ? 'rgba(0, 0, 0, 0.6)' : 'transparent'};
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

export const ModalBody = styled.div`
  position: relative;
  width: 545px;
  padding: 65px 65px 65px 65px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;

  @media ${devices.mobile} {
    width: 90%;
    height: 90vh;
    overflow: scroll;
    padding: 25px;
  }
`;

export const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
`;

export const ModalSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-top: 8px;
`;

export const InputWrap = styled.div`
  position: relative;
  margin-top: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  user-select: none;

  @media ${devices.mobile} {
    width: 95%;
  }
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

export const ButtonSave = styled(PrimaryButton)`
  width: 300px;
  height: 56px;
  font-size: 17px;
`;

export const ButtonCancel = styled(ButtonSave)`
  margin-top: 20px;
`;
