import {
  Dispatch,
  FC,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { $WaitingListModal, ModalContent } from './modalStyles';
import { PrimaryButton } from 'styled/PrimaryButton';
import { UserProxy, UserStore } from 'store/UserStore';
import { toJS } from 'mobx';
import { Loader } from 'components/Loader/Loader';
import cross from 'assets/icons/closeCircle.svg';
import checkmark from 'assets/icons/checkmark.svg';
import { AddUserProxyModal } from 'pages/Checkout/AddUserProxyModal';
import { Course } from 'store/ServiceStore';
import { MANDATORY, OPTIONAL } from 'constants/checkoutConstants';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';

interface IProps {
  onClose: () => void;
  isOpen: boolean;
  course?: Course;
  onSubmit: (e: number[]) => Promise<void>;
  isLoading: boolean;
}

export const WaitingListModal: FC<IProps> = ({
  onClose,
  isOpen,
  course,
  onSubmit,
  isLoading,
}) => {
  const [step, setStep] = useState(0);
  const [isCreatingUserProxy, setIsCreatingUserProxy] = useState(false);
  const [selectedUserProxies, setSelectedUserProxies] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const { userProxies, getUserProxies } = UserStore;
  const users = toJS(userProxies);
  const { t } = useTranslation(Namespaces.UI);

  const nextStep = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setStep(step + 1);
    },
    [step]
  );

  const handleClose = (e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    setStep(course?.proxyUsersMode === OPTIONAL ? 0 : 1);
    onClose();
    setSelectedUserProxies([]);
  };

  const handleSubmit = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (
      (course?.proxyUsersMode === MANDATORY && selectedUserProxies.length === 0) ||
      isLoading
    )
      return;
    onSubmit(selectedUserProxies).then(() => {
      handleClose();
    });
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      getUserProxies().then(() => {
        setLoading(false);
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (course?.proxyUsersMode === OPTIONAL) {
      setStep(0);
    } else if (course?.proxyUsersMode === MANDATORY) {
      setStep(1);
    }
  }, [course?.proxyUsersMode]);

  return isOpen ? (
    <$WaitingListModal
      open={isOpen}
      onCancel={handleClose}
      centered
      footer={null}
      bodyStyle={{ padding: '0' }}
      closeIcon={<></>}
    >
      {isCreatingUserProxy ? (
        <AddUserProxyModal
          onUserProxyAdded={() => setIsCreatingUserProxy(false)}
          onCloseClicked={() => setIsCreatingUserProxy(false)}
          withBackgroundDimm={false}
        />
      ) : (
        <ModalContent>
          {step === 0 ? (
            <>
              <p className="modal-title">{t('waitingListModalTitle')}</p>
              <div className="modal-buttons">
                <PrimaryButton onClick={handleSubmit} disabled={isLoading}>
                  {isLoading ? (
                    <Loader small isWhite />
                  ) : (
                    t('waitingListModalAddYourself')
                  )}
                </PrimaryButton>
                <PrimaryButton onClick={nextStep}>
                  {t('waitingListModalAddSomeoneElse')}
                </PrimaryButton>
              </div>
            </>
          ) : step === 1 ? (
            <>
              <div className="title-container">
                <p className="modal-title">{t('waitingListModalAddNames')}</p>
                <p className="modal-helper">{t('waitingListModalChooseNames')}</p>
              </div>
              <div className="users-container">
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {users.map((user) => (
                      <UserProxyRow
                        key={user.id}
                        user={user}
                        selectedUserProxies={selectedUserProxies}
                        setSelectedUserProxies={setSelectedUserProxies}
                      />
                    ))}
                  </>
                )}
                <PrimaryButton
                  className="new-person-button"
                  secondary
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsCreatingUserProxy(true);
                  }}
                >
                  {t('waitingListModalAddPerson')}
                </PrimaryButton>
              </div>
              <div className="save-button-container">
                <PrimaryButton
                  className="save-button"
                  onClick={handleSubmit}
                  disabled={
                    (course?.proxyUsersMode === MANDATORY &&
                      selectedUserProxies.length === 0) ||
                    isLoading
                  }
                >
                  {isLoading ? <Loader small isWhite /> : t('save')}
                </PrimaryButton>
              </div>
            </>
          ) : null}
        </ModalContent>
      )}
    </$WaitingListModal>
  ) : null;
};

const UserProxyRow: FC<{
  user: UserProxy;
  selectedUserProxies: number[];
  setSelectedUserProxies: Dispatch<SetStateAction<number[]>>;
}> = ({ user, selectedUserProxies, setSelectedUserProxies }) => {
  const { t } = useTranslation(Namespaces.UI);

  const addUser = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setSelectedUserProxies([...selectedUserProxies, user.id]);
  };
  const removeUser = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setSelectedUserProxies(selectedUserProxies.filter((id) => id !== user.id));
  };

  return (
    <div>
      <div className="user-info">
        <p>
          {user.firstName} {user.surname}
        </p>
      </div>
      {!selectedUserProxies.includes(user.id) ? (
        <>
          <PrimaryButton className="add-button" onClick={addUser}>
            {t('add')}
          </PrimaryButton>
        </>
      ) : (
        <img
          src={checkmark}
          alt="checkmark"
          width={34}
          height={34}
          color="white"
          style={{ marginRight: '10px' }}
        />
      )}
      {selectedUserProxies.includes(user.id) && (
        <div className="remove-button" onClick={removeUser}>
          <img src={cross} alt="cross" width={24} height={24} color="white" />
        </div>
      )}
    </div>
  );
};
