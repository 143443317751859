import { devices } from 'constants/mediaConstants';
import styled, { DefaultTheme } from 'styled-components';
import Layout from 'styled/Layout';
import { PrimaryButton } from 'styled/PrimaryButton';
import PriceIcon from 'assets/icons/price.svg';
import infoIcon from 'assets/icons/infoIcon.svg';
import CloseIcon from 'assets/icons/close.svg';

type MessageBoxProps = {
  theme: DefaultTheme;
  isRed?: boolean;
};

export const Card = styled(Layout)`
  position: relative;
  padding: 40px 40px 40px;
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: ${(props: { collapsed: boolean }) => (props.collapsed ? 'auto' : 'pointer')};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;
  @media ${devices.mobile} {
    padding: 30px 30px 30px;
  }
`;

export const CardHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 22px;
  }
`;

export const CardDescription = styled.div`
  margin: 14px 0 24px;
  padding-right: 40px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.middleDark};
  white-space: pre-wrap;
  @media ${devices.mobile} {
    font-size: 14px;
    padding-right: 20px;
  } ;
`;

export const CardButton = styled(PrimaryButton)`
  max-height: 56px;
  width: 214px;
  padding: 17px 50px;
  margin: 25px auto 0;
  font-size: 14px;

  @media ${devices.mobile} {
    max-height: 38px;
    width: 170px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;

export const WaitListButton = styled(PrimaryButton)`
  max-height: 56px;
  width: 214px;
  padding: 17px 50px;
  margin: 25px auto 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.whiteRed};
  color: ${({ theme }) => theme.colors.white};

  @media ${devices.mobile} {
    max-height: 38px;
    width: 170px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;

export const CardPriceIcon = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${PriceIcon});
`;

export const Price = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'normal' : 'bold'};
  font-size: 18px;
  text-decoration-line: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'line-through' : 'none'};
  flex-grow: 1;
  @media${devices.mobile} {
    font-size: 14px;
  }
`;

export const SpotsLeftLabel = styled.div`
  color: ${(props) => props.color};
  font-size: 15px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  @media${devices.mobile} {
    font-size: 12px;
  }
`;

export const TimeZoneText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  margin-top: 12px;
`;

export const CardValidBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
`;

export const BookingDurationBlock = styled.div`
  width: 100%;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px 10px 0 0;
  @media ${devices.mobile} {
    padding: 8px;
  } ;
`;
export const BookingStartEndBlock = styled.div`
  display: flex;
  width: 100%;
`;

export const BookingStartBlock = styled.div`
  width: 100%;
  margin-top: -1px;
  margin-right: -1px;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 0 0 0 10px;
  @media ${devices.mobile} {
    padding: 8px;
  } ;
`;
export const BookingEndBlock = styled.div`
  width: 100%;
  margin-top: -1px;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 0 0 10px 0;
  @media ${devices.mobile} {
    padding: 8px;
  } ;
`;

export const GroupLeadersBlock = styled.div`
  margin-top: 25px;
`;

export const GroupLeadersBlockName = styled.div`
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

export const GroupLeaderName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

export const OccasionsBlock = styled.div`
  margin-top: 25px;
`;

export const CardDateTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  @media ${devices.mobile} {
    font-size: 11px;
  } ;
`;
export const GroupLeaderBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px;
  margin-top: 10px;
  @media ${devices.mobile} {
    padding: 8px;
  } ;
`;

export const CardDateValues = styled.div`
  color: ${(props) => props.color};
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 700;
  @media (max-width: 500px) {
    font-size: 16px;
  }
  @media ${devices.mobile} {
    font-size: 12px;
  } ;
`;

export const Hour = styled.div`
  margin-left: 10px;
`;

export const InfoIcon = styled.div`
  height: 22px;
  width: 22px;
  background-size: cover;
  background-image: url(${infoIcon});
  cursor: pointer;
  margin: 0 30px 0 auto;
  @media ${devices.mobile} {
    height: 18px;
    width: 18px;
    margin: 0 5px 0 auto;
  } ;
`;

export const MessageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px -40px -40px -40px;
  height: 100px;
  background-color: ${(props: MessageBoxProps) =>
    props.isRed ? props.theme.colors.whiteRed : props.theme.colors.darkGray};
  border-radius: 0 0 30px 30px;
  color: ${(props: MessageBoxProps) => props.theme.colors.white};
  font-family: ${(props: MessageBoxProps) => props.theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  opacity: ${(props: MessageBoxProps) => (props.isRed ? 0.5 : 1)};
  @media ${devices.mobile} {
    margin: 20px -30px -30px -30px;
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

export const ModalBody = styled.div`
  position: relative;
  width: 545px;
  padding: 65px 67px 40px 65px;
  border-radius: 30px;
  background: ${({ theme }) => theme.colors.white};

  @media ${devices.mobile} {
    width: 90%;
    padding: 25px;
  }
`;

export const IconClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  height: 20px;
  width: 20px;
  background-size: contain;
  background-image: url(${CloseIcon});
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media ${devices.mobile} {
    right: 30px;
    top: 30px;
    height: 25px;
    width: 25px;
  }
`;
export const ModalCardName = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 22px;
  }
`;

export const ModalCardDescription = styled.div`
  margin: 10px 0 27px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 18px;
  line-height: 21px;

  @media ${devices.mobile} {
    font-size: 16px;
    line-height: 20px;
  }
`;
