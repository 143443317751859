import { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { Dispatch, SetStateAction } from 'react';
import { CourseDateType, ProfileOccasion } from 'store/CourseStore';
import { LoginStore } from 'store/LoginStore';
import { Namespaces } from 'languages';
import {
  CustomDoneIcon,
  CustomIcon,
  ScheduleWaitListButton,
  SelectButton,
} from './occasionsStyles';
import { Loader } from 'components/Loader/Loader';
import DoneIcon from 'assets/icons/DoneIcon.svg';
import ClosedIcon from 'assets/icons/closed-icon.svg';
import WaitingIcon from 'assets/icons/waiting-icon.svg';
import { Course } from 'store/ServiceStore';
import { DONT_ASK } from 'constants/checkoutConstants';
import moment from 'moment';

interface IProps {
  element: CourseDateType | ProfileOccasion;
  loading: number[];
  setLoading: Dispatch<SetStateAction<number[]>>;
  addToCourseOccasionWaitingList: (
    { id, userProxiesIds }: { id: number | string; userProxiesIds?: number[] },
    setLoading: Dispatch<SetStateAction<number[]>>
  ) => Promise<void>;
  isBookPerOccasion: boolean;
  isCourseBookable: boolean;
  itemsToSelect: number[];
  selectedButtons: number[];
  setSelectedButtons: Dispatch<SetStateAction<number[]>>;
  allEntries: boolean;
  setAllEntries?: (value: boolean) => void;
  setModal: Dispatch<SetStateAction<number | null>>;
  proxyUsersMode: Course['proxyUsersMode'];
  isSchedule: boolean;
}

export const ScheduleButton: FC<IProps> = ({
  element,
  loading,
  setLoading,
  addToCourseOccasionWaitingList,
  isBookPerOccasion,
  isCourseBookable,
  itemsToSelect,
  selectedButtons,
  setSelectedButtons,
  allEntries,
  setAllEntries,
  setModal,
  proxyUsersMode,
  isSchedule,
}) => {
  const { user } = LoginStore;
  const userData = toJS(user);
  const { t } = useTranslation(Namespaces.UI);
  const history = useHistory();
  const handleWaitingListClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const localProxyUsersMode = isSchedule
      ? (element as ProfileOccasion).service.proxyUsersMode
      : proxyUsersMode;
    if (!userData.id) {
      localStorage.prevPath = history.location.pathname;
      localStorage.elementId = element.id;
      if (isSchedule)
        localStorage.elementDate = moment(element.startTime).format('YYYY-MM-DD');

      history.push('/auth');
      return;
    }
    if (!loading.length) {
      if (localProxyUsersMode === DONT_ASK) {
        setLoading([element.id]);
        addToCourseOccasionWaitingList({ id: element.id }, setLoading);
      } else {
        setModal(element?.id);
      }
    }
  };

  if (!isCourseBookable) return <></>;

  if (isBookPerOccasion) {
    if (element.isCallerAttending) {
      return <CustomIcon icon={DoneIcon} />;
    }

    if (
      element.isFull &&
      (element.isCallerOnWaitingList ||
        (element?.proxyUserIdsOnWaitingList || [])?.length > 0)
    ) {
      return <CustomIcon icon={WaitingIcon} />;
    }

    if (element.isFull) {
      if (element.isWaitingListEnabled) {
        return (
          <ScheduleWaitListButton onClick={handleWaitingListClick}>
            {loading.includes(element.id) ? <Loader small red /> : t('waitingList')}
          </ScheduleWaitListButton>
        );
      }
      return <CustomIcon icon={ClosedIcon} />;
    }

    if (itemsToSelect.includes(element.id) && !selectedButtons.includes(element.id)) {
      return (
        <SelectButton
          onClick={(e) => {
            e.stopPropagation();
            setSelectedButtons([...selectedButtons, element.id]);
          }}
        >
          {t('select')}
        </SelectButton>
      );
    }

    if (selectedButtons.includes(element.id)) {
      return (
        <CustomDoneIcon
          icon={DoneIcon}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedButtons(selectedButtons.filter((el) => el !== element.id));
            if (allEntries) {
              setAllEntries?.(false);
            }
          }}
        />
      );
    }
  }

  return null;
};
