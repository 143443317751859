import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getTime, getWeekdayName, getYMD } from 'helpers/DateTimeUtils';
import { Namespaces } from 'languages';

import { devices } from 'constants/mediaConstants';

import Close from 'assets/icons/closeCircle.svg';
import WaitingIcon from 'assets/icons/waiting-icon.svg';

import { WaitingItemType, Entries } from 'store/WaitingListStore';

import Clock from 'assets/icons/time.svg';
import { Flex } from 'styled/Flex';

const TimeIcon = styled.div`
  height: 12.5px;
  width: 12.5px;
  margin: 0 7px 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${Clock});
`;

type IconProps = {
  icon: string;
};

const CustomIcon = styled.div`
  width: 12px;
  height: 15px;
  margin-right: 11px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props: IconProps) => props.icon});
`;
const CardValidBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;
const WaitingItemBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px 10px 13px 19px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px;
  @media ${devices.mobile} {
    flex-direction: column;
    padding: 20px;
  } ;
`;

const CardDateTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 7px;
`;

const CloseIcon = styled.div`
  position: absolute;
  right: -15px;
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  background-size: contain;
  background-image: url(${Close});
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const ScheduleItemDate = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  margin-right: 25px;
`;

const WaitingOnDate = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin-right: 14px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.whiteRed};
  @media ${devices.mobile} {
  }
`;

const WaitingDataBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media ${devices.mobile} {
    width: 100%;
    margin-bottom: 13px;
  }
`;

const WeekdayName = styled.div`
  margin: 2px 0 6px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.middleLightGray};
`;
const ScheduleDurationTime = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
`;

type PropsType = {
  waitingItem: WaitingItemType;
  setModalType: (type: string) => void;
  setActiveWaitingItem: (waitingItem: WaitingItemType) => void;
};

export const WaitingItem = observer((props: PropsType) => {
  const { waitingItem, setModalType, setActiveWaitingItem } = props;
  const { entries, id, createdAt, userProxy } = waitingItem;
  const { t } = useTranslation(Namespaces.UI);

  return (
    <div key={id}>
      {entries.map((element: Entries) => {
        return (
          <CardValidBlock key={element.id}>
            <WaitingItemBlock>
              <WaitingDataBlock>
                {userProxy && (
                  <span style={{ fontWeight: 500 }}>
                    {userProxy.firstName} {userProxy.surname}
                  </span>
                )}
                <ScheduleItemDate>{getYMD(element.startTime, true)}</ScheduleItemDate>
                <WeekdayName>{t(getWeekdayName(element.startTime, true))}</WeekdayName>
                <Flex alignItems="center">
                  <TimeIcon />
                  <ScheduleDurationTime>
                    {`${getTime(element.startTime, true)} - ${getTime(
                      element.endTime,
                      true
                    )}`}
                  </ScheduleDurationTime>
                </Flex>
              </WaitingDataBlock>
              <Flex flexDirection="column" justifyContent="center">
                <CardDateTitle>{t('onWaitingList').toUpperCase()}</CardDateTitle>
                <Flex alignItems="center">
                  <CustomIcon icon={WaitingIcon} />
                  <WaitingOnDate>{getYMD(createdAt)}</WaitingOnDate>
                  <WaitingOnDate>{getTime(createdAt)}</WaitingOnDate>
                </Flex>
              </Flex>
              <CloseIcon
                onClick={() => {
                  setActiveWaitingItem(waitingItem);
                  setModalType('cancel');
                }}
              />
            </WaitingItemBlock>
          </CardValidBlock>
        );
      })}
    </div>
  );
});
