import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

export const CardHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
`;

export const CardPriceData = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  margin-right: 40px;
  @media ${devices.mobile} {
    font-size: 15px;
    margin-right: 25px;
  }
`;

export const CardTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  margin-top: 3px;
`;

export const ServiceDuration = styled.div`
  margin-left: 5px;
  line-height: 20px;
`;

export const SlotsContainer = styled.div`
  margin-top: 24px;
  padding: 0 25px;
  .add-to-cart-counter-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }
`;

export const TimeZoneText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 4px;
`;

export const SlotsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
  > div {
    min-width: 135px;
  }
`;

export const CardButton = styled(PrimaryButton)`
  max-height: 56px;
  width: 214px;
  padding: 17px 50px;
  margin: 30px auto 0;
  font-size: 14px;

  @media ${devices.mobile} {
    max-height: 38px;
    width: 170px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;

export const ActiveRowContainer = styled.div<{ borderLess?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ borderLess }) => (borderLess ? '25px' : '20px')};
  background: white;
  border-radius: 12px;
  border: ${({ borderLess, theme }) =>
    borderLess ? 'none' : `1px solid ${theme.colors.middleGray}`};
  gap: 5px;
`;

export const RowSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-transform: uppercase;
`;

export const Value = styled.span`
  font-size: 16px;
  font-weight: 500;
  width: 120px;
  text-align: left;
  display: flex;
  justify-content: start;
  align-items: center;
  max-height: 25px;
  word-break: keep-all;
  overflow: hidden;
  white-space: nowrap;
`;

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 120px;
`;

export const QuantityValue = styled.span`
  font-size: 16px;
  font-weight: 500;
  min-width: 20px;
  text-align: center;
`;
