import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { callApi } from 'helpers';
import { AddCheckoutItemData } from 'store/CheckoutStore';

type ServiceType = {
  currency: string;
  description: string;
  durationMinutes: number;
  id: number;
  name: string;
  price: number;
  type: string;
};

export type FixedSlot = {
  startTime: string;
  endTime: string;
  checkoutItemData: AddCheckoutItemData;
  availableCount?: number;
};

export type FlexibleSlot = {
  startTime: string;
  endTime: string;
};

type AppointmentsData = {
  type: string;
  page: number;
  totalPages: number;
};

export type AppointmentSlotFixed = {
  avatarUrl: string;
  description: string;
  price: number;
  fromPrice?: number;
  currency: string;
  durationMinutes: number;
  name: string;
  type: string;
  slots: FixedSlot[];
  coverImageUrl?: string;
};

export interface AppointmentSlotFixedData extends AppointmentsData {
  appointments: AppointmentSlotFixed[];
}

export type AppointmentSlotFlexible = {
  type: string;
  rangeStartTime: string;
  rangeEndTime: string;
  name: string;
  description: string;
  avatarUrl: string;
  price: number;
  fromPrice?: number;
  pricePerUnit: string;
  currency: string;
  slots: FlexibleSlot[];
  rangeIncrementMinutes: number;
  maxBookableMinutes?: number;
  minBookableMinutes?: number;
  coverImageUrl?: string;
  groupSelectMode: string;
  checkoutItemData: {
    serviceId: number;
    resourceId: number;
    resourceGroupId: number;
  };
};

export type AppointmentUntilCancellation = {
  avatarUrl: string;
  type: string;
  startTime: string;
  endTime: string;
  name: string;
  description: string;
  price: number;
  pricePerUnit: string;
  currency: string;
  address: string;
  coverImageUrl?: string;
  availableCount?: number;
  groupSelectMode?: string;
  checkoutItemData: { startTime: string; resourceGroupId: number; serviceId: number };
};

export interface AppointmentUntilCancellationData extends AppointmentsData {
  appointments: AppointmentUntilCancellation[];
}

export type AppointmentDayItem = {
  avatarUrl: string;
  type: string;
  startTime: string;
  endTime: string;
  name: string;
  description: string;
  price: number;
  pricePerUnit: string;
  currency: string;
  address: string;
  coverImageUrl?: string;
  checkoutItemData: {
    startTime: string;
    endTime: string;
    resourceId: number;
    resourceGroupId: number;
    serviceId: number;
  };
};

export type Voucher = {
  balance: number;
  category?: { id: number; name: string };
  currency: string;
  description: string;
  id: number;
  name: string;
  price: number;
  type: string;
  validTo: string;
  validForAllServices: boolean;
  validServices: { id: number; name: string }[];
};

export type Membership = {
  category?: { id: number; name: string };
  currency: string;
  description: string;
  id: number;
  name: string;
  price: number;
  type: string;
  validTo: string;
  validForAllServices: boolean;
  validServices: { id: number; name: string }[];
};

export type ResourceType = {
  avatarUrl: string;
  description: string;
  email: string;
  firstName: string;
  id: number;
  name: string;
  surname: string;
  type: string;
};

export type Course = {
  type: string;
  currency: string;
  description: string;
  startTime: string;
  endTime: string;
  firstBookableAt: string;
  lastBookableAt: string;
  id: number;
  name: string;
  price: number;
  isWaitingListEnabled?: boolean;
  isBookPerOccasion: boolean;
  isCallerAttending?: boolean;
  isCallerOnWaitingList?: boolean;
  isFull?: boolean;
  proxyUsersMode?: 'dontAsk' | 'optional' | 'mandatory';
  proxyUserIdsOnWaitingList?: number[];
  resources: ResourceType[];
  spotsLeft?: number;
};

// Model the application state.
class Service {
  service: ServiceType | Voucher | Course | undefined = undefined;

  loading = false;

  appointmentDetails:
    | AppointmentUntilCancellationData
    | AppointmentSlotFixedData
    | undefined = undefined;

  availableFlexibleSlots:
    | {
        availableCount: number;
        endTime: string;
        startTime: string;
      }
    | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  fetchService = async (serviceId: string) => {
    try {
      const res = await callApi(`v1/services/${serviceId}`);
      const data = await res.json();
      this.setService(data);
    } catch (e) {
      console.error(e);
    }
  };

  setService = (service: ServiceType | Voucher | Course) => {
    this.service = service;
  };

  clearService = () => {
    this.service = undefined;
  };

  getAvailableAppointments = async (
    startDate: string,
    endDate?: string | null,
    pageNumber?: number
  ) => {
    if (!pageNumber) {
      this.setAppointmentDetails(undefined);
    }
    try {
      runInAction(() => {
        this.loading = true;
      });
      let serviceId: string | number = '';
      if (this.service) {
        serviceId = this.service.id;
      }

      if (!endDate) {
        endDate = startDate;
      }
      const res = await callApi(
        `v1/appointments/${serviceId}/search?startTime=${startDate}T00:00:00&endTime=${endDate}T23:59:59&perPage=5${
          pageNumber ? `&page=${pageNumber}` : ''
        }`
      );
      const { data, page, totalPages } = await res.json();
      if (data) {
        const type = data[0]?.type;
        const oldData = this.appointmentDetails
          ? toJS(this.appointmentDetails.appointments)
          : [];
        const newData = pageNumber ? [...oldData, ...data] : data;

        this.setAppointmentDetails({ appointments: newData, page, totalPages, type });
      }
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  checkFlexibleSlots = async (
    serviceId: number,
    selectedStartTime: string,
    selectedEndTime: string,
    resourceGroupId: number
  ) => {
    const res = await callApi(
      `v1/appointments/${serviceId}/resource-availability?startTime=${encodeURIComponent(
        selectedStartTime
      )}&endTime=${encodeURIComponent(
        selectedEndTime
      )}&resourceGroupId=${resourceGroupId}`
    );
    const data = await res.json();
    this.availableFlexibleSlots = data;
  };

  setAppointmentDetails = (
    data: AppointmentUntilCancellationData | AppointmentSlotFixedData | undefined
  ) => {
    this.appointmentDetails = data;
  };

  clearAppointmentDetails = () => {
    this.appointmentDetails = undefined;
  };
}

export const ServiceStore = new Service();
