import { FC } from 'react';
import './styles.scss';

interface IProps {
  isWhite?: boolean;
  small?: boolean;
  red?: boolean;
}

export const Loader: FC<IProps> = ({ isWhite, small, red }) => {
  return (
    <div
      className={`loader ${isWhite ? 'white' : ''} ${small ? 'small' : ''} ${
        red ? 'red' : ''
      }`}
      data-testid="loader"
    >
      <div className="ball one">
        <div className="inner" />
      </div>
      <div className="ball two">
        <div className="inner" />
      </div>
      <div className="ball three">
        <div className="inner" />
      </div>
      <div className="ball four">
        <div className="inner" />
      </div>
      <div className="ball five">
        <div className="inner" />
      </div>
      <div className="ball six">
        <div className="inner" />
      </div>
      <div className="ball center">
        <div className="inner" />
      </div>
    </div>
  );
};
