import { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Switch, Route, useLocation } from 'react-router-dom';

import { ServiceList } from 'pages/ServiceList/ServiceList';
import { Confirmation } from 'pages/Checkout/Confirmation';
import { InvoicePayment } from 'pages/Payment/Invoice/InvoicePayment';
import { CardPayment } from 'pages/Payment/CardPayment/CardPayment';
import { Auth } from 'pages/Auth/Auth';
import { ServiceDetails } from 'pages/ServiceDetails/ServiceDetails';
import { BookingPage } from 'pages/BookingList';
import { AppleRedirect } from 'pages/Auth/AppleRedirect';
import { ForgotPassword } from 'pages/Auth/ForgotPassword';
import { ResetPassword } from 'pages/Auth/ResetPassword';
import { Checkout } from 'pages/Checkout/Checkout';
import { Account } from 'pages/Account';
import { CheckoutFooter } from 'pages/Checkout/CheckoutFooter';

import { urls } from 'helpers';
import { getPageViewEvent, postPageViewEventMessage } from 'helpers/EventUtils';
import { Header } from 'containers/Header/Header';
import { theme } from 'theme';

import 'languages/i18n';
import './assets/fonts/SFPro.scss';
import { PaymentLinkPage } from 'pages/Payment/PaymentLinkPage/PaymentLinkPage';
import { MembershipPage } from 'pages/DiscountList/MembershipList';
import { VouchersPage } from 'pages/DiscountList/VoucherList';
import { WaitingListPage } from 'pages/WaitingList/WaitingList';
import { PaymentsListPage } from 'pages/PaymentsList/PaymentsList';
import { SwishPayment } from 'pages/Payment/Swish/SwishPayment';
import { SubscriptionsListPage } from 'pages/SubscriptionsList/SubscriptionsList';
import { SubscriptionAddCardPage } from 'pages/SubscriptionsList/SubscriptionAddCardPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'antd/dist/antd.min.css';

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 720px;
  margin: 0 auto;
  padding-bottom: 100px;
`;

export const App = () => {
  const location = useLocation();
  const googleId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

  useEffect(() => {
    const pageViewEvent = getPageViewEvent(location.pathname);
    if (pageViewEvent) {
      postPageViewEventMessage(pageViewEvent);
    }
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={googleId}>
        <Content>
          <Header />
          <Switch>
            <Route path={urls.services(':profileId')} component={ServiceList} exact />
            <Route
              path={urls.service(':profileId', ':serviceId')}
              component={ServiceDetails}
              exact
            />
            <Route path={urls.auth} component={Auth} exact />
            <Route path={urls.forgotPassword} component={ForgotPassword} exact />
            <Route path={urls.resetPassword} component={ResetPassword} />
            <Route path={urls.appleRedirect} component={AppleRedirect} />
            <Route path={urls.bookings} component={BookingPage} />
            <Route path={urls.waitingList} component={WaitingListPage} />
            <Route path={urls.payments} component={PaymentsListPage} />
            <Route path={urls.subscriptions} component={SubscriptionsListPage} exact />
            <Route
              path={urls.subscriptionChangeCard(':id')}
              component={SubscriptionAddCardPage}
              exact
            />
            <Route path={urls.memberships} component={MembershipPage} />
            <Route path={urls.vouchers} component={VouchersPage} />
            <Route path={urls.confirmation} component={Confirmation} />
            <Route
              path={urls.checkout(':profileId', ':checkoutId')}
              component={Checkout}
            />
            <Route path={urls.invoice(':id')} component={InvoicePayment} />
            <Route path={urls.swishPayment(':id')} component={SwishPayment} />
            <Route path={urls.cardPayment(':id')} component={CardPayment} />
            <Route path={urls.account} component={Account} />
            <Route
              path={urls.paymentLink(':transactionLinkHash')}
              component={PaymentLinkPage}
            />
          </Switch>
          <CheckoutFooter />
        </Content>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
};

export default <App />;
