import { Error } from 'components/Error/Error';
import { Loader } from 'components/Loader/Loader';
import { CHECKOUT_CONFIRMED, ERROR_NOT_FOUND } from 'constants/apiErrorCodeConstants';
import { MANDATORY, OPTIONAL } from 'constants/checkoutConstants';
import { urls } from 'helpers';
import { Namespaces } from 'languages';
import { toJS } from 'mobx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { CheckoutStore } from 'store/CheckoutStore';
import { ErrorStore } from 'store/ErrorStore';
import { HeaderStore } from 'store/HeaderStore';
import { LoginStore } from 'store/LoginStore';
import { PaymentStore } from 'store/PaymentStore';
import { UserStore } from 'store/UserStore';

export const useCheckout = () => {
  const history = useHistory();

  const {
    checkout,
    getCheckout,
    checkoutFieldErrors,
    paymentInfo,
    setPaymentInfo,
    clearFieldErrors,
    removeItemFromCheckout,
  } = CheckoutStore;
  const { userProxies, getUserProxies } = UserStore;
  const { user } = LoginStore;
  const { error } = ErrorStore;

  const [phoneNumber, setPhoneNumber] = useState(paymentInfo?.phoneNumber || '');
  const [customerMessage, setCustomerMessage] = useState(
    paymentInfo?.customerMessage || ''
  );

  const { activePromotion } = PaymentStore;
  const { setBackButton, setProfileId } = HeaderStore;
  const { t } = useTranslation(Namespaces.UI);
  const checkoutData = toJS(checkout);
  const { checkoutId } = useParams<{
    checkoutId: string;
  }>();

  const checkoutErrors = toJS(checkoutFieldErrors);

  const transactionLinkHash = new URLSearchParams(useLocation().search).get(
    'transactionLinkHash'
  ) as string;

  const renderError = () => {
    if (error && error.place === 'checkout') {
      let errorMessage = error.message;
      if (error.code === ERROR_NOT_FOUND) {
        errorMessage = t('errorCheckoutNotFound');
      } else if (error.code === CHECKOUT_CONFIRMED) {
        errorMessage = t('errorCheckoutConfirmed');
      }

      return (
        <Error
          errorMessage={errorMessage}
          callback={() => {
            history.push(urls.services(localStorage.profileId));
          }}
        />
      );
    }
    if (!localStorage.checkouts && !checkoutData) {
      return <Loader />;
    }
  };

  useEffect(() => {
    setBackButton(true);
    window.scrollTo(0, 0);
  }, [setBackButton]);

  useEffect(() => {
    getCheckout(checkoutId, transactionLinkHash);
  }, [checkoutId, getCheckout, transactionLinkHash]);

  useEffect(() => {
    if (checkoutData?.profile?.id) {
      const checkoutProfileId = checkoutData.profile.id;
      localStorage.profileId = checkoutProfileId;
      setProfileId(checkoutProfileId.toString());
    }
  }, [checkoutData?.profile?.id, setProfileId]);

  useEffect(() => {
    if (
      user?.id &&
      checkout?.items?.find(
        (x) => x.proxyUsersMode === OPTIONAL || x.proxyUsersMode === MANDATORY
      )
    ) {
      getUserProxies();
    }
  }, [user?.id, checkout?.items, getUserProxies]);

  useEffect(() => {
    const paymentInfo: { phoneNumber: string; customerMessage?: string } = {
      phoneNumber: '',
    };

    if (checkoutData?.phoneNumber) {
      paymentInfo.phoneNumber = checkoutData.phoneNumber;
      setPhoneNumber(checkoutData.phoneNumber);
      setPaymentInfo(paymentInfo);
    }

    if (checkoutData?.customerMessage) {
      paymentInfo.customerMessage = checkoutData.customerMessage;
      setCustomerMessage(checkoutData.customerMessage);
      setPaymentInfo(paymentInfo);
    }

    return () => {
      clearFieldErrors();
    };
  }, [
    checkout,
    checkoutData?.customerMessage,
    checkoutData?.phoneNumber,
    clearFieldErrors,
    setPaymentInfo,
  ]);

  return {
    renderError,
    checkoutErrors,
    checkoutData,
    activePromotion,
    checkoutId,
    removeItemFromCheckout,
    userProxies,
    transactionLinkHash,
    phoneNumber: { phoneNumber, setPhoneNumber },
    customerMessage: { customerMessage, setCustomerMessage },
  };
};
