import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';
import Icon from 'assets/icons/checkBlack.svg';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { FC } from 'react';

const CheckoutBlock = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
const CheckoutItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 26px;
  height: 26px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 5px;
`;

const Text = styled.div`
  margin: 0 5px 0 14px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
`;

const BoldText = styled.div`
  height: 24px;
  margin: 0;
  border-bottom: 2px solid black;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-weight: 600;
`;

interface ICheckboxProps {
  isActive: boolean;
  setSelect: () => void;
  linkAction?: () => void;
  label?: string;
}

export const Checkbox: FC<ICheckboxProps> = ({
  isActive,
  setSelect,
  linkAction,
  label,
}) => {
  const { t } = useTranslation(Namespaces.UI);
  return (
    <CheckoutBlock onClick={setSelect} data-testid="checkbox">
      <CheckoutItem>
        {isActive ? <CustomIcon width="14px" height="12px" icon={Icon} /> : null}
      </CheckoutItem>
      <Text>{label || t('checkoutAcceptTerms1')}</Text>
      {linkAction && (
        <BoldText
          onClick={(e) => {
            e.stopPropagation();
            linkAction?.();
          }}
        >
          {t('checkoutAcceptTerms2')}
        </BoldText>
      )}
    </CheckoutBlock>
  );
};
