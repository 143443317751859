import { Namespaces } from 'languages';
import { FC, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CollapseTextContainer, ToggleButton } from './styles';

interface CollapseTextProps {
  text: string;
  maxLines: number;
}

export const CollapseText: FC<CollapseTextProps> = ({ text = '', maxLines }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [displayText, setDisplayText] = useState<string | ReactNode>(text);
  const textRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(Namespaces.UI);

  useEffect(() => {
    const container = textRef.current;
    if (container) {
      const lineHeight = parseInt(window.getComputedStyle(container)?.lineHeight, 10);
      const maxHeight = lineHeight * maxLines;

      // Create a temporary element for measurements
      const measureElement = document.createElement('div');
      measureElement.style.visibility = 'hidden';
      measureElement.style.position = 'absolute';
      measureElement.style.width = `${container.clientWidth}px`;
      measureElement.style.fontSize = window.getComputedStyle(container).fontSize;
      measureElement.style.lineHeight = `${lineHeight}px`;
      document.body.appendChild(measureElement);

      // Binary search for the optimal truncation point
      let low = 0;
      let high = text.length;
      let truncatedText = text;

      while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        measureElement.innerText = text.slice(0, mid) + ` ${t('readMore')}...`;

        if (measureElement.scrollHeight <= maxHeight) {
          truncatedText = text.slice(0, mid);
          low = mid + 1;
        } else {
          high = mid - 1;
        }
      }

      // Clean up
      document.body.removeChild(measureElement);

      setDisplayText(
        <>
          {truncatedText}
          {truncatedText !== text && (
            <ToggleButton onClick={toggleCollapse}> {t('readMore')}...</ToggleButton>
          )}
        </>
      );
    }
  }, [text, maxLines]);

  const toggleCollapse = (e: MouseEvent) => {
    e.stopPropagation();
    setIsCollapsed(!isCollapsed);
  };

  if (!text) {
    return null;
  }

  return (
    <CollapseTextContainer ref={textRef}>
      {isCollapsed ? (
        displayText
      ) : (
        <>
          {text}
          <ToggleButton onClick={toggleCollapse}> {t('readLess')}</ToggleButton>
        </>
      )}
    </CollapseTextContainer>
  );
};
