import { devices } from 'constants/mediaConstants';
import styled, { keyframes } from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

const fadeIn = keyframes`
  from {
    bottom: -90px;
  }
  to {
    bottom: 0;
  }
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 2;
  width: 100%;
  height: 84px;
  max-width: 720px;
  padding: 18px;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  animation: ${fadeIn} 0.7s ease-in-out;
  animation-iteration-count: 1;
`;

export const CheckoutButton = styled(PrimaryButton)`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  @media${devices.widgetDesktop} {
  }

  @media${devices.mobile} {
    font-size: 14px;
  }
`;

export const LeftContainer = styled.div`
  flex: 1;
`;

export const MiddleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ItemCountLabel = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-size: 14px;
  font-weight: 400;
  width: 32px;
  height: 32px;
  text-align: center;
  padding-top: 10px;
`;

export const MiddleContainerTextContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 12px;
`;

export const PriceAmountLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-size: 18px;
  font-weight: 700;
  white-space: pre-wrap;
`;

export const PriceCurrencyLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-size: 18px;
  font-weight: 500;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;
`;

export const GoToCheckoutLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-size: 14px;
  font-weight: 400;
`;

export const GoToCheckoutHideIfNarrowLabel = styled(GoToCheckoutLabel)`
  @media${devices.mobile} {
    display: none;
  }
`;
