import moment from 'moment';

export const getYMDTime = (
  date: string | Date | undefined,
  keepTimeZone: boolean = false
) => {
  return formatDateTime(date, 'YYYY-MM-DD HH:mm', keepTimeZone);
};

export const getYMD = (
  date: string | Date | undefined,
  keepTimeZone: boolean = false
) => {
  return formatDateTime(date, 'YYYY-MM-DD', keepTimeZone);
};

export const getYM = (date: string | Date | undefined, keepTimeZone: boolean = false) => {
  return formatDateTime(date, 'YYYY-MM', keepTimeZone);
};

export const getY = (date: string | Date | undefined, keepTimeZone: boolean = false) => {
  return formatDateTime(date, 'YYYY', keepTimeZone);
};

export const getTime = (
  date: string | Date | undefined,
  keepTimeZone: boolean = false
) => {
  return formatDateTime(date, 'HH:mm', keepTimeZone);
};

export const getDayMonthTime = (
  date: string | Date | undefined,
  keepTimeZone: boolean = false
) => {
  return formatDateTime(date, 'D/M HH:mm', keepTimeZone);
};

export const formatDateTime = (
  date: string | Date | undefined,
  format: string,
  keepTimeZone: boolean = false
) => {
  if (!date) return '';
  if (keepTimeZone) {
    return moment.parseZone(date).format(format);
  }

  return moment(date).format(format);
};

export const isLocalTimeZoneSameAs = (date: string | Date) => {
  return moment().utcOffset() === moment.parseZone(date).utcOffset();
};

export const getDurationTextFromMinutes = (minutes: number) => {
  let days = Math.floor(minutes / 1440);
  let hours = Math.floor((minutes % 1440) / 60);
  let mins = Math.floor(minutes % 60);
  return `${days > 0 ? `${days}d ` : ''}${hours > 0 ? `${hours}h ` : ''}${
    mins > 0 ? `${mins}m` : ''
  }`;
};

export const getMonthName = (date: string | Date) => {
  const month = moment(date).format('MM');
  switch (month) {
    case '01':
      return 'january';
    case '02':
      return 'february';
    case '03':
      return 'march';
    case '04':
      return 'april';
    case '05':
      return 'may';
    case '06':
      return 'june';
    case '07':
      return 'july';
    case '08':
      return 'august';
    case '09':
      return 'september';
    case '10':
      return 'october';
    case '11':
      return 'november';
    case '12':
      return 'december';
    default:
      return null;
  }
};

export const isDueDate = (date: string) => {
  return moment(date) < moment(new Date());
};

export const getWeekdayName = (date: string, keepTimeZone: boolean = false) => {
  const momentDate = keepTimeZone ? moment.parseZone(date) : moment(date);
  if (momentDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
    return 'today';
  }
  if (momentDate.format('YYYY-MM-DD') === moment().add(1, 'days').format('YYYY-MM-DD')) {
    return 'tomorrow';
  }
  return momentDate.format('dddd').toLowerCase();
};

export const convertMinutesToTime = (day: string, minutes: number) => {
  // Use parseZone so that offset becomes fixed and doesn't potentially change when adding minutes.
  // This is something that might happen on the actual days when offset changes on the local timezone
  let startOfDay = moment.parseZone(day).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return startOfDay.add(minutes, 'm').format('HH:mm');
};

export const convertMinutesToDate = (day: string, minutes: number) => {
  let startOfDay = moment.parseZone(day).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return startOfDay.add(minutes, 'm').format('YYYY-MM-DD');
};

export const getTimeLineMinutesFromDate = (
  type: string,
  date: string,
  startDate?: string | null,
  keepTimeZone: boolean = false
) => {
  const todayDate = type === 'start' ? date : startDate;
  const today = keepTimeZone ? moment.parseZone(todayDate) : moment(todayDate);
  const time = keepTimeZone ? moment.parseZone(date) : moment(date);
  const defaultValue = type === 'start' ? 0 : 23;
  const additionalValue = type === 'start' ? 0 : -1;
  const hours =
    +time.format('HH') + additionalValue >= 0
      ? +time.format('HH') + additionalValue
      : defaultValue;
  const minutes = +time.format('mm');
  if (type === 'end' && +today.format('DD') !== +time.format('DD')) {
    if (+time.format('HH') === 0) {
      return 23 * 60;
    }
    return minutes + (24 + hours) * 60;
  }
  return minutes + hours * 60;
};

export const getTimeLineRange = (
  start: string,
  end: string,
  keepTimeZone: boolean = false
) => {
  const startDate = keepTimeZone ? moment.parseZone(start) : moment(start);
  const endDate = keepTimeZone ? moment.parseZone(end) : moment(end);

  const startHours = +startDate.format('HH');
  const endHours = +endDate.format('HH') ? +endDate.format('HH') : 24;

  const startDay = +startDate.format('DD');
  const endDay = +endDate.format('DD');

  if (endDay - startDay <= 1 && +endDate.format('HH') === 0) {
    return [startHours, endHours];
  }

  return [startHours, 24 + endHours];
};

export const getDateFromMinutes = (
  day: string,
  minutes: number,
  keepTimeZone: boolean = false
) => {
  const dayDate = keepTimeZone ? moment.parseZone(day) : moment(day);
  let dayDateWithMinutes = keepTimeZone ? moment.parseZone(dayDate) : moment(dayDate);
  dayDateWithMinutes
    .set({ hour: 0, minute: 0, second: 0, millisecond: 999 })
    .add(minutes, 'm');

  if (dayDate.utcOffset() !== dayDateWithMinutes.utcOffset()) {
    // Different offsets, meaning the minute addition will not reflect the correct time due to DST.
    // This should only happen on the actual day of change, subtract or add minutes according to the DST offset change
    const offsetMinuteDifference = Math.abs(
      dayDate.utcOffset() - dayDateWithMinutes.utcOffset()
    );

    if (dayDateWithMinutes.utcOffset() > dayDate.utcOffset()) {
      dayDateWithMinutes = dayDateWithMinutes.subtract(offsetMinuteDifference, 'm');
    } else {
      dayDateWithMinutes = dayDateWithMinutes.add(offsetMinuteDifference, 'm');
    }
  }
  return dayDateWithMinutes.format();
};
