import { useState, useEffect, FC } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useHistory, useLocation } from 'react-router';
import { useTranslation, TFunction } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import QRCode from 'react-qr-code';

import { Loader } from 'components/Loader/Loader';
import { Namespaces } from 'languages';

import { CheckoutStore, BankIDData, CheckoutData } from 'store/CheckoutStore';
import { LoginStore } from 'store/LoginStore';
import { HeaderStore } from 'store/HeaderStore';

import CheckIcon from 'assets/icons/checkmark.svg';

import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import {
  ContainerBlock,
  ButtonStart,
  ErrorText,
  Title,
  Subtitle,
  QRCodeContainer,
  OpenBankIDOnThisDeviceButton,
  ChangeFlowText,
  TryAgainButton,
  CancelText,
} from './styles/bankIDStyles';

const BANKID_STATUS_PENDING = 'pending';
const BANKID_STATUS_COMPLETE = 'complete';
const BANKID_STATUS_FAILED = 'failed';

const BANKID_HINT_CODE_OUTSTANDING_TRANSACTION = 'outstandingTransaction';
const BANKID_HINT_CODE_USER_SIGN = 'userSign';
const BANKID_HINT_CODE_USER_CANCEL = 'userCancel';
const BANKID_HINT_CODE_START_FAILED = 'startFailed';

export const BankIDCard = observer((props: { checkoutId: number }) => {
  const history = useHistory();
  const { checkoutId } = props;
  const {
    startBankIDAuth,
    collectBankID,
    bankIDData,
    setBankIDData,
    checkout,
    setCheckout,
    checkoutFieldErrors,
    setFieldError,
  } = CheckoutStore;

  const { user } = LoginStore;
  const { setBackButton, setTitle } = HeaderStore;
  const { t } = useTranslation(Namespaces.UI);

  const [isLoading, setIsLoading] = useState(false);
  const [autoStartToken, setAutoStartToken] = useState<string>('');
  const [forceChangeToFlow, setForceChangeToFlow] = useState<string>('');

  const userData = toJS(user);
  const checkoutData = toJS(checkout);
  const bankIDStatusData = toJS(bankIDData);
  const checkoutErrors = toJS(checkoutFieldErrors);

  const bankIDTransactionId = (bankIDStatusData?.transactionId ||
    checkoutData?.bankIDTransactionId) as number;
  const bankIDCurrentStatus = (bankIDStatusData?.status ||
    checkoutData?.bankIDStatus) as string;
  const bankIDCurrentHintCode = bankIDStatusData?.hintCode as string;
  const bankIDCurrentQrData = bankIDStatusData?.qrData as string;

  const transactionLinkHash = new URLSearchParams(useLocation().search).get(
    'transactionLinkHash'
  ) as string;

  useEffect(() => {
    if (bankIDCurrentStatus !== BANKID_STATUS_PENDING || !bankIDTransactionId) return;

    // Collect status
    let collectTimeoutId = setTimeout(doCollect, 1000);

    function doCollect() {
      collectBankID(bankIDTransactionId, (bankIDData: BankIDData) => {
        if (bankIDData.status !== BANKID_STATUS_PENDING) {
          // No longer pending, no need to collect further
          setIsLoading(false);
          clearTimeout(collectTimeoutId);
          setCheckout({
            ...checkout,
            bankIDStatus: bankIDData.status,
          } as CheckoutData);
        } else {
          collectTimeoutId = setTimeout(doCollect, 1000);
        }
      });
    }

    return () => {
      clearTimeout(collectTimeoutId);
    };
  }, [bankIDCurrentStatus, bankIDTransactionId]);

  useEffect(() => {
    return () => {
      clearBankIdData();
    };
  }, []);

  const onBankIDStartTransactionClicked = () => {
    if (isLoading) return;

    if (!userData.id) {
      goToLogin();
      return;
    }

    setIsLoading(true);
    setFieldError('bankID', '');
    startBankIDAuth(checkoutId, transactionLinkHash, (bankIDData: BankIDData) => {
      setAutoStartToken(bankIDData.autoStartToken);
    });
  };

  const onTryAgainClicked = () => {
    onBankIDStartTransactionClicked();
  };

  const onRestartClicked = () => {
    clearBankIdData();
    setForceChangeToFlow('');
  };

  const onChangeFlowClicked = (changeToFlow: string) => {
    setForceChangeToFlow(changeToFlow);
    setIsLoading(false);
  };

  const clearBankIdData = () => {
    setBankIDData(null);
    setCheckout({
      ...checkout,
      bankIDStatus: undefined,
      bankIDTransactionId: undefined,
    } as CheckoutData);
  };

  const goToLogin = () => {
    setBackButton(true);
    setTitle(t('loginTitle'));
    localStorage.prevPath = history.location.pathname + history.location.search;
    history.push('/auth');
  };

  return (
    <ContainerBlock data-testid="bankid-card">
      {!bankIDCurrentStatus && (
        <ButtonStart
          size="large"
          onClick={() => {
            onBankIDStartTransactionClicked();
          }}
        >
          {isLoading ? <Loader isWhite small /> : t('checkoutBankIDStartAuth')}
        </ButtonStart>
      )}
      {bankIDCurrentStatus &&
        (forceChangeToFlow === 'mobile' || (!forceChangeToFlow && isMobile)) && (
          <BankIDMobileFlow
            status={bankIDCurrentStatus}
            hintCode={bankIDCurrentHintCode}
            autoStartToken={autoStartToken}
            t={t}
            isLoading={isLoading}
            onTryAgainClicked={onTryAgainClicked}
            onRestartClicked={onRestartClicked}
            onChangeFlowClicked={onChangeFlowClicked}
          />
        )}
      {bankIDCurrentStatus &&
        (forceChangeToFlow === 'desktop' || (!forceChangeToFlow && !isMobile)) && (
          <BankIDDesktopFlow
            status={bankIDCurrentStatus}
            hintCode={bankIDCurrentHintCode}
            qrData={bankIDCurrentQrData}
            autoStartToken={autoStartToken}
            t={t}
            isLoading={isLoading}
            showChangeFlowButton={!forceChangeToFlow}
            onTryAgainClicked={onTryAgainClicked}
            onRestartClicked={onRestartClicked}
            onChangeFlowClicked={onChangeFlowClicked}
          />
        )}
      {checkoutErrors.bankID && (
        <ErrorText>{checkoutErrors.bankID.errorMessage}</ErrorText>
      )}
    </ContainerBlock>
  );
});

const getSubtitleText = (
  hintCode: string,
  isMobile: boolean,
  t: TFunction<Namespaces>
) => {
  if (hintCode === BANKID_HINT_CODE_OUTSTANDING_TRANSACTION) {
    if (isMobile) {
      return t('checkoutBankIDHintCodeOutstandingTransactionMobile');
    }
    return t('checkoutBankIDHintCodeOutstandingTransactionDesktop');
  } else if (hintCode === BANKID_HINT_CODE_USER_SIGN) {
    return t('checkoutBankIDHintCodeUserSign');
  } else if (hintCode === BANKID_HINT_CODE_USER_CANCEL) {
    return t('checkoutBankIDHintCodeUserCancel');
  } else if (hintCode === BANKID_HINT_CODE_START_FAILED) {
    return t('checkoutBankIDHintCodeStartFailed');
  }

  return null;
};

interface BankIDFlowProps {
  status: string;
  hintCode: string;
  autoStartToken: string;
  qrData?: string;
  isLoading: boolean;
  showChangeFlowButton?: boolean;
  t: TFunction<Namespaces>;
  onTryAgainClicked: () => void;
  onRestartClicked: () => void;
  onChangeFlowClicked: (changeToFlow: string) => void;
}

const BankIDMobileFlow: FC<BankIDFlowProps> = ({
  status,
  hintCode,
  autoStartToken,
  isLoading,
  t,
  onTryAgainClicked,
  onRestartClicked,
  onChangeFlowClicked,
}) => {
  return (
    <>
      {status === BANKID_STATUS_PENDING && (
        <>
          <Title>{t('checkoutBankIDIdentify')}</Title>
          <Subtitle>
            {getSubtitleText(hintCode, true, t) ||
              t('checkoutBankIDHintCodeOutstandingTransactionMobile')}
          </Subtitle>

          {autoStartToken && (
            <a
              href={`https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`}
              target="_top"
            >
              <OpenBankIDOnThisDeviceButton>
                {t('checkoutBankIDOpenBankID')}
              </OpenBankIDOnThisDeviceButton>
            </a>
          )}

          {hintCode !== BANKID_HINT_CODE_USER_SIGN && (
            <ChangeFlowText onClick={() => onChangeFlowClicked('desktop')}>
              {t('checkoutBankIDOpenOnDifferentDevice')}
            </ChangeFlowText>
          )}
        </>
      )}
      {status === BANKID_STATUS_COMPLETE && (
        <>
          <CustomIcon width="39px" height="39px" icon={CheckIcon} margin="0 0 0 0" />
          <Subtitle>{t('checkoutBankIDCompleted')}</Subtitle>
        </>
      )}
      {status === BANKID_STATUS_FAILED && (
        <>
          <Title>{t('checkoutBankIDIdentify')}</Title>
          <Subtitle>{getSubtitleText(hintCode, true, t)}</Subtitle>

          <TryAgainButton size="large" onClick={() => onTryAgainClicked()}>
            {isLoading ? <Loader isWhite small /> : t('checkoutBankIDTryAgain')}
          </TryAgainButton>

          <CancelText onClick={() => onRestartClicked()}>
            {t('checkoutBankIDCancel')}
          </CancelText>
        </>
      )}
    </>
  );
};

const BankIDDesktopFlow: FC<BankIDFlowProps> = ({
  status,
  hintCode,
  qrData,
  autoStartToken,
  isLoading,
  showChangeFlowButton,
  t,
  onTryAgainClicked,
  onRestartClicked,
}) => {
  return (
    <>
      {status === BANKID_STATUS_PENDING && (
        <>
          <Title>{t('checkoutBankIDIdentify')}</Title>
          <Subtitle>
            {getSubtitleText(hintCode, false, t) ||
              t('checkoutBankIDHintCodeOutstandingTransactionDesktop')}
          </Subtitle>

          {qrData && (
            <QRCodeContainer>
              <QRCode
                size={200}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={qrData}
                viewBox={`0 0 200 200`}
              />
            </QRCodeContainer>
          )}

          {showChangeFlowButton && hintCode !== BANKID_HINT_CODE_USER_SIGN && (
            <ChangeFlowText
              onClick={() =>
                (window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`)
              }
            >
              {t('checkoutBankIDOpenOnThisDevice')}
            </ChangeFlowText>
          )}
        </>
      )}
      {status === BANKID_STATUS_COMPLETE && (
        <>
          <CustomIcon width="39px" height="39px" icon={CheckIcon} margin="0 0 0 0" />
          <Subtitle>{t('checkoutBankIDCompleted')}</Subtitle>
        </>
      )}
      {status === BANKID_STATUS_FAILED && (
        <>
          <Title>{t('checkoutBankIDIdentify')}</Title>
          <Subtitle>{getSubtitleText(hintCode, false, t)}</Subtitle>

          <TryAgainButton size="large" onClick={() => onTryAgainClicked()}>
            {isLoading ? <Loader isWhite small /> : t('checkoutBankIDTryAgain')}
          </TryAgainButton>

          <CancelText onClick={() => onRestartClicked()}>
            {t('checkoutBankIDCancel')}
          </CancelText>
        </>
      )}
    </>
  );
};
