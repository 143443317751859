import { devices } from 'constants/mediaConstants';
import styled, { DefaultTheme } from 'styled-components';

type CardDateValuesType = {
  bigFontSize?: boolean;
  isLeft?: boolean;
  theme: DefaultTheme;
};

export const Container = styled.div`
  max-width: 100%;
  margin: ${(props: { isBig?: boolean }) => (props.isBig ? '30px 0 0' : '30px 0 0')};
`;

export const DatesContainer = styled.div`
  display: flex;
  position: relative;
`;

export const CardStartBlock = styled.div`
  width: 50%;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px 0 0 10px;
  cursor: pointer;
  @media ${devices.widgetDesktop} {
    padding: 8px;
  } ;
`;

export const CardEndBlock = styled.div`
  width: 50%;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-left: 0;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  @media ${devices.widgetDesktop} {
    padding: 8px;
  } ;
`;

export const CardDateTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  @media ${devices.mobile} {
    font-size: 11px;
  } ;
`;

export const CardDateValues = styled.div`
  position: relative;
  display: flex;
  justify-content: ${(props: CardDateValuesType) =>
    props.isLeft ? 'left' : 'space-between'};
  align-items: center;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  flex-wrap: wrap;
  @media ${devices.widgetDesktop} {
    font-size: ${(props: CardDateValuesType) => (props.bigFontSize ? '18px' : '14px')};
  }
  @media ${devices.mobile} {
    font-size: 14px;
  } ;
`;

export const TimeValue = styled.div`
  border-bottom: ${(props: { isUnderLine?: boolean; theme: DefaultTheme }) =>
    props.isUnderLine ? `2px solid ${props.theme.colors.darkBlack}` : 0};
  color: ${({ theme }) => theme.colors.darkBlack};
`;

export const DateValue = styled.div`
  margin-right: 11px;
  color: ${(props: { isBlack?: boolean; theme: DefaultTheme }) =>
    props.isBlack ? props.theme.colors.darkBlack : props.theme.colors.darkGray};
`;

export const ArrowDownIcon = styled.div`
  @media (max-width: 520px) {
    position: absolute;
    top: -23px;
    right: 0px;
  }
`;

export const TimeZoneText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 4px;
`;
