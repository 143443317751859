import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { parseLocalStorageCheckout, urls, getCurrencyName } from 'helpers';
import { Namespaces } from 'languages';

import shoppingBagIcon from 'assets/icons/shoppingBag.svg';
import arrowRightIcon from 'assets/icons/arrowRightWhite.svg';

import { CheckoutStore } from 'store/CheckoutStore';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import {
  Footer,
  CheckoutButton,
  LeftContainer,
  MiddleContainer,
  ItemCountLabel,
  MiddleContainerTextContainer,
  PriceAmountLabel,
  PriceCurrencyLabel,
  RightContainer,
  GoToCheckoutLabel,
  GoToCheckoutHideIfNarrowLabel,
} from './styles/checkoutFooterStyles';

export const CheckoutFooter = observer(() => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(Namespaces.UI);

  const [hidden, setHidden] = useState(false);

  const { checkoutsInfo } = CheckoutStore;
  let checkoutsInfoData = toJS(checkoutsInfo);
  if (!checkoutsInfoData.length && localStorage.checkouts) {
    checkoutsInfoData = parseLocalStorageCheckout(JSON.parse(localStorage.checkouts));
  }

  const profileId = localStorage.getItem('profileId');
  const checkouts = checkoutsInfoData.filter(
    (checkout) => checkout && profileId && checkout.profile?.id === +profileId
  );
  const currentCheckout = checkouts.length ? checkouts[0] : null;
  const currentCheckoutId = currentCheckout?.id || 0;

  const disabledUrls = useMemo(() => {
    return {
      [urls.checkout(localStorage.profileId, currentCheckoutId)]: true,
      [urls.invoice(currentCheckoutId)]: true,
      [urls.cardPayment(currentCheckoutId)]: true,
      [urls.swishPayment(currentCheckoutId)]: true,
      [urls.auth]: true,
      [urls.forgotPassword]: true,
    };
  }, [currentCheckoutId]);

  useEffect(() => {
    // As checkout paths in some cases can contain ids not available, make sure footer is never shown on its pages
    setHidden(disabledUrls[location.pathname] || location.pathname.includes('/checkout'));
  }, [location, disabledUrls]);

  const openCheckout = () => {
    if (currentCheckoutId && profileId) {
      const isEqualPath =
        history.location.pathname === urls.checkout(profileId, currentCheckoutId);
      if (!isEqualPath) {
        history.push(urls.checkout(profileId, currentCheckoutId));
      }
    }
  };

  return !hidden && currentCheckout ? (
    <Footer>
      <CheckoutButton onClick={openCheckout}>
        <LeftContainer></LeftContainer>
        <MiddleContainer>
          <CustomIcon width="32px" height="32px" icon={shoppingBagIcon} />
          <ItemCountLabel>{currentCheckout.itemCount}</ItemCountLabel>

          {currentCheckout.price > 0 && (
            <MiddleContainerTextContainer>
              <PriceAmountLabel>{`${currentCheckout.price.toLocaleString()} `}</PriceAmountLabel>
              <PriceCurrencyLabel>
                {getCurrencyName(currentCheckout.currency)}
              </PriceCurrencyLabel>
            </MiddleContainerTextContainer>
          )}
        </MiddleContainer>
        <RightContainer>
          {currentCheckout.price > 0 ? (
            <GoToCheckoutHideIfNarrowLabel>
              {t('checkoutFooterGoToCheckout')}
            </GoToCheckoutHideIfNarrowLabel>
          ) : (
            <GoToCheckoutLabel>{t('checkoutFooterGoToCheckout')}</GoToCheckoutLabel>
          )}
          <CustomIcon
            width="18px"
            height="18px"
            margin="0 0 0 12px"
            icon={arrowRightIcon}
          />
        </RightContainer>
      </CheckoutButton>
    </Footer>
  ) : null;
});
