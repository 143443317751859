import { PriceReducer } from 'store/LoginStore';
import { getPrice, urls } from 'helpers';
import { DISCOUNT_TYPE_MEMBERSHIP } from 'constants/discountConstants';
import Membership from 'assets/icons/membership-icon.svg';
import Voucher from 'assets/icons/voucher-icon.svg';
import { useHistory } from 'react-router';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { VoucherBody, VoucherName, VoucherValue, VoucherList } from './styles';
import { FC, MouseEvent } from 'react';

interface IProps {
  priceReducers: PriceReducer[];
}

export const DiscountAlert: FC<IProps> = ({ priceReducers }) => {
  return (
    <>
      {/* Not needed for now */}
      {/* 
      <AlertBody>
        <AnimationText>
          <CustomIcon icon={Peace} width="26px" height="36px" />
          <AlertText>
            <span>{`${t('availableDiscounts')} `}</span>
            <BoldText>{t('discounts').toLocaleLowerCase()}</BoldText>
            <span>!</span>
          </AlertText>
        </AnimationText>
      </AlertBody>
      */}
      <VoucherList length={priceReducers.length}>
        {priceReducers.map((el, i) => (
          <VoucherListItem priceReducer={el} key={i} />
        ))}
      </VoucherList>
    </>
  );
};

const VoucherListItem: FC<{ priceReducer: PriceReducer }> = ({ priceReducer }) => {
  const history = useHistory();

  const voucherValue =
    priceReducer.type === DISCOUNT_TYPE_MEMBERSHIP
      ? `${priceReducer.discountPercentage || ''}%`
      : getPrice(priceReducer.balance, priceReducer.currency);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (priceReducer.type === DISCOUNT_TYPE_MEMBERSHIP) {
      history.push(urls.memberships);
    } else {
      history.push(urls.vouchers);
    }
  };

  return (
    <VoucherBody onClick={handleClick}>
      {priceReducer.type === DISCOUNT_TYPE_MEMBERSHIP ? (
        <CustomIcon width="20px" height="20px" margin="0 11px 0 0 " icon={Membership} />
      ) : (
        <CustomIcon width="20px" height="20px" icon={Voucher} margin="0 11px 0 0 " />
      )}
      <VoucherName title={priceReducer.name}>{priceReducer.name}</VoucherName>
      <VoucherValue>{voucherValue}</VoucherValue>
    </VoucherBody>
  );
};
