import { FC, useEffect, useState } from 'react';
import { CollapseButton, Container } from './styles';
import { Body } from './Body';
import { CollapseText } from 'components/CollapseText/CollapseText';
import { ArrowDown, ArrowUp } from 'components/ServicePanel/styles';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';

export const WeekSchedule: FC<{ initialExpanded: boolean }> = ({ initialExpanded }) => {
  const [isExpanded, setIsExpanded] = useState(initialExpanded);
  const { t } = useTranslation(Namespaces.UI);

  useEffect(() => {
    const { elementDate } = localStorage;
    if (elementDate) {
      setIsExpanded(true);
    }
  }, []);

  return (
    <Container
      isExpanded={isExpanded}
      onClick={(e) => {
        e.stopPropagation();
        setIsExpanded(!isExpanded);
      }}
    >
      <p className="title">{t('schedule')}</p>
      <div className="description">
        <CollapseText text={t('scheduleDescription')} maxLines={3} />
      </div>

      {isExpanded && <Body />}
      <CollapseButton>
        {isExpanded ? t('hide') : t('show')}{' '}
        {isExpanded ? <ArrowUp size={22} /> : <ArrowDown size={22} />}
      </CollapseButton>
    </Container>
  );
};
