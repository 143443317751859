import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

import ArrowDown from 'assets/icons/arrow-down.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';

import { Flex } from 'styled/Flex';
import { Card, CardHeader, CardNumber } from './styles';

interface IProps {
  header: JSX.Element;
  body: JSX.Element[] | JSX.Element;
  number: number;
  collapsedHeader?: JSX.Element;
  isOpen?: boolean;
  isOpenByError?: boolean;
  setIsOpenByError?: Dispatch<SetStateAction<boolean>>;
}

export const CollapseCard: FC<IProps> = (props) => {
  const {
    header,
    number,
    body,
    collapsedHeader,
    isOpen: propIsOpen = true,
    isOpenByError = false,
    setIsOpenByError,
  } = props;

  const [isOpen, setIsOpen] = useState(propIsOpen);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const handleIconClick = () => {
    if (setIsOpenByError && isOpenByError && isOpen) {
      setIsOpenByError(false);
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpenByError) {
      setIsOpen(true);

      // Reset state so that manual collapse will open it again on error
      if (setIsOpenByError) {
        setIsOpenByError(false);
      }
    }
  }, [isOpenByError]);

  return (
    <Card data-testid="collapse-card">
      <CardHeader onClick={toggleCollapse}>
        <CardNumber>{number}</CardNumber>
        <Flex alignItems="center">
          {header}
          {collapsedHeader && !isOpen ? collapsedHeader : null}
        </Flex>
        <CustomIcon
          width="20px"
          height="12px"
          margin="0 0 0 15px"
          icon={isOpen ? ArrowUp : ArrowDown}
          onClick={handleIconClick}
          cursor
          hover
        />
      </CardHeader>
      {isOpen && <div>{body}</div>}
    </Card>
  );
};
