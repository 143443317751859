import { devices } from 'constants/mediaConstants';
import styled, { DefaultTheme } from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

export const CardItem = styled.div`
  position: relative;
  margin: 20px 0;
  padding: ${(props: { isCollapsed: boolean }) =>
    props.isCollapsed ? '26px 34px 48px' : '26px 34px 10px'};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 20px;
  line-height: 20px;

  @media ${devices.mobile} {
    padding: 16px 10px 10px;
  }
`;

export const HeaderElements = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-bottom: 13px;
  color: ${({ theme }) => theme.colors.darkBlack};
  line-height: 24px;
`;

export const CardTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
`;

export const CardDescription = styled.div`
  margin: 4px 10px 13px 5px;
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
`;

export const CardPrice = styled.div`
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-decoration-line: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'line-through' : 'none'};
  white-space: nowrap;
  @media${devices.mobile} {
    font-size: 14px;
  }
`;

export type PriceStyledProps = {
  isRed?: boolean;
  isBold?: boolean;
  isLineThrough?: boolean;
  theme: DefaultTheme;
};

export const Price = styled.div`
  color: ${(props: PriceStyledProps) =>
    props.isRed ? props.theme.colors.red : props.theme.colors.darkBlack};
  margin-left: 5px;
  font-style: normal;
  font-weight: ${(props: PriceStyledProps) => (props.isBold ? 'bold' : 'normal')};
  font-size: 18px;
  white-space: nowrap;
  text-decoration-line: ${(props: PriceStyledProps) =>
    props.isLineThrough ? 'line-through' : 'none'};
  @media${devices.mobile} {
    font-size: 14px;
  }
`;

export const DiscountBlock = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: column;
  border-bottom: 1px solid #dcdcdc;
`;

export const DiscountDescription = styled.div`
  margin: 4px 10px 13px 5px;
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
`;

export const DiscountValue = styled.div`
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  color: ${({ theme }) => theme.colors.red};
  @media${devices.mobile} {
    font-size: 16px;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 38px;
  margin-right: 30px;

  @media ${devices.mobile} {
    font-size: 16px;
  }
`;

export const TotalPrice = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  text-align: right;
  @media${devices.mobile} {
    font-size: 16px;
  }
`;

export const TotalBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #181818;
  margin-top: 26px;
  @media${devices.mobile} {
    height: 26px;
  }
`;

export const UserProxyBlockContainer = styled.div`
  margin-top: 40px;
`;

export const UserProxyBlockTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
`;

export const UserProxyBlockDescription = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 16px;
`;

export const UserProxyItemContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px;
  height: 58px;
  padding: 0px 16px 0px 16px;
`;

export const UserProxyItemLeftContainer = styled.div`
  flex-grow: 1;
`;

export const UserProxyItemRightContainer = styled.div`
  margin-right: 16px;
`;

export const UserProxyItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.lightBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
`;

export const UserProxyItemAddButton = styled(PrimaryButton)`
  font-size: 14px;
`;

export const AddUserProxyButton = styled(PrimaryButton)`
  font-size: 14px;
  margin-top: 20px;
  width: 175px;
`;

export const ErrorText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  margin-top: 12px;
  margin-bottom: 12px;
`;
