import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

export const ContainerBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;

  @media ${devices.mobile} {
    padding: 20px;
  }
`;

export const ButtonStart = styled(PrimaryButton)`
  width: 250px;
  height: 56px;
  font-size: 16px;
`;

export const Title = styled.div`
  display: inline;
  font-weight: normal;
  font-size: 32px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  color: #193e4f;

  @media ${devices.mobile} {
    font-size: 22px;
  }
`;

export const Subtitle = styled.div`
  display: inline;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  color: #193e4f;
  margin-top: 10px;

  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

export const QRCodeContainer = styled.div`
  height: 'auto';
  margin: '0 auto';
  maxwidth: 64;
  width: '100%';
  margin-top: 25px;
`;

export const ChangeFlowText = styled.div`
  display: inline;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  color: #193e4f;
  text-decoration: underline;
  margin-top: 25px;

  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

export const TryAgainButton = styled(PrimaryButton)`
  width: 250px;
  height: 56px;
  margin-top: 20px;
  font-size: 16px;
`;

export const OpenBankIDOnThisDeviceButton = styled(PrimaryButton)`
  width: 250px;
  height: 56px;
  margin-top: 20px;
  font-size: 16px;
`;

export const CancelText = styled.div`
  display: inline;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  color: #193e4f;
  text-decoration: underline;
  margin-top: 20px;

  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

export const ErrorText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  margin-top: 20px;
`;
