import { useEffect, useState } from 'react';

import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { getPrice } from 'helpers';
import { isMobile } from 'react-device-detect';
import {
  getDateFromMinutes,
  getTime,
  getTimeLineMinutesFromDate,
  getYMD,
} from 'helpers/DateTimeUtils';
import { useTranslation } from 'react-i18next';
import { getAddBookingCTAEvent, postCTAEventMessage } from 'helpers/EventUtils';

import { Loader } from 'components/Loader/Loader';
import { Slot, TimeLine } from 'components/TimeLine/TimeLine';
import { TimeButton } from 'components/TimeButton/TimeButton';
import { CardDateBlock } from 'components/CardDateBlock/CardDateBlock';
import { devices } from 'constants/mediaConstants';
import { APPOINTMENT_TYPE_FLEXIBLE } from 'constants/appointmentConstants';

import { Namespaces } from 'languages';
import { CheckoutStore } from 'store/CheckoutStore';
import { AppointmentSlotFlexible, ServiceStore } from 'store/ServiceStore';

import PriceIcon from 'assets/icons/price.svg';

import AppointmentCardContainer from 'styled/AppointmentCardContainer';
import AppointmentCardCoverImage from 'styled/AppointmentCardCoverImage';
import { Flex } from 'styled/Flex';
import { CollapseText } from 'components/CollapseText/CollapseText';
import CloseIcon from 'assets/icons/close.svg';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { Avatar } from 'components/Avatar';
import { FlexibleCardHeader } from './styles';
import {
  ModalDateTitle,
  ModalBody,
  ModalTimeButtonWrapper,
  IconClose,
  TimeLabel,
  Wrapper,
  TimeWrapper,
  MinMaxBlock,
  LineWrapper,
  CardDescription,
  CardPriceData,
  CardDateContainer,
  CardButton,
  Modal,
  ModalCardName,
} from './flexibleCardStyles';
import { ExpandCollapse } from 'components/ExpandCollapse';
import { ActiveRow } from './ActiveRow';

export type PairsAllowedRangesType = {
  start: string;
  end: string;
};

export const AppointmentCardFlexibleSlots = observer(
  (props: { appointment: AppointmentSlotFlexible; defaultActive: boolean }) => {
    const {
      appointment: {
        avatarUrl,
        name,
        description,
        price,
        fromPrice,
        pricePerUnit,
        currency,
        checkoutItemData,
        slots,
        coverImageUrl,
        rangeStartTime,
        rangeEndTime,
        rangeIncrementMinutes,
        maxBookableMinutes,
        minBookableMinutes,
        groupSelectMode,
      },
      defaultActive,
    } = props;
    const { profileId } = useParams<{ profileId: string }>();
    const [checkAvailableCount, setCheckAvailableCount] = useState(false);
    const [loading, setLoading] = useState(false);
    const [availableCountLoading, setAvailableCountLoading] = useState(false);
    const [isModalOpen, setOpenModal] = useState(false);
    const [modalName, setModalName] = useState('');
    const [activeSlots, setActiveSlot] = useState<{
      startRangeTime: number | null;
      endRangeTime: number | null;
    }>({
      startRangeTime: null,
      endRangeTime: null,
    });
    const [isCollapsed, setCollapsed] = useState(defaultActive);
    const [quantity, setQuantity] = useState(1);
    const { addItemToCheckout } = CheckoutStore;
    const { checkFlexibleSlots, availableFlexibleSlots } = ServiceStore;
    const { t } = useTranslation(Namespaces.UI);
    const getRequestTimeSlot = (searchDay: string) => {
      const { startRangeTime, endRangeTime } = activeSlots;
      const checkoutItemDataItems = {
        ...checkoutItemData,
        startTime: getDateFromMinutes(searchDay, startRangeTime as number),
        endTime: getDateFromMinutes(searchDay, endRangeTime as number),
      };
      return checkoutItemDataItems;
    };
    const durationMinutes =
      (new Date(getRequestTimeSlot(rangeStartTime).endTime).getTime() -
        new Date(getRequestTimeSlot(rangeStartTime).startTime).getTime()) /
      (1000 * 60);

    const checkStartTime = (
      pairsAllowedRanges: PairsAllowedRangesType[],
      currentPair: number,
      time: string
    ) => {
      if (activeSlots.endRangeTime === null) {
        if (
          time >= pairsAllowedRanges[currentPair].start &&
          time < pairsAllowedRanges[currentPair].end
        ) {
          return true;
        }
      } else {
        const hours = Math.trunc(activeSlots.endRangeTime / 60);
        const minute = activeSlots.endRangeTime - hours * 60;
        const tempHours = hours < 10 ? `0${hours}` : `${hours}`;
        const tempMinute = minute < 10 ? `0${minute}` : `${minute}`;
        const currentEndRange = `${tempHours}:${tempMinute}`;
        const activeRange = pairsAllowedRanges.find((el) => {
          const { start, end } = el;
          return currentEndRange > start && currentEndRange <= end;
        });

        if (activeRange && time >= activeRange.start && time < currentEndRange) {
          return true;
        }
      }
      return false;
    };

    const handleTimeRangeSelect = async (e?: Slot) => {
      const isTimeEqual =
        e?.endRangeTime === activeSlots.endRangeTime &&
        e?.startRangeTime === activeSlots.startRangeTime;

      if (groupSelectMode === 'selectCount' && !availableCountLoading && !isTimeEqual) {
        setAvailableCountLoading(true);
        await checkFlexibleSlots(
          props.appointment.checkoutItemData.serviceId,
          getDateFromMinutes(rangeStartTime, e?.startRangeTime as number),
          getDateFromMinutes(rangeStartTime, e?.endRangeTime as number),
          props.appointment.checkoutItemData.resourceGroupId
        );
        setAvailableCountLoading(false);
        setCheckAvailableCount(false);
      }
    };

    const checkEndTime = (
      pairsAllowedRanges: PairsAllowedRangesType[],
      currentPair: number,
      time: string
    ) => {
      if (activeSlots.startRangeTime === null) {
        if (
          time > pairsAllowedRanges[currentPair].start &&
          time <= pairsAllowedRanges[currentPair].end
        ) {
          return true;
        }
      } else {
        const hours = Math.trunc(activeSlots.startRangeTime / 60);
        const minute = activeSlots.startRangeTime - hours * 60;
        const tempHours = hours < 10 ? `0${hours}` : `${hours}`;
        const tempMinute = minute < 10 ? `0${minute}` : `${minute}`;
        const currentEndRange = `${tempHours}:${tempMinute}`;
        const activeRange = pairsAllowedRanges.find((el) => {
          const { start, end } = el;
          return currentEndRange >= start && currentEndRange < end;
        });
        if (activeRange && time > currentEndRange && time <= activeRange.end) {
          return true;
        }
      }
      return false;
    };

    const renderModalBody = () => {
      const minutesPattern: number[] = [];
      for (let i = 0; i < 60; i += rangeIncrementMinutes) {
        minutesPattern.push(i);
      }
      const hours = [];

      let currentPair = 0;
      const pairsAllowedRanges = slots.map((slot) => {
        const { startTime, endTime } = slot;
        const tempHours = Math.trunc(
          getTimeLineMinutesFromDate('end', endTime, startTime, true) / 60
        );
        const tempMinutes =
          getTimeLineMinutesFromDate('end', endTime, startTime, true) - 60 * tempHours;
        const endHours = `${tempHours + 1 < 10 ? `0${tempHours + 1}` : tempHours + 1}:${
          tempMinutes < 10 ? `0${tempMinutes}` : `${tempMinutes}`
        }`;

        const startAllowedTime = getTime(startTime, true);
        const endAllowedTime = endHours === '00:00' ? '24:00' : endHours;

        return { start: startAllowedTime, end: endAllowedTime };
      });
      for (
        let i = +pairsAllowedRanges[0].start.slice(0, 2);
        i <= +pairsAllowedRanges[pairsAllowedRanges.length - 1].end.slice(0, 2);
        i++
      ) {
        hours.push(i);
      }

      return (
        <>
          {hours.map((hour, ind) => {
            const hourTitle = hour - 24 >= 0 ? hour - 24 : hour;
            const isShouldRenderDay = hour - 24 === 0;
            return (
              <div key={ind}>
                {isShouldRenderDay && (
                  <ModalDateTitle>{getYMD(rangeEndTime, true)}</ModalDateTitle>
                )}
                <LineWrapper>
                  <TimeLabel>{hourTitle < 10 ? `0${hourTitle}` : hourTitle}</TimeLabel>
                  <Wrapper>
                    <TimeWrapper column={60 / rangeIncrementMinutes} key={hour}>
                      {minutesPattern.map((minute, i) => {
                        const timeHour = hour < 10 ? `0${hour}` : `${hour}`;
                        const hourForRender =
                          hourTitle < 10 ? `0${hourTitle}` : `${hourTitle}`;
                        const timeMinutes = minute >= 10 ? `${minute}` : `0${minute}`;
                        const time = `${timeHour}:${timeMinutes}`;
                        const timeForRender = `${hourForRender}:${timeMinutes}`;
                        let allowedStatus = false;

                        const checkFunctionAllowedTime =
                          modalName === 'start' ? checkStartTime : checkEndTime;
                        if (
                          checkFunctionAllowedTime(pairsAllowedRanges, currentPair, time)
                        ) {
                          allowedStatus = true;
                        } else {
                          if (
                            currentPair + 1 < pairsAllowedRanges.length &&
                            time > pairsAllowedRanges[currentPair].end
                          ) {
                            currentPair++;
                          }
                          if (
                            checkFunctionAllowedTime(
                              pairsAllowedRanges,
                              currentPair,
                              time
                            )
                          ) {
                            allowedStatus = true;
                          }
                        }
                        const timeInMinutes = hour * 60 + minute;

                        return (
                          <TimeButton
                            key={i}
                            isParsedTime
                            startTime={timeForRender}
                            action={() => {
                              if (allowedStatus) {
                                if (modalName === 'start') {
                                  setActiveSlot({
                                    ...activeSlots,
                                    startRangeTime: timeInMinutes,
                                  });
                                } else {
                                  setActiveSlot({
                                    ...activeSlots,
                                    endRangeTime: timeInMinutes,
                                  });
                                }
                                setOpenModal(false);
                              }
                            }}
                            active={
                              modalName === 'start'
                                ? activeSlots.startRangeTime === timeInMinutes
                                : activeSlots.endRangeTime === timeInMinutes
                            }
                            disabled={!allowedStatus}
                          />
                        );
                      })}
                    </TimeWrapper>
                  </Wrapper>
                </LineWrapper>
              </div>
            );
          })}
        </>
      );
    };

    useEffect(() => {
      setQuantity(1);
    }, [availableFlexibleSlots]);

    const isBookingButtonDisabled =
      activeSlots.startRangeTime === null ||
      activeSlots.endRangeTime === null ||
      (!!maxBookableMinutes &&
        maxBookableMinutes < activeSlots.endRangeTime - activeSlots.startRangeTime) ||
      (!!minBookableMinutes &&
        minBookableMinutes > activeSlots.endRangeTime - activeSlots.startRangeTime);

    let servicePrice = getPrice(fromPrice || price, currency, true, t(pricePerUnit));
    if (fromPrice) {
      servicePrice = `${t('from')} ${servicePrice}`;
    }

    return (
      <>
        <AppointmentCardContainer
          collapsed={isCollapsed}
          onClick={() => {
            setCollapsed(true);
          }}
          data-testid="appointment-card-flexible-slots"
          withPaddingTop={!coverImageUrl}
        >
          <Flex>
            <Flex flexDirection="column" width="100%">
              {coverImageUrl && isCollapsed ? (
                <AppointmentCardCoverImage img={coverImageUrl} />
              ) : null}
              <Flex alignItems="center" style={{ padding: '0 25px' }}>
                {avatarUrl && (
                  <Avatar size={50} url={avatarUrl} style={{ marginRight: '22px' }} />
                )}
                <FlexibleCardHeader>{name}</FlexibleCardHeader>
              </Flex>
              <CardDescription>
                <CollapseText text={description} maxLines={2} />
              </CardDescription>
              {servicePrice && (
                <Flex alignItems="center" style={{ padding: '0 25px' }}>
                  <CustomIcon
                    width="16px"
                    height="16px"
                    margin="0 10px 0 0"
                    icon={PriceIcon}
                  />
                  <CardPriceData>{servicePrice}</CardPriceData>
                </Flex>
              )}
              <Flex margin="0 0 0 18px" style={{ padding: '0 25px' }}>
                {minBookableMinutes && (
                  <MinMaxBlock
                    isError={
                      !!activeSlots.endRangeTime &&
                      !!activeSlots.startRangeTime &&
                      activeSlots.endRangeTime - activeSlots.startRangeTime <
                        minBookableMinutes
                    }
                  >
                    {t('min', { value: minBookableMinutes })}
                  </MinMaxBlock>
                )}
                {maxBookableMinutes && (
                  <MinMaxBlock
                    isError={
                      !!activeSlots.endRangeTime &&
                      !!activeSlots.startRangeTime &&
                      activeSlots.endRangeTime - activeSlots.startRangeTime >
                        maxBookableMinutes
                    }
                  >
                    {t('max', { value: maxBookableMinutes })}
                  </MinMaxBlock>
                )}
              </Flex>
              {isCollapsed && (
                <div style={{ padding: '0 25px' }}>
                  <TimeLine
                    rangeIncrementMinutes={rangeIncrementMinutes}
                    maxBookableMinutes={maxBookableMinutes}
                    minBookableMinutes={minBookableMinutes}
                    isMobileBrowser={isMobile}
                    resourceId={checkoutItemData.resourceId}
                    rangeStartTime={rangeStartTime}
                    rangeEndTime={rangeEndTime}
                    activeSlots={activeSlots}
                    setActiveSlot={setActiveSlot}
                    slots={slots}
                    onTimeRangeSelect={handleTimeRangeSelect}
                  />
                </div>
              )}
            </Flex>
          </Flex>
          {isCollapsed && (
            <>
              <CardDateContainer>
                <CardDateBlock
                  type={APPOINTMENT_TYPE_FLEXIBLE}
                  startAction={() => {
                    setOpenModal(true);
                    setModalName('start');
                  }}
                  endAction={() => {
                    setOpenModal(true);
                    setModalName('end');
                  }}
                  rangeStartTime={rangeStartTime}
                  startTime={activeSlots.startRangeTime}
                  endTime={activeSlots.endRangeTime}
                />
              </CardDateContainer>
              {!isBookingButtonDisabled && groupSelectMode === 'selectCount' ? (
                <ActiveRow
                  item={{
                    index: 0,
                    quantity,
                    startTime: availableFlexibleSlots?.startTime || '',
                    endTime: availableFlexibleSlots?.endTime || '',
                    checkoutItemData,
                    availableCount: availableFlexibleSlots?.availableCount,
                  }}
                  onSubmit={async () => {
                    if (!loading) {
                      await addItemToCheckout(profileId, [
                        { ...getRequestTimeSlot(rangeStartTime), quantity },
                      ]);
                      postCTAEventMessage(getAddBookingCTAEvent());
                    }
                  }}
                  price={price * Math.ceil(durationMinutes / 60)}
                  borderLess
                  onNext={() => setQuantity(quantity + 1)}
                  onPrev={() => setQuantity(quantity - 1)}
                  isLoading={availableCountLoading}
                />
              ) : (
                !isBookingButtonDisabled && (
                  <CardButton
                    disabled={isBookingButtonDisabled}
                    onClick={() => {
                      if (!loading) {
                        setLoading(true);
                        addItemToCheckout(
                          profileId,
                          [getRequestTimeSlot(rangeStartTime)],
                          setLoading
                        );
                        postCTAEventMessage(getAddBookingCTAEvent());
                      }
                    }}
                  >
                    {loading ? <Loader isWhite small /> : t('addToCart')}
                  </CardButton>
                )
              )}
            </>
          )}

          <ExpandCollapse isCollapsed={isCollapsed} setCollapsed={setCollapsed} />
        </AppointmentCardContainer>
        {isModalOpen && (
          <Modal>
            <ModalBody>
              <IconClose>
                <CustomIcon
                  width="20px"
                  height="20px"
                  icon={CloseIcon}
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  hover
                  cursor
                  device={devices.mobile}
                  deviceHeight="25px"
                  deviceWidth="25px"
                />
              </IconClose>
              <ModalCardName>{t(modalName === 'start' ? 'start' : 'end')}</ModalCardName>
              <ModalTimeButtonWrapper>{renderModalBody()}</ModalTimeButtonWrapper>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }
);
