import styled from 'styled-components';

export const CollapseTextContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const ToggleButton = styled.span`
  cursor: pointer;
  font-weight: 700;
  color: #000;
`;
