import {
  PERSPECTIVE_WEEK,
  ReactCalendar,
} from 'components/AppointmentDetails/ReactCalendar';
import { Namespaces } from 'languages';
import moment from 'moment';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarWrapper, OccasionsWrapper } from './styles';
import { CourseOccasions } from 'components/CourseDetails/CourseOccasions';
import { CourseStore } from 'store/CourseStore';
import { Course } from 'store/ServiceStore';
import { HeaderStore } from 'store/HeaderStore';
import { Loader } from 'components/Loader/Loader';
import { WaitingListModal } from 'components/CourseDetails/WaitingListModal';
import { observer } from 'mobx-react-lite';
import { CardButton } from 'components/CourseDetails/detailsStyles';
import { AddCheckoutItemData, CheckoutStore } from 'store/CheckoutStore';
import { getAddBookingCTAEvent, postCTAEventMessage } from 'helpers/EventUtils';

export const Body: FC = observer(() => {
  const { t } = useTranslation(Namespaces.UI);

  const [value, setValue] = useState(moment());
  const [selectedButtons, setSelectedButtons] = useState<number[]>([]);
  const [itemsToSelect, setItemsToSelect] = useState<number[]>([]);
  const [modal, setModal] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [calendarActiveStartDate, setCalendarActiveStartDate] = useState(
    moment().startOf('isoWeek').format('YYYY-MM-DD')
  );

  const {
    addToCourseOccasionWaitingList,
    fetchProfileOccasions,
    profileOccasions,
    clearProfileOccasions,
  } = CourseStore;
  const { profileId } = HeaderStore;
  const { addItemToCheckout } = CheckoutStore;

  const { data, page, totalPages, totalRows } = profileOccasions || {};

  const handleSubmit = async (userProxyIds: number[]) => {
    setIsModalLoading(true);
    return addToCourseOccasionWaitingList({
      id: modal?.toString() || '',
      userProxyIds,
    }).finally(() => {
      setIsModalLoading(false);
    });
  };

  const getRequestData = () => {
    const requestData = [] as AddCheckoutItemData[];

    selectedButtons.forEach((el) => {
      requestData.push({
        serviceId: data?.find((item) => item.id === el)?.service?.id || 0,
        entryId: el,
      });
    });

    return requestData;
  };

  const fetchNextPage = async () => {
    fetchProfileOccasions({
      profileId,
      page: (page || 0) + 1,
      perPage: 10,
      startTime: value.clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      endTime: value.clone().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  const addToCheckout = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!isCheckoutLoading && selectedButtons.length) {
      setIsCheckoutLoading(true);
      addItemToCheckout(profileId, getRequestData(), setIsCheckoutLoading);
      postCTAEventMessage(getAddBookingCTAEvent());
    }
  };

  const fetchOccasions = () => {
    setIsLoading(true);
    fetchProfileOccasions({
      profileId,
      page: 1,
      perPage: 10,
      startTime: value.clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      endTime: value.clone().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    })
      .then(({ data }) => {
        if (data) {
          const selectableButtons = data.reduce((acc, item) => {
            const {
              isFull: isFullItem,
              isCallerAttending: isDisabled,
              id: itemId,
            } = item;
            if (!isFullItem && !isDisabled) {
              acc.push(itemId);
            }
            return acc;
          }, [] as number[]);
          setItemsToSelect(selectableButtons);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchOccasions();
    return () => {
      setItemsToSelect([]);
      setSelectedButtons([]);
      clearProfileOccasions();
    };
  }, [profileId, value]);

  useEffect(() => {
    const { elementDate } = localStorage;
    if (elementDate) {
      setValue(moment(elementDate));
    }
  }, []);

  return (
    <CalendarWrapper>
      <ReactCalendar
        selectedDates={[value.toISOString()]}
        activeStartDate={calendarActiveStartDate}
        perspective={PERSPECTIVE_WEEK}
        disabledDates={[]}
        onSelectedDateChanged={(firstDate) => {
          setValue(moment(firstDate));
        }}
        onVisibleDateRangeChanged={(_, _1, newActiveStartDate) => {
          setCalendarActiveStartDate(newActiveStartDate);
        }}
        view={'schedule'}
        isLoading={isLoading}
        showNeighboringMonth={true}
        allowRangeSelection={false}
        minRangeDays={0}
        maxRangeDays={0}
      />

      {isLoading ? (
        <Loader />
      ) : data?.length && data?.length > 0 ? (
        <OccasionsWrapper>
          <CourseOccasions
            data={data || []}
            isBookPerOccasion={true}
            selectedButtons={selectedButtons}
            setSelectedButtons={setSelectedButtons}
            page={page || 0}
            totalPages={totalPages || 0}
            totalRows={totalRows || 0}
            itemsToSelect={itemsToSelect}
            allEntries={false}
            isCourseBookable={true}
            fetchProfileOccasions={fetchNextPage}
            addToCourseOccasionWaitingList={addToCourseOccasionWaitingList}
            setModal={setModal}
            view="schedule"
          />
          {selectedButtons.length > 0 && (
            <CardButton
              style={{ marginTop: '30px' }}
              disabled={!selectedButtons.length}
              onClick={addToCheckout}
            >
              {isCheckoutLoading ? <Loader isWhite small /> : t('addToCart')}
            </CardButton>
          )}
        </OccasionsWrapper>
      ) : (
        <div className="no-courses">{t('noOccasions')}</div>
      )}
      <WaitingListModal
        isOpen={!!modal}
        onClose={() => setModal(null)}
        course={data?.find((el) => el.id === modal)?.service as Course}
        onSubmit={handleSubmit}
        isLoading={isModalLoading}
      />
    </CalendarWrapper>
  );
});
