import { FC, ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';

interface IProps {
  children?: ReactNode;
  icon: string;
  position?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  height?: string;
  width?: string;
  cursor?: string | boolean;
  borderRadius?: string;
  rotate?: string | boolean;
  disabled?: boolean;
  margin?: string;
  background?: string;
  device?: string;
  deviceHeight?: string;
  deviceWidth?: string;
  deviceMargin?: string;
  hover?: boolean;
  transition?: string;
  onClick?: (event?: SyntheticEvent<HTMLDivElement>) => void;
}

const Icon = styled.div<IProps>`
  ${({ position }) => (position ? `position: ${position}` : '')};
  ${({ top }) => (top ? `top: ${top}` : '')};
  ${({ right }) => (right ? `right: ${right}` : '')};
  ${({ bottom }) => (bottom ? `bottom: ${bottom}` : '')};
  ${({ left }) => (left ? `left: ${left}` : '')};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  ${({ margin }) => (margin ? `margin: ${margin}` : '')};
  ${({ background }) => (background ? `background: ${background}` : '')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${({ icon }) => icon});
  ${({ borderRadius }) => (borderRadius ? `border-radius: ${borderRadius}` : '')};
  ${({ rotate }) => rotate as string};
  ${({ transition }) => (transition ? `transition: ${transition}` : '')};
  cursor: ${({ cursor }) => cursor as string};
  ${({ disabled }) => (disabled ? 'opacity: 0.3' : '')};
  user-select: none;
  ${({ device, deviceWidth, deviceHeight, deviceMargin }) =>
    device
      ? `@media${device} {
          ${deviceWidth ? `width: ${deviceWidth}` : ''};
          ${deviceHeight ? `height: ${deviceHeight}` : ''};
            ${deviceMargin ? `margin: ${deviceMargin}` : ''};
  }`
      : ''};
  ${({ hover }) =>
    hover
      ? `&:hover {
    opacity: 0.6;
  }`
      : ''};
`;

export const CustomIcon: FC<IProps> = ({
  icon,
  height,
  width,
  borderRadius,
  rotate,
  cursor,
  disabled,
  margin,
  device,
  position,
  top,
  right,
  bottom,
  left,
  background,
  deviceHeight,
  deviceWidth,
  deviceMargin,
  hover,
  children,
  transition,
  onClick,
}) => {
  return (
    <Icon
      icon={icon}
      height={height}
      width={width}
      borderRadius={borderRadius}
      rotate={rotate ? 'transform: rotate(180deg)' : ''}
      cursor={cursor ? 'pointer' : 'auto'}
      disabled={disabled}
      margin={margin}
      position={position}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      background={background}
      device={device}
      deviceHeight={deviceHeight}
      deviceWidth={deviceWidth}
      deviceMargin={deviceMargin}
      hover={hover}
      transition={transition}
      onClick={onClick}
    >
      {children}
    </Icon>
  );
};
