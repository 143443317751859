import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CollapseText } from 'components/CollapseText/CollapseText';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { getPrice, urls } from 'helpers';
import { getDurationTextFromMinutes, getYMD } from 'helpers/DateTimeUtils';
import { Namespaces } from 'languages';
import { HeaderStore } from 'store/HeaderStore';
import { PriceReductionsType } from 'store/ServiceListStore';

import PriceIcon from 'assets/icons/price.svg';
import Clock from 'assets/icons/time.svg';
import Calendar from 'assets/icons/calendar.svg';
import Membership from 'assets/icons/membership-icon.svg';
import Voucher from 'assets/icons/voucher-icon.svg';

import {
  SERVICE_TYPE_MEMBERSHIP,
  SERVICE_TYPE_VOUCHER,
} from 'constants/serviceConstants';

import {
  Card,
  CardBody,
  CourseDuration,
  CourseDurationBlock,
  CourseDurationTitle,
  IconWrapper,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
  CardPrice,
  Balance,
  Price,
  ServiceDuration,
  WrapInfo,
  BookButton,
  SpotsLeftLabel,
  CardTime,
} from './styles';
import { Flex } from 'styled/Flex';

interface IProps {
  id: number;
  profileId: string;
  name: string;
  description: string;
  price: number;
  fromPrice?: number;
  currency: string;
  durationMinutes?: number;
  startTime?: string;
  endTime?: string;
  pricePerUnit: string;
  balance?: number;
  type: string;
  priceReductions?: PriceReductionsType[];
  spotsLeft?: number;
}

export const Service: FC<IProps> = ({
  id,
  profileId,
  name,
  description,
  price,
  fromPrice,
  currency,
  durationMinutes,
  startTime,
  endTime,
  pricePerUnit,
  balance,
  type,
  priceReductions,
  spotsLeft,
}) => {
  const { setBackButton } = HeaderStore;
  const history = useHistory();
  const { t } = useTranslation(Namespaces.UI);
  const themeContext = useContext(ThemeContext);

  const onBookClicked = () => {
    setBackButton(true);
    history.push(urls.service(profileId, id.toString()));
  };

  const voucherIcon = priceReductions?.find(
    (element: PriceReductionsType) => element.type === SERVICE_TYPE_VOUCHER
  );
  const membershipIcon = priceReductions?.find(
    (element: PriceReductionsType) => element.type === SERVICE_TYPE_MEMBERSHIP
  );

  let servicePrice = getPrice(
    fromPrice || price,
    currency,
    true,
    pricePerUnit ? t(pricePerUnit) : undefined
  );
  if (fromPrice) {
    servicePrice = `${t('from')} ${servicePrice}`;
  }

  return (
    <Card onClick={onBookClicked} data-testid="service">
      <CardBody>
        {startTime && (
          <CourseDurationBlock>
            <CustomIcon width="15px" height="15px" icon={Calendar} />
            <CourseDurationTitle>{`${t(
              'courseDuration'
            ).toUpperCase()}:`}</CourseDurationTitle>
            <CourseDuration>{`${getYMD(startTime, true)} ~ ${getYMD(
              endTime,
              true
            )}`}</CourseDuration>
          </CourseDurationBlock>
        )}
        <CardHeader>
          <CardTitle>{name}</CardTitle>
          <IconWrapper>
            {membershipIcon && (
              <CustomIcon
                width="20px"
                height="20px"
                icon={Membership}
                margin="0 10px 0 0"
              />
            )}
            {voucherIcon && (
              <CustomIcon width="20px" height="20px" icon={Voucher} margin="0 10px 0 0" />
            )}
          </IconWrapper>
        </CardHeader>
        <CardDescription>
          <CollapseText text={description} maxLines={4} />
        </CardDescription>
        <CardFooter>
          <WrapInfo>
            {servicePrice && (
              <CardPrice>
                <CustomIcon width="20px" height="20px" icon={PriceIcon} />
                {type === SERVICE_TYPE_VOUCHER ? (
                  <>
                    <Balance>{servicePrice}</Balance>
                    <Price isLineThrough>{`(${getPrice(
                      balance || 0,
                      currency,
                      true,
                      t(pricePerUnit)
                    )})`}</Price>
                  </>
                ) : (
                  <Price>{servicePrice}</Price>
                )}
              </CardPrice>
            )}
            {durationMinutes && (
              <CardTime>
                <CustomIcon width="20px" height="20px" icon={Clock} />
                <ServiceDuration>
                  {`${getDurationTextFromMinutes(durationMinutes)}`}
                </ServiceDuration>
              </CardTime>
            )}
          </WrapInfo>
          <Flex flexDirection="column" position="relative">
            <BookButton
              secondary={false}
              onClick={(e) => {
                e.stopPropagation();
                onBookClicked();
              }}
            >
              {type === SERVICE_TYPE_VOUCHER || type === SERVICE_TYPE_MEMBERSHIP
                ? t('buy')
                : t('book')}
            </BookButton>

            {typeof spotsLeft === 'number' && spotsLeft >= 0 && (
              <SpotsLeftLabel
                color={
                  spotsLeft > 0 ? themeContext.colors.yellow : themeContext.colors.red
                }
              >
                {spotsLeft === 0
                  ? t('spotsLeftFull')
                  : spotsLeft === 1
                  ? t('spotsLeftSingle')
                  : t('spotsLeft', {
                      count: spotsLeft,
                    })}
              </SpotsLeftLabel>
            )}
          </Flex>
        </CardFooter>
      </CardBody>
    </Card>
  );
};
