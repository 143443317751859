import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';
import MinusButton from 'assets/icons/Minus-button.svg';
import PlusButton from 'assets/icons/Plus-button.svg';
import {
  ActiveRowContainer,
  CardButton,
  RowSection,
  Label,
  Value,
  CounterContainer,
  QuantityValue,
} from './fixedCardStyles';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { Loader } from 'components/Loader/Loader';
import { getTimeText } from 'components/TimeButton/TimeButton';
import { AppointmentSlotFixed } from 'store/ServiceStore';

export type ActiveButton = {
  index: number;
  quantity?: number;
} & AppointmentSlotFixed['slots'][number];

interface IProps {
  item: ActiveButton;
  activeTimeButtons?: ActiveButton[];
  setActiveTimeButton?: (value: ActiveButton[]) => void;
  onSubmit: (item: ActiveButton) => Promise<void>;
  price: number;
  borderLess?: boolean;
  onNext?: () => void;
  onPrev?: () => void;
  withTime?: boolean;
  isLoading?: boolean;
}

export const ActiveRow: FC<IProps> = ({
  item,
  activeTimeButtons,
  setActiveTimeButton,
  onSubmit,
  price,
  borderLess,
  onNext,
  onPrev,
  withTime = true,
  isLoading,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(Namespaces.UI);

  const isAvailable = item.availableCount && item.availableCount > 0;

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(item);
    setLoading(false);
  };
  const handlePrev = () => {
    if (!(item.quantity && item.quantity <= 1)) {
      onPrev?.();
      activeTimeButtons &&
        setActiveTimeButton?.(
          activeTimeButtons?.map((el) =>
            el.index === item.index ? { ...el, quantity: (el.quantity || 2) - 1 } : el
          )
        );
    }
  };
  const handleNext = () => {
    if (!(item.quantity && item.quantity >= (item?.availableCount || 1))) {
      onNext?.();
      activeTimeButtons &&
        setActiveTimeButton?.(
          activeTimeButtons?.map((el) =>
            el.index === item.index ? { ...el, quantity: (el.quantity || 1) + 1 } : el
          )
        );
    }
  };

  return (
    <ActiveRowContainer borderLess={borderLess}>
      {!isLoading ? (
        <>
          {withTime && (
            <RowSection>
              <Label>{t('time').toUpperCase()}</Label>
              <Value>
                {isLoading ? (
                  <Loader small />
                ) : (
                  getTimeText(item.startTime, item.endTime, false)
                )}
              </Value>
            </RowSection>
          )}
          <RowSection>
            <Label>{t('quantity').toUpperCase()}</Label>
            {isAvailable ? (
              <CounterContainer>
                <CustomIcon
                  width="24px"
                  height="24px"
                  icon={MinusButton}
                  disabled={!!(isLoading || (item.quantity && item.quantity <= 1))}
                  onClick={handlePrev}
                >
                  -
                </CustomIcon>
                <QuantityValue>{item.quantity}</QuantityValue>
                <CustomIcon
                  width="24px"
                  height="24px"
                  icon={PlusButton}
                  disabled={
                    !!(
                      isLoading ||
                      (item.quantity && item.quantity >= (item?.availableCount || 1))
                    )
                  }
                  onClick={handleNext}
                >
                  +
                </CustomIcon>
              </CounterContainer>
            ) : (
              <Value>{t('noneAvailable')}</Value>
            )}
          </RowSection>
          <RowSection>
            <Label>{t('sum').toUpperCase()}</Label>
            <Value>{isLoading ? <Loader small /> : price * (item.quantity || 1)}</Value>
          </RowSection>
        </>
      ) : (
        <Loader small />
      )}
      {!isLoading && isAvailable ? (
        <CardButton style={{ margin: 0, alignSelf: 'center' }} onClick={handleSubmit}>
          {loading || isLoading ? <Loader isWhite small /> : t('addToCart')}
        </CardButton>
      ) : (
        <></>
      )}
    </ActiveRowContainer>
  );
};
