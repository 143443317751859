import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';

export const Card = styled.div`
  margin-bottom: 15px;
  padding: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  user-select: none;
  cursor: pointer;

  @media ${devices.mobile} {
    padding: 14px;
  }
`;
export const CardHeader = styled.div`
  display: grid;
  grid-template-columns: 42px 1fr 35px;
  align-items: center;
`;
export const CardNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 12px;
  border-radius: 50%;
  color: white;
  background: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  user-select: none;
`;
